import { Redirect, Route } from 'react-router-dom';

export const ProtectedRoute = ({
  component: Component,
  path,
  isAuthorized = () => true,
  redirectPath,
  ...rest
}) => {
  return (
    <Route
      path={path}
      // status={403}
      {...rest}
      render={(props) => {
        if (isAuthorized()) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: redirectPath || '/',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}
