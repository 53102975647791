import { Activity } from '@vaadataworks/vaa-planboard';
import moment from 'moment';
import { ActivityDto } from 'types/activitydto';

export const mapDtoToActivity = (dto: ActivityDto) => {
  return {
    id: dto.activityId,
    resourceId: dto.subFieldId,
    startDate: moment(dto.startDate),
    endDate: moment(dto.endDate),
    activityType: dto.activityType,
    properties: {
      ...dto.activityData,
      harvestNumber: dto.harvestNumber,
      plot: dto.subFieldId,
      plotName: null,
    },
  } as Activity<any>;
};

export const mapActivityToDto = (activity: Activity<any>) => {
  return {
    subFieldId: activity.properties.plot,
    activityType: activity.activityType,
    harvestNumber: activity.properties.harvestNumber,
    startDate: activity.startDate.toDate(),
    endDate: activity.endDate.toDate(),
    activityId: activity.id,
    activityData: activity.properties,
  } as ActivityDto;
};

export const mapDtoArrayToActivityArray = (dtos: ActivityDto[]) => {
  return dtos.map((dto) => mapDtoToActivity(dto));
};

export const mapActivityArrayToDtoArray = (activities: Activity<any>[]) => {
  return activities.map((activity) => mapActivityToDto(activity));
};
