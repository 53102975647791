import { auth } from './auth';
import { plots, manurePlans, subfields, cuts } from './plots';
import { activities, schema } from './activities';
import { modal } from './modal';
import { selectedYear } from './year';
import { breakpoint } from './breakpoint';
import {
  artificialFertilizers,
  defaultArtificialFertilizers,
  defaultOrganicFertilizers,
  fertilizations,
  organicFertilizers,
  ownArtificialFertilizers,
  ownOrganicFertilizers,
} from './fertilizations';
import { impersonatedRelation, impersonatedRelationId } from './relation';
import { croptypes } from './croptypes';
import { user } from './user';
import { monsters, silages } from './monsters';

export const atoms = {
  schema,
  auth,
  fertilizations,
  artificialFertilizers,
  organicFertilizers,
  cuts,
  plots,
  activities,
  modal,
  selectedYear,
  manurePlans,
  subfields,
  breakpoint,
  defaultArtificialFertilizers,
  ownArtificialFertilizers,
  defaultOrganicFertilizers,
  ownOrganicFertilizers,
  impersonatedRelation,
  impersonatedRelationId,
  user,
  croptypes,
  monsters,
  silages,
};
