import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Grid, Menu, Modal } from 'antd';
import { atoms } from 'atoms';
import { LinkWithQuery } from 'components/link-with-query';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserHeader, MobileHeader, TabletHeader } from '.';
import styles from './styles.module.scss';

type HeaderProps = {
  icon?: IconProp;
  title?: string;
};
const { useBreakpoint } = Grid;

export const Header: FC<HeaderProps> = () => {
  const { xl, md } = useBreakpoint();
  const { t } = useTranslation();
  const modalComponent = useAtomValue(atoms.modal.component);
  const [showModal, setShowModal] = useAtom(atoms.modal.show);
  const user = useAtomValue(atoms.user);
  const impersonatedRelationId = useAtomValue(atoms.impersonatedRelationId);

  const menuItems =
    user?.role === 'advisor' && !impersonatedRelationId ? null : (
      <>
        <Menu.Item>
          <LinkWithQuery to="/plots" />
          {t('navbar.menu.rvo')}
        </Menu.Item>
        <Menu.Item>
          <LinkWithQuery to="/fertilization" />
          {t('navbar.menu.fertilization')}
        </Menu.Item>
        <Menu.Item>
          <LinkWithQuery to="/planboard" />
          {t('navbar.menu.planboard')}
        </Menu.Item>
        <Menu.Item>
          <LinkWithQuery to="/ruwvoer" />
          {t('navbar.menu.ruwvoer')}
        </Menu.Item>
        <Menu.Item>
          <LinkWithQuery to="/overview" />
          {t('navbar.menu.overview')}
        </Menu.Item>
      </>
    );
  return (
    <>
      {xl ? (
        <BrowserHeader user={user} menuItems={menuItems} />
      ) : md ? (
        <TabletHeader menuItems={menuItems} />
      ) : (
        <MobileHeader menuItems={menuItems} />
      )}
      <Modal
        width={600}
        footer={false}
        bodyStyle={{ padding: 20 }}
        className={styles.modal}
        onCancel={() => setShowModal(false)}
        destroyOnClose
        visible={showModal}
      >
        {modalComponent}
      </Modal>
    </>
  );
};
