import * as Color from 'color';
export const setTheme = (colors) => {
  const doc = document.documentElement.style;
  Object.entries(colors).forEach(([key, value]) => {
    const color = Color(value);
    doc.setProperty(`--${key}`, color);
    doc.setProperty(`--${key}--lighter`, color.lighten(0.1));
    doc.setProperty(`--${key}--lightest`, color.lighten(0.15));
    doc.setProperty(`--${key}--darker`, color.darken(0.1));
    doc.setProperty(`--${key}--darkest`, color.darken(0.15));
    isLight(value, 30)
      ? doc.setProperty(`--text--${key}`, '#111')
      : doc.setProperty(`--text--${key}`, '#fff');
  });
  window.less.modifyVars({
    '@primary-color': colors.primary,
    '@btn-primary-color': isLight(colors.primary, 30) ? '#111' : '#fff',
  });
};

function isLight(color, offset?) {
  if (!offset) offset = 0;
  var rgb = hexToRgb(color);
  if (!rgb) return;
  var yiq = (rgb?.r * 299 + rgb?.g * 587 + rgb?.b * 114) / 1000;
  return yiq > 128 + offset;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
