import { getFarmManurePlan, postFarmManurePlan } from 'adapters/manure-adapter';
import { Form, message } from 'antd';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { useAtomValue } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FarmManurePlan } from 'types/farmManurePlan';
import { CustomInputNumber } from 'utilities/InputNumberNlFormat';

export interface CompanyInfoProps {}
export const CompanyInfo: FC<CompanyInfoProps> = () => {
  const { t } = useTranslation();
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [farmManurePlan, setFarmManurePlan] = useState<FarmManurePlan>();
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      try {
        setFarmManurePlan(await getFarmManurePlan({ year: selectedYear }));
      } catch (err) {
        message.error('Er ging iets mis.');
        console.error(err);
      }
    })();
  }, [selectedYear]);

  useEffect(() => {
    if (farmManurePlan) {
      form.setFieldsValue(farmManurePlan);
    }
  }, [farmManurePlan]);

  const onFinish = async (values) => {
    let id = {};
    if (farmManurePlan?.farmManurePlanId) {
      id = { farmManurePlanId: farmManurePlan.farmManurePlanId };
    }
    try {
      setFarmManurePlan(
        await postFarmManurePlan({ ...values, ...id, year: selectedYear }),
      );
      message.success('Succesvol opgeslagen');
    } catch (err) {
      message.error('Er ging iets mis.');
      console.error(err);
    }
  };

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Form.Item
        label="N uit kunstmest volgens bemestingsplan [kg]"
        name={['expectedNitrogenFromFertalizer']}
      >
        <CustomInputNumber />
      </Form.Item>
      <Form.Item
        label="Verwachte beschikbare eigen runderdrijfmest [m³]"
        name={['expectedAvailableLiquidManure']}
      >
        <CustomInputNumber />
      </Form.Item>
      <Form.Item
        label="Verwachte beschikbare eigen vaste mest [ton]"
        name={['expectedAvailableSolidManure']}
      >
        <CustomInputNumber />
      </Form.Item>
      <Form.Item
        label="Verwachte aan te voeren drijfmest [m3]"
        name={['expectedLiquidManureToBeSupplied']}
      >
        <CustomInputNumber />
      </Form.Item>
      <Form.Item
        label="Verwachte aan te voeren overige organische mest [ton]"
        name={['expectedOtherManureToBeSupplied']}
      >
        <CustomInputNumber />
      </Form.Item>
      <Form.Item>
        <CustomButton type="primary" htmlType="submit">
          Opslaan
        </CustomButton>
      </Form.Item>
    </Form>
  );
};
