import { Activity, ActivityStyle } from '@vaadataworks/vaa-planboard';

const styleActivities = (
  activities: Array<Activity<any>>,
  styleFn: (activity: Activity<any>) => ActivityStyle,
) => {
  if (!Array.isArray(activities)) {
    throw new TypeError('activities is not of type Array');
  }

  return activities.map((x) => ({ ...x, style: styleFn(x) }));
};

const styleActivitiesByProperty = (
  activities: Array<Activity<any>>,
  property: string,
  propertyStyles: { [key: string]: ActivityStyle },
) => {
  const styleFn = (activity: Activity<any>) => {
    const propertyValue = activity[property];
    return propertyStyles[propertyValue];
  };
  return styleActivities(activities, styleFn);
};

export { styleActivities, styleActivitiesByProperty };
