import { Activity } from '@vaadataworks/vaa-planboard';
import { getSubfieldManurePlan } from 'adapters/manure-adapter';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
} from 'antd';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { useAtom } from 'jotai';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultSorter } from 'utilities/default-sorter';
import styles from './styles.module.scss';

export interface FertilizerActivityModalProps {
  type: 'fertalize' | 'manure' | 'mow' | 'mowClean' | 'stableFeeding';
  harvestNumber: number | undefined;
  fields: string[] | undefined;
  modifyActivity?: Activity<any> | null;
  submit: (value: any) => any;
  cancel: () => any;
  handleDelete: () => any;
}
export const FertilizerActivityModal: FC<FertilizerActivityModalProps> = ({
  type,
  harvestNumber,
  fields,
  modifyActivity,
  submit,
  cancel,
  handleDelete,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedYear] = useAtom(atoms.selectedYear);
  const [organics] = useAtom(atoms.organicFertilizers);
  const [artificials] = useAtom(atoms.artificialFertilizers);
  const [silages] = useAtom(atoms.silages);
  const fertilizers = { fertalize: artificials, manure: organics };
  const onOk = (values) => {
    submit(values);
  };

  useEffect(() => {
    if (!modifyActivity) {
      return;
    }
    const fieldsValue = {
      fertilizerTypeId: modifyActivity.properties.fertilizerTypeId,
      amountInKgPerHectare: modifyActivity.properties.amountInKgPerHectare,
      amountInTonPerHectare: modifyActivity.properties.amountInTonPerHectare,
      explanation: modifyActivity.properties.explanation,
      grassYieldKgDsPerHa: modifyActivity.properties.grassYieldKgDsPerHa,
      hourOfDay: modifyActivity.properties.hourOfDay,
      shaking: modifyActivity.properties.shaking,
      silageSampleId: modifyActivity.properties.silageSampleId,
    };
    form.setFieldsValue(fieldsValue);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyActivity]);

  const hasNotAlreadyValues = (activity: Activity<any> | null | undefined) => {
    if (!activity) {
      return true;
    }
    if (type === 'manure') {
      if (
        form.getFieldValue('fertilizerTypeId') &&
        form.getFieldValue('amountInTonPerHectare') &&
        form.getFieldValue('explanation')
      ) {
        return false;
      }
    } else if (type === 'fertalize') {
      if (
        form.getFieldValue('fertilizerTypeId') &&
        form.getFieldValue('amountInKgPerHectare')
      ) {
        return false;
      }
    } else if (
      type === 'mow' ||
      type === 'mowClean' ||
      type === 'stableFeeding'
    ) {
      if (
        form.getFieldValue('grassYieldKgDsPerHa') &&
        form.getFieldValue('hourOfDay') &&
        form.getFieldValue('silageSampleId') &&
        form.getFieldValue('shaking')
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (form && type && harvestNumber && fields && fields.length) {
      (async () => {
        try {
          const subfieldManurePlans = await getSubfieldManurePlan({
            year: selectedYear,
          });
          const matchingSubfieldManurePlan = subfieldManurePlans.find(
            (plan) =>
              plan.subFieldId === fields[0] &&
              plan.harvestNumber === harvestNumber,
          );
          if (matchingSubfieldManurePlan) {
            // TODO set the right values.
            if (
              type === 'fertalize' &&
              matchingSubfieldManurePlan.artificialFertilizerId &&
              hasNotAlreadyValues(modifyActivity)
            ) {
              form.setFieldsValue({
                fertilizerTypeId:
                  matchingSubfieldManurePlan.artificialFertilizerId,
                explanation: matchingSubfieldManurePlan.explanation,
                amountInKgPerHectare:
                  matchingSubfieldManurePlan.amountOfFertilizer,
              });
            } else if (
              type === 'manure' &&
              matchingSubfieldManurePlan.organicFertilizerId &&
              hasNotAlreadyValues(modifyActivity)
            ) {
              form.setFieldsValue({
                fertilizerTypeId:
                  matchingSubfieldManurePlan.organicFertilizerId,
                explanation: matchingSubfieldManurePlan.explanation,
                amountInTonPerHectare:
                  matchingSubfieldManurePlan.amountOfManure,
              });
            }
          }
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [form, selectedYear, type, harvestNumber, fields]);

  return (
    <Form onFinish={onOk} form={form} layout="vertical">
      {type !== 'mow' && type !== 'mowClean' && type !== 'stableFeeding' && (
        <Form.Item
          fieldKey="id"
          label="Mest type"
          name={['fertilizerTypeId']}
          rules={[{ required: true }]}
        >
          <Select style={{ width: '100%' }}>
            {fertilizers[type].map((fertilizer) => (
              <Select.Option key={fertilizer.id} value={fertilizer.id}>
                {fertilizer.fertilizerType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'fertalize' && (
        <Form.Item
          label={'Hoeveelheid [kg/ha]'}
          name={['amountInKgPerHectare']}
          rules={[{ required: true }]}
        >
          <InputNumber className={styles.field} />
        </Form.Item>
      )}
      {type === 'manure' && (
        <>
          <Form.Item
            label={'Hoeveelheid [m3/ha]'}
            name={['amountInTonPerHectare']}
            rules={[{ required: true }]}
          >
            <InputNumber className={styles.field} />
          </Form.Item>
          <Form.Item label={'Toelichting'} name={['explanation']}>
            <Input className={styles.field} />
          </Form.Item>
        </>
      )}
      {(type === 'mow' || type === 'mowClean' || type === 'stableFeeding') && (
        <>
          <Form.Item
            label={'Opbrengst [kg ds/ha]'}
            name={['grassYieldKgDsPerHa']}
            rules={[{ required: false }]}
          >
            <InputNumber className={styles.field} />
          </Form.Item>
          <Form.Item
            label={'Schudden'}
            name={['shaking']}
            rules={[{ required: false }]}
            valuePropName="checked"
          >
            <Checkbox className={styles.field} />
          </Form.Item>
          <Form.Item
            label={'Uur van de dag (0-23)'}
            name={['hourOfDay']}
            rules={[{ required: false }]}
          >
            <InputNumber min={0} max={23} className={styles.field} />
          </Form.Item>
          <Form.Item
            label={'Kuilmonster'}
            name={['silageSampleId']}
            rules={[{ required: false }]}
          >
            <Select allowClear style={{ width: '100%' }}>
              {silages.sort(defaultSorter((x) => x.name)).map((silage) => (
                <Select.Option key={silage.id} value={silage.id}>
                  {silage.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

      <div className={styles.footer}>
        <div className={styles.delete}>
          {modifyActivity && (
            <Popconfirm
              title="Weet u zeker dat u de activiteit wilt verwijderen?"
              okText="Bevestig"
              cancelText="Annuleer"
              onConfirm={handleDelete}
            >
              <CustomButton color="danger">Verwijderen</CustomButton>
            </Popconfirm>
          )}
        </div>
        <div className={styles.mainButtons}>
          <Button onClick={cancel}>{t('schemaform.cancel')}</Button>
          <Button htmlType="submit" type="primary">
            {t('schemaform.submit')}
          </Button>
        </div>
      </div>
    </Form>
  );
};
