import { AdvisorMandateDto, DataMandateDto } from 'types';
import { Advisor } from 'types/advisor';
import { DataClient } from 'types/dataclient';
import { Lab } from 'types/lab';
import { User } from 'types/user';
import { api } from 'utilities/api.axios';

export const getUser = () =>
  api.get<User>(`usermanagement/v1/user`).then((r) => r.data);

type PostUser = {
  username: string;
  companyName: string;
  role: 'None' | 'Farmer' | 'Advisor';
  email: string | undefined;
};

type PostMandate = {
  mandateType: string;
  yearStart?: number;
  yearEnd?: number;
  otherPartyKvk: string;
  email?: string;
};
type labParams = {
  labCode: string;
  customerCode: string;
};
export const getLabData = () =>
  api.get<Lab>(`usermanagement/v1/LabCustomerCode`).then((r) => r.data);
export const postLabData = (params: labParams) =>
  api
    .post<Lab>(`usermanagement/v1/LabCustomerCode`, params)
    .then((r) => r.data);

export const postUser = (user: PostUser) =>
  api.post<User>(`usermanagement/v1/user`, user).then((r) => r.data);

export const getDataClients = () =>
  api
    .get<DataClient[]>(`usermanagement/v1/dataclient/list`)
    .then((r) => r.data);

export const getActiveAdvisorMandates = () =>
  api
    .get<AdvisorMandateDto[]>(`usermanagement/v1/advisorMandate/active`)
    .then((r) => r.data);

export const getOpenAdvisorMandates = () =>
  api
    .get<AdvisorMandateDto[]>(`usermanagement/v1/advisorMandate/open`)
    .then((r) => r.data);

export const getAdvisorList = () =>
  api
    .get<Advisor[]>(`usermanagement/v1/advisorMandate/list`)
    .then((r) => r.data);

export const createAdvisorMandate = (mandate: PostMandate) =>
  api
    .post<AdvisorMandateDto>(`usermanagement/v1/advisorMandate`, mandate)
    .then((r) => r.data);

export const updateAdvisorMandate = (advisorMandateId: string) =>
  api
    .put<AdvisorMandateDto>(
      `usermanagement/v1/advisorMandate?advisorMandateId=${advisorMandateId}`,
    )
    .then((r) => r.data);

export const deleteAdvisorMandate = (advisorMandateId: string) =>
  api
    .delete<AdvisorMandateDto>(
      `usermanagement/v1/advisorMandate?advisorMandateId=${advisorMandateId}`,
    )
    .then((r) => r.data);

export const getActiveDataMandates = () =>
  api
    .get<DataMandateDto[]>(`usermanagement/v1/DataMandate/active`)
    .then((r) => r.data);

export const getOpenDataMandates = () =>
  api
    .get<DataMandateDto[]>(`usermanagement/v1/DataMandate/open`)
    .then((r) => r.data);

export const createDataMandate = (mandate: PostMandate) =>
  api
    .post<DataMandateDto>(`usermanagement/v1/DataMandate`, mandate)
    .then((r) => r.data);

export const updateDataMandate = (DataMandateId: string) =>
  api
    .put<DataMandateDto>(
      `usermanagement/v1/DataMandate?DataMandateId=${DataMandateId}`,
    )
    .then((r) => r.data);

export const deleteDataMandate = (dataMandateId: string) =>
  api
    .delete<DataMandateDto>(
      `usermanagement/v1/dataMandate?dataMandateId=${dataMandateId}`,
    )
    .then((r) => r.data);
