import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postSubfields } from 'adapters/cropfield-adapter';
import { message, notification, Popconfirm } from 'antd';
import { atoms } from 'atoms';
import { FeatureViewer } from 'components';
import { PlotEditor } from 'components/PlotEditor/PlotEditor';
import { Feature } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useState } from 'react';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';
import styles from './styles.module.scss';

type PlotCardType = {
  item: Feature;
};
export const PlotCard = ({ item }: PlotCardType) => {
  const setModalComponent = useUpdateAtom(atoms.modal.component);
  const setShowModal = useUpdateAtom(atoms.modal.show);
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [plots, setPlots] = useAtom(atoms.plots);
  const setModalState = (comp) => {
    setShowModal(true);
    setModalComponent(comp);
  };

  const [hover, setHover] = useState<boolean>(false);
  const editPlot = async (plot: Feature) => {
    setShowModal(false);

    const newList = [...plots.filter((x) => x.id !== plot.id), plot];
    try {
      setPlots(await postSubfields({ year: selectedYear }, newList));
      message.success('Succesvol opgeslagen.');
    } catch (err) {
      message.error('Er ging iets mis.');
      console.error(err);
    }
  };

  const deletePlot = async (id: number) => {
    const plotsToSave = [...plots.filter((x) => x.id !== id)];
    try {
      const response = await postSubfields({ year: selectedYear }, plotsToSave);
      setPlots(response);
      window.dispatchEvent(new Event('resize')); // Causes leaflet to center properly.
    } catch (err) {
      notification['error']({
        message: 'Er ging iets mis',
        description: err.response?.data?.Message,
      });
      console.error(err);
    }
  };

  return (
    <div
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles.overviewPlot}>
        <div
          style={{
            width: '100px',
            height: '80px',
            display: 'inline-block',
            float: 'left',
            position: 'relative',
          }}
        >
          <Popconfirm
            title={`Weet u zeker dat u ${item.properties?.name} wilt verwijderen?`}
            okText="Bevestig"
            cancelText="Annuleer"
            onConfirm={() => deletePlot(item.id as number)}
          >
            {hover && (
              <div color="danger" className={styles.overlay}>
                <FontAwesomeIcon
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%,-50%) scale(2)',
                  }}
                  icon="trash"
                />
              </div>
            )}
          </Popconfirm>
          <FeatureViewer
            mapStyle={{
              height: '80px',
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
            }}
            feature={item}
          />
        </div>
        <div
          style={{
            backgroundColor: 'white',
            width: 'calc(100% - 100px)',
            display: 'inline-block',
            height: '100%',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            padding: '12px',
            float: 'right',
            fontSize: '12px',
            color: 'black',
            overflow: 'hidden',
          }}
          onClick={() =>
            setModalState(<PlotEditor onSubmit={editPlot} plot={item} />)
          }
        >
          <div
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <b>{item.properties?.name}</b>
          </div>
          <div>
            {displayWithDecimals(2)(
              roundToDecimals(2)(item.properties?.hectare),
            )}{' '}
            ha
          </div>
          <div>{item.properties?.cropType}</div>
        </div>
      </div>
    </div>
  );
};
