import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Col, Menu, Row, Select, Space } from 'antd';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import { User } from 'types/user';
import { SelectYear } from './SelectYear';
import styles from './styles.module.scss';

type BrowserHeaderProps = {
  icon?: IconProp;
  title?: string;
  user: User | null;
  menuItems: ReactNode;
};

export const BrowserHeader: FC<BrowserHeaderProps> = ({ menuItems, user }) => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const [impersonatedRelation, setImpersonatedRelation] = useAtom(
    atoms.impersonatedRelation,
  );
  const setUser = useUpdateAtom(atoms.user);

  const signOut = () => {
    keycloak.logout();
    setImpersonatedRelation(null);
    setUser(null);
  };
  const history = useHistory();

  const stopImpersonation = () => {
    setImpersonatedRelation(null);
    history.push('/');
  };

  return (
    <div style={{ height: 80 }}>
      <div className={styles.header}>
        <Row
          justify="space-between"
          align="middle"
          gutter={20}
          className={styles.row}
          wrap={false}
        >
          <Col
            flex="340px"
            className={styles.title}
            onClick={() => {
              const searchParams = new URLSearchParams(window.location.search);
              history.push({
                pathname: '/',
                search: '?' + searchParams.toString(),
              });
            }}
          >
            <img src="/logo.svg" alt="" className={styles.dggkIcon} /> Digitale
            Graslandgebruikskalender
          </Col>
          <Col flex={1}>
            <Menu mode="horizontal" inlineCollapsed={false}>
              {menuItems}
            </Menu>
          </Col>
          <Col className={styles.impersonate} span={4}>
            <Row gutter={5}>
              <Col className={styles.label} span={11}>
                Bedrijf:
              </Col>
              <Col span={13} className={styles.value}>
                {user?.companyName}
              </Col>
            </Row>
            <Row gutter={5}>
              <Col className={styles.label} span={11}>
                KVK:
              </Col>
              <Col flex={1}>{user?.kvk}</Col>
            </Row>
          </Col>
          <Col flex="300px" className={styles.textRight}>
            {!impersonatedRelation ? (
              <Space direction="horizontal" size="middle">
                Jaar:
                <SelectYear />
                <CustomButton
                  color="secondary"
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    const searchParams = new URLSearchParams(
                      window.location.search,
                    );
                    history.push({
                      pathname: '/settings',
                      search: '?' + searchParams.toString(),
                    });
                  }}
                  icon={<FontAwesomeIcon icon="cog" />}
                />
                <CustomButton
                  color="primary"
                  type="primary"
                  shape="circle"
                  onClick={signOut}
                  icon={<FontAwesomeIcon icon="sign-out-alt" />}
                />
              </Space>
            ) : (
              <Space direction="horizontal" size="middle">
                Jaar:
                <SelectYear />
                <CustomButton
                  color="secondary"
                  type="primary"
                  shape="round"
                  onClick={stopImpersonation}
                >
                  Sluit bedrijf
                </CustomButton>
              </Space>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
