import {
  createDataMandate,
  getActiveDataMandates,
  getDataClients,
} from 'adapters/user-adapter';
import { Empty, Form, message, Select, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CustomButton } from 'components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { FC } from 'react';
import { DataClient } from 'types/dataclient';

export const AddDataClientModal: FC<{
  isModalVisible: boolean;
  closeModal: () => void;
}> = ({ isModalVisible, closeModal }) => {
  const [form] = Form.useForm();
  const [dataClientList, setDataClientList] = useState<DataClient[]>([]);

  const years = useMemo(() => {
    const currentYear = moment().year();
    const startYear = 2019;
    const diff = currentYear - startYear;
    return Array(diff + 1)
      .fill(startYear)
      .map((value, index) => value + index);
  }, []);

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  useEffect(() => {
    if (isModalVisible) {
      (async () => {
        try {
          const activeDataClientMandates = await getActiveDataMandates();
          const dataClientList = await getDataClients();
          const mandatelessDataClients = dataClientList.filter(
            (dataClient) =>
              !activeDataClientMandates.find(
                (mandate) => mandate.dataClient.kvk === dataClient.kvk,
              ),
          );
          setDataClientList(mandatelessDataClients);
        } catch (e: any) {
          message.error(JSON.stringify(e));
          console.error(e);
        }
      })();
    }
  }, [isModalVisible]);

  const resetForm = () => {
    form.resetFields();
  };

  const handleSave = async () => {
    try {
      const values = { ...(await form.validateFields()), mandateType: 'all' };
      await createDataMandate(values);
      message.success('Datakluis machtiging succesvol toegevoegd.');
      handleClose();
    } catch (e: any) {
      message.error(JSON.stringify(e));
      console.error(e);
    }
  };

  return (
    <Modal
      footer={
        <Space>
          <CustomButton onClick={handleClose}>Annuleren</CustomButton>
          <CustomButton
            disabled={!dataClientList.length}
            color="primary"
            onClick={handleSave}
          >
            Toevoegen
          </CustomButton>
        </Space>
      }
      onCancel={handleClose}
      visible={isModalVisible}
      title="Voeg een datakluis machtiging toe"
    >
      {dataClientList.length ? (
        <Form form={form} layout="vertical">
          <Form.Item name="otherPartyKvk" label="Datakluis" required>
            <Select>
              {dataClientList.map((dataClient) => (
                <Select.Option value={dataClient.kvk}>
                  {dataClient.kvk} - {dataClient.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="yearStart" label="Start jaar">
            <Select allowClear>
              {years.map((year) => (
                <Select.Option value={year}>{year}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="yearEnd" label="Eind jaar">
            <Select allowClear>
              {years.map((year) => (
                <Select.Option value={year}>{year}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      ) : (
        <Empty description="Er zijn geen datakluizen gevonden om te machtigen!" />
      )}
    </Modal>
  );
};
