import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { atoms } from 'atoms';
import {
  createAdvisorMandate,
  deleteAdvisorMandate,
  getActiveAdvisorMandates,
  getAdvisorList,
} from 'adapters/user-adapter';
import { CustomButton } from 'components';
import { useAtomValue } from 'jotai/utils';
import { ReactComponent as SupportIcon } from './support-icon.svg';
import styles from './support.module.scss';
import moment from 'moment';
import { message, notification } from 'antd';
import { AdvisorMandateDto } from 'types';
import { useEffect, useState } from 'react';
import { AdvisorLandingPage } from 'pages';

export const SupportTabPane = () => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);

  // @ts-ignore
  const distributor = keycloak.idTokenParsed?.distributor;

  if (!distributor) return null;

  return (
    <div>
      <SupportIcon className={styles.supportIcon} />
      Support
    </div>
  );
};

export const Support = () => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const [activeAdvisorMandates, setActiveAdvisorMandates] = useState<
    AdvisorMandateDto[]
  >([]);

  const updateActiveAdvisorMandates = async () => {
    try {
      setActiveAdvisorMandates(await getActiveAdvisorMandates());
    } catch (e: any) {
      notification['error']({
        message: 'Er ging iets mis',
        description: e.response?.data?.Message,
      });
      console.error(e);
    }
  };

  useEffect(() => {
    updateActiveAdvisorMandates();
  }, []);

  // @ts-ignore
  const distributor = keycloak.idTokenParsed?.distributor;

  if (!distributor) return null;

  const distributorPhoneNumber =
    window['DISTRIBUTOR_' + distributor.toUpperCase()];
  const distributorName =
    window['DISTRIBUTOR_' + distributor.toUpperCase() + '_NAME'];
  const distributorKVK =
    window['DISTRIBUTOR_' + distributor.toUpperCase() + '_KVK'];
  const distributorEmail =
    window['DISTRIBUTOR_' + distributor.toUpperCase() + '_EMAIL'];

  const hasDistributorPhoneNumber =
    distributor && distributorPhoneNumber !== '';

  const distributorMandate = activeAdvisorMandates.find(
    (mandate) =>
      mandate.status === 'active' &&
      mandate.advisor.kvk === distributorKVK &&
      mandate.levelOfRights === 'read',
  );

  const handleSaveMandate = async () => {
    try {
      const values = {
        mandateType: 'read',
        // yearEnd: moment().year(),
        otherPartyKvk: distributorKVK,
      };
      await createAdvisorMandate(values);
      message.success('Machtiging succesvol toegevoegd.');
      updateActiveAdvisorMandates();
    } catch (e: any) {
      notification['error']({
        message: 'Er ging iets mis',
        description: e.response?.data?.Message,
      });
      console.error(e);
    }
  };

  const handleRemoveMandate = async () => {
    if (distributorMandate) {
      try {
        await deleteAdvisorMandate(distributorMandate.advisorMandateId);
        message.success('Machtiging succesvol verwijderd.');
        updateActiveAdvisorMandates();
      } catch (e: any) {
        notification['error']({
          message: 'Er ging iets mis',
          description: e.response?.data?.Message,
        });
        console.error(e);
      }
    }
  };

  return (
    <>
      <div className={styles.paragraph}>
        <SupportIcon className={styles.supportIcon} />
        <h1 className={styles.title}>Support</h1>
      </div>
      <div className={styles.paragraph}>
        Loopt u ergens op vast in de Digitale Graslandgebruikskalender en heeft
        u hulp nodig?
      </div>
      <div className={styles.paragraph}>
        Mail {hasDistributorPhoneNumber ? 'of bel' : ''} dan uw distributeur{' '}
        {distributorName}:
      </div>
      <div className={styles.paragraph}>
        E-mail: <a href={`mailto:${distributorEmail}`}>{distributorEmail}</a>
        <br />
        {hasDistributorPhoneNumber ? (
          <>
            Telefoon:
            <a href={`tel:${distributorPhoneNumber}`}>
              {distributorPhoneNumber}
            </a>
          </>
        ) : (
          ''
        )}
      </div>
      {distributorKVK && (
        <>
          <div className={styles.paragraph}>
            Wilt u uw distributeur {distributorName} toestaan om met u mee te
            kijken? Druk dan op de onderstaande knop om een machtiging in te
            regelen.
          </div>
          {!distributorMandate ? (
            <CustomButton
              color="primary"
              type="primary"
              shape="round"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => handleSaveMandate()}
            >
              Machtig mijn distributeur om met mij mee te kijken
            </CustomButton>
          ) : (
            <CustomButton
              color="secondary"
              shape="round"
              icon={<FontAwesomeIcon icon={faMinus} />}
              onClick={() => handleRemoveMandate()}
            >
              Verwijder de machtiging van mijn distributeur om met mij mee te
              kijken
            </CustomButton>
          )}
          <div className={styles.paragraph}>
            <small>
              Let op; de bovenstaande knop voegt een machtiging toe aan jouw
              lijst van adviseur machtigingen voor de volgende KVK:{' '}
              {distributorKVK}.
            </small>
          </div>
        </>
      )}
    </>
  );
};
