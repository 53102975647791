import { Theme as AntDTheme } from '@rjsf/antd';
import { withTheme } from '@rjsf/core';
import { Activity } from '@vaadataworks/vaa-planboard';
import { Button } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { useAtomValue } from 'jotai/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export interface SchemaFormProps {
  type: string | undefined;
  submit: (value: any) => any;
  cancel: () => any;
  handleDelete: () => any;
  activity?: Activity<any> | null;
}
export const SchemaForm: FC<SchemaFormProps> = ({
  type,
  submit,
  cancel,
  handleDelete,
  activity,
}) => {
  const { t } = useTranslation();
  const Form = withTheme(AntDTheme);
  const schema = useAtomValue(atoms.schema);

  return type && schema[type] ? (
    <Form
      formData={activity?.properties}
      onSubmit={submit}
      schema={schema[type]}
    >
      <div className={styles.footer}>
        <div className={styles.delete}>
          {activity && (
            <Popconfirm
              title="Weet u zeker dat u de activiteit wilt verwijderen?"
              okText="Bevestig"
              cancelText="Annuleer"
              onConfirm={handleDelete}
            >
              <CustomButton color="danger">Verwijderen</CustomButton>
            </Popconfirm>
          )}
        </div>
        <div className={styles.mainButtons}>
          <Button onClick={cancel}>{t('schemaform.cancel')}</Button>
          <Button htmlType="submit" type="primary">
            {t('schemaform.submit')}
          </Button>
        </div>
      </div>
    </Form>
  ) : null;
};
