import { Tabs } from 'antd';
import { CustomCard } from 'components';
import { Container } from 'components/grid';
import { Account } from 'pages/settings-page/account';
import { FarmerMandates } from './farmer-mandates';

const { TabPane } = Tabs;

export const AdvisorLandingPage = () => {
  return (
    <Container>
      <CustomCard>
        <Tabs tabPosition="left">
          <TabPane tab="Melkveehouder machtigingen" key="1">
            <FarmerMandates />
          </TabPane>
          <TabPane tab="Mijn account" key="3">
            <Account />
          </TabPane>
        </Tabs>
      </CustomCard>
    </Container>
  );
};
