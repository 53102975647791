import { Skeleton } from 'antd';
import { atoms } from 'atoms';
import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { api } from 'utilities/api.axios';

// UPDATE BEARER TOKEN FUNCTION
const updateToken = (token: string | undefined) =>
  (api.defaults.headers.common['Authorization'] = `Bearer ${token}`);

export const AuthComponent: FC = ({ children }) => {
  const [isInitializing, setInitializing] = useState(true);
  const [keycloak, setKeycloakInstance] = useAtom(atoms.auth.keycloakInstance);

  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(60)
      .then((refreshed) => {
        if (refreshed) console.log('refreshed token');
        else console.log('not refreshed token');
      })
      .catch(() => console.error('Failed to refresh token ' + new Date()));
  };

  keycloak.onAuthSuccess = () => updateToken(keycloak.token);
  keycloak.onAuthRefreshSuccess = () => updateToken(keycloak.token);
  keycloak.onAuthError = (error) =>
    console.error('Keycloak onAuthError:', error);
  keycloak.onAuthRefreshError = () =>
    console.error('Keycloak onAuthRefreshError');

  useEffect(() => {
    keycloak
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
      })
      .then((auth: boolean) => {
        setKeycloakInstance(keycloak);
        setInitializing(false);
      })
      .catch((e) => console.log(e));

    return () => console.log('AuthComponent destroyed');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Skeleton loading={isInitializing}>{children}</Skeleton>;
};
