import { Statistic } from 'antd';
import { atoms } from 'atoms';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Account = () => {
  const [user] = useAtom(atoms.user);
  const [labcode, setLabcode] = useState<string>();
  const { t } = useTranslation();

  return (
    user && (
      <>
        <div style={{ marginBottom: 24 }}>
          <Statistic title="Naam" value={user.userName} />
        </div>
        <div style={{ marginBottom: 24 }}>
          <Statistic title="Bedrijfsnaam" value={user.companyName} />
        </div>
        <div style={{ marginBottom: 24 }}>
          <Statistic
            title="KVK"
            value={user.kvk}
            formatter={(value) => value}
          />
        </div>
        <div style={{ marginBottom: 24 }}>
          <Statistic
            title="Rol"
            value={t(`advisorMandates.${user.role}`) as string}
          />
        </div>
      </>
    )
  );
};
