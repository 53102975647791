import { BBox } from '@turf/turf';
import Leaflet from 'leaflet';

export const turfToLeafletLatLngBounds: (
  turfBox: BBox,
) => Leaflet.LatLngBoundsLiteral = (turfBbox) => {
  return [
    [turfBbox[1], turfBbox[0]],
    [turfBbox[3], turfBbox[2]],
  ];
};
