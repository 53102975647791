import area from '@turf/area';
import bbox from '@turf/bbox';
import center from '@turf/center';
import { ErrorBoundary } from 'components';
import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';
import { FeatureGroup, GeoJSON, Map, TileLayer } from 'react-leaflet';
import { turfToLeafletLatLngBounds } from 'utilities/leaflet-utils';
import styles from './styles.module.scss';

const defaultPadding: leaflet.PointTuple = [5, 5];

export const FeatureViewer = ({
  feature,
  mapStyle = {},
  geoJson,
  geoJsonKey,
  colorPoint = () => '#000000',
  paddingBottomRight,
  paddingTopLeft,
}: {
  feature: any;
  mapStyle?: any;
  geoJson?: any;
  geoJsonKey?: any;
  colorPoint?: (feature: any) => string;
  paddingBottomRight?: leaflet.PointTuple;
  paddingTopLeft?: leaflet.PointTuple;
}) => {
  const mapRef = useRef<Map>(null);

  const [position, setPosition] = useState<[number, number]>([
    51.84276079, 5.18380148,
  ]);
  const [zoom, setZoom] = useState<number>(10);

  const zoomToBounds = () => {
    if (feature && mapRef.current?.leafletElement && area(feature) > 0.0001) {
      const leafletElement = mapRef.current.leafletElement;
      leafletElement.fitBounds(
        turfToLeafletLatLngBounds(bbox(feature)),
        paddingBottomRight || paddingTopLeft
          ? {
              paddingBottomRight,
              paddingTopLeft,
            }
          : { padding: defaultPadding },
      );
      leafletElement.invalidateSize();
    } else if (feature && area(feature) <= 0.0001) {
      const centerPoint = center(feature);
      setPosition([
        centerPoint.geometry.coordinates[1],
        centerPoint.geometry.coordinates[0],
      ]);
      setZoom(20);
    }
  };

  useEffect(() => {
    zoomToBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature]);

  useEffect(() => {
    window.addEventListener('resize', zoomToBounds);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', zoomToBounds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <Map
        ref={mapRef}
        style={mapStyle}
        className={styles.featureViewer}
        zoom={zoom}
        center={position}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        closePopupOnClick={false}
        dragging={false}
        zoomSnap={undefined}
        zoomDelta={undefined}
        trackResize={false}
        touchZoom={false}
        zoomControl={false}
        attributionControl={false}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <WMSTileLayer
        url="https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?"
        layers="Actueel_ortho25"
      /> */}
        {feature ? (
          <FeatureGroup>
            <GeoJSON
              data={feature}
              style={{ color: '#0088ff', fillOpacity: 0 }}
            />
            {geoJson && (
              <GeoJSON
                key={geoJsonKey}
                data={geoJson}
                pointToLayer={(feature, latlng) =>
                  leaflet.circle(latlng, {
                    radius: 2,
                    color: colorPoint(feature),
                    fillOpacity: 1,
                  })
                }
              />
            )}
          </FeatureGroup>
        ) : null}
      </Map>
    </ErrorBoundary>
  );
};
