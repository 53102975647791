import { Card } from 'antd';
import styles from './styles.module.scss';

export const CustomCard = ({ children, className = '' }) => {
  return (
    <div className={`${styles.CustomCard} ${className}`}>
      <Card>{children}</Card>
    </div>
  );
};
