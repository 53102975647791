import { FC, PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const LinkWithQuery: FC<PropsWithChildren<{ to: string }>> = ({
  children,
  to,
  ...props
}) => {
  return (
    <Link to={(location) => to + location.search} {...props}>
      {children}
    </Link>
  );
};
