import {
  deleteSubFieldManurePlan,
  getSubfieldManurePlan,
} from 'adapters/manure-adapter';
import { message, notification, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { atoms } from 'atoms';
import { FertilizationPlan } from 'components';
import { EditableTable } from 'components/editable-table/EditableTable';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubFieldManurePlan } from 'types';
import { defaultSorter } from 'utilities/default-sorter';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';

const { Option } = Select;
export interface CutPlanningProps {
  plots: Feature<Geometry, GeoJsonProperties>[];
}
export const CutPlanning: FC<CutPlanningProps> = ({ plots }) => {
  const { t } = useTranslation();
  const cuts = useAtomValue(atoms.cuts).sort();
  const [selectedCut, selectCut] = useState<number>();
  const setModalComponent = useUpdateAtom(atoms.modal.component);
  const [fertilizations, setFertilizations] = useAtom(atoms.manurePlans);
  const setShowModal = useUpdateAtom(atoms.modal.show);
  const organics = useAtomValue(atoms.organicFertilizers);
  const artificials = useAtomValue(atoms.artificialFertilizers);
  const selectedYear = useAtomValue(atoms.selectedYear);
  const setModalState = (comp) => {
    setShowModal(true);
    setModalComponent(comp);
  };
  const [filteredFertilizations, setFilteredFertilizations] =
    useState<SubFieldManurePlan[]>();

  useEffect(() => {
    getFertilizations();

    if (cuts && cuts.length) {
      if (!selectedCut) {
        setSelectedCut(cuts[0]);
      } else {
        setSelectedCut(selectedCut);
      }
    }
  }, []);

  useEffect(() => {
    if (cuts && cuts.length && fertilizations && fertilizations.length) {
      if (!selectedCut) {
        setSelectedCut(cuts[0]);
      } else {
        setSelectedCut(selectedCut);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuts, fertilizations]);

  const getFertilizations = async () => {
    try {
      const tmpFertilizations = await getSubfieldManurePlan({
        year: selectedYear,
      });
      setFertilizations(tmpFertilizations);
    } catch (err) {
      console.error(err);
      notification['error']({
        message: 'Er ging iets mis',
        description: err.toString(),
      });
    }
  };

  const setSelectedCut = (cut: number) => {
    selectCut(cut);
    if (fertilizations && fertilizations.length) {
      const filtered = fertilizations.filter(
        (fer) => fer.harvestNumber === cut,
      );
      setFilteredFertilizations(filtered);
    }
  };

  const editFertilization = (record: any) => {
    setModalState(
      <FertilizationPlan
        onSubmit={() => setShowModal(false)}
        fertilization={record}
      />,
    );
  };

  const deleteFertilization = async (record: SubFieldManurePlan) => {
    if (record.subFieldManurePlanId) {
      try {
        await deleteSubFieldManurePlan(record.subFieldManurePlanId);
        setFertilizations((current) =>
          current.filter(
            (fert) => fert.subFieldManurePlanId !== record.subFieldManurePlanId,
          ),
        );
        message.success('Succesvol verwijderd!');
        getFertilizations();
      } catch (e: any) {
        message.error('Er ging iets mis');
        console.error(e);
      }
    }
  };

  const format = (value) =>
    value === undefined || value === null
      ? ''
      : displayWithDecimals(2)(roundToDecimals(2)(value));

  const InitColumns = [
    {
      id: 'subfieldId',
      render: (subfieldId, record) =>
        plots.find((z) => z.id === record.subFieldId)?.properties?.name,
      sorter: (a, b) => {
        const aName = plots.find((z) => z.id === a.subFieldId)?.properties
          ?.name;
        const bName = plots.find((z) => z.id === b.subFieldId)?.properties
          ?.name;

        return defaultSorter((name: string) => name)(aName, bName);
      },
      sortOrder: 'ascend',
      sortDirections: ['ascend'],
    },
    { id: 'usage', render: (value) => t(`fertilizationpage.usages.${value}`) },
    {
      id: 'organicFertilizerId',
      render: (value) =>
        organics.find((organic) => organic.id === value)?.fertilizerType,
    },
    {
      id: 'amountOfManure',
      render: format,
    },
    {
      id: 'artificialFertilizerId',
      render: (value) =>
        artificials.find((artificial) => artificial.id === value)
          ?.fertilizerType,
    },
    {
      id: 'amountOfFertilizer',
      render: format,
    },
    {
      id: 'mineralization',
      responsive: ['lg'],
      render: format,
    },
    {
      id: 'nitrogenSupply',
      responsive: ['lg'],
      render: format,
    },
    {
      id: 'phosphateSupply',
      responsive: ['lg'],
      render: format,
    },
  ];

  const columns = InitColumns.map((c) => ({
    title: t(`fertilizationpage.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    responsive: c.responsive,
    sorter: c.sorter,
    sortOrder: c.sortOrder,
    sortDirections: c.sortDirections,
    render: (text, record) => {
      if (c.render) return c.render(text, record);
      return text;
    },
  })) as ColumnsType<SubFieldManurePlan>;

  return (
    <>
      {cuts.length > 0 && (
        <h1>
          Snede{' '}
          <Select value={selectedCut} onChange={setSelectedCut}>
            {cuts.map((cut) => (
              <Option value={cut}>{cut}</Option>
            ))}
          </Select>
        </h1>
      )}
      <EditableTable
        numberKeys={['organicM3', 'artificialKg']}
        editFunc={editFertilization}
        deleteFunc={deleteFertilization}
        columns={columns}
        dataSource={filteredFertilizations}
      />
    </>
  );
};
