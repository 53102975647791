import { postSubfieldData } from 'adapters/manure-adapter';
import { addSubfieldToMonster } from 'adapters/monster-adapter';
import { Button, Form, Input, Select } from 'antd';
import { atoms } from 'atoms';
import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubFieldData } from 'types';
import { Monster } from 'types/monster';
import { defaultSorter } from 'utilities/default-sorter';
import { CustomInputNumber } from 'utilities/InputNumberNlFormat';
import styles from './styles.module.scss';
export interface PlotModalProps {
  onSubmit: () => any;
  subfield: SubFieldData;
}
export const PlotModal: FC<PlotModalProps> = ({ onSubmit, subfield }) => {
  const [plots] = useAtom(atoms.plots);
  const [monsters, setMonsters] = useAtom(atoms.monsters);
  const [selectedMonster, setSelectedMonster] = useState<Monster>();
  const { t } = useTranslation();
  const [_, setSubfields] = useAtom(atoms.subfields);
  const [form] = Form.useForm();
  const onfinish = (values) => {
    editFertilization(values);
  };
  const resetForm = () => {
    form.resetFields();
  };
  useEffect(() => {
    form.setFieldsValue({
      ...subfield,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editFertilization = async (values: any) => {
    const response = await postSubfieldData(values);
    setSubfields((current) => {
      const plans = current.filter(
        (fert) => fert.subFieldId !== subfield?.subFieldId,
      );
      return [...plans, response];
    });
    if (selectedMonster)
      addSubfieldToMonster({
        monster: selectedMonster,
        subfield: response,
      }).then((monsters) => setMonsters(monsters));

    resetForm();
    onSubmit();
  };

  return (
    <Form
      layout={'horizontal'}
      labelAlign="left"
      labelCol={{ span: 10 }}
      colon={false}
      form={form}
      onFinish={onfinish}
    >
      <h1>{t('plotmodal.edit')}</h1>
      <Form.Item name="subFieldId" label={t('plotmodal.plot')}>
        <div>
          {plots.find((z) => z.id === subfield.subFieldId)?.properties?.name}
        </div>
      </Form.Item>
      <Form.Item hidden name="key">
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item
        name="hectareToBeFertalized"
        label={t('plotmodal.hectareToBeFertalized')}
      >
        <CustomInputNumber precision={2} className={styles.field} />
      </Form.Item>
      <Form.Item name="groundType" label={t('plotmodal.groundType')}>
        <Select>
          <Select.Option value="clay">Klei</Select.Option>
          <Select.Option value="moor">Veen</Select.Option>
          <Select.Option value="sand">Zand</Select.Option>
          <Select.Option value="loss">Loss</Select.Option>
          <Select.Option value="unknown">Onbekend</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="soilSampleId" label={t('plotmodal.monster')}>
        <Select allowClear>
          {monsters.sort(defaultSorter((x) => x.name)).map((monster) => (
            <Select.Option value={monster.id}>{monster.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="phosphateCondition"
        label={t('plotmodal.phosphateCondition')}
      >
        <Select>
          <Select.Option value="low">Laag</Select.Option>
          <Select.Option value="neutral">Neutraal</Select.Option>
          <Select.Option value="plenty">Overvloedig</Select.Option>
          <Select.Option value="high">Hoog</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, cur) => prev.soilSampleId !== cur.soilSampleId}
      >
        {({ getFieldValue }) => {
          const newSelectedMonster = monsters.find(
            (monster) => monster.id === getFieldValue('soilSampleId'),
          );
          if (
            newSelectedMonster?.nlv?.value &&
            selectedMonster !== newSelectedMonster
          ) {
            form.setFieldsValue({
              nitrogenSupplyingCapactity: newSelectedMonster.nlv?.value,
            });
            setSelectedMonster(newSelectedMonster);
          }
          return (
            <Form.Item
              name="nitrogenSupplyingCapactity"
              label={t('plotmodal.nitrogenSupplyingCapactity')}
            >
              <CustomInputNumber className={styles.field} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, cur) => prev.soilSampleId !== cur.soilSampleId}
      >
        {({ getFieldValue }) => {
          const newSelectedMonster = monsters.find(
            (monster) => monster.id === getFieldValue('soilSampleId'),
          );
          if (
            newSelectedMonster?.ph?.value &&
            selectedMonster !== newSelectedMonster
          ) {
            form.setFieldsValue({
              ph: newSelectedMonster.ph?.value,
            });
            setSelectedMonster(newSelectedMonster);
          }
          return (
            <Form.Item name="ph" label={t('plotmodal.ph')}>
              <CustomInputNumber precision={1} className={styles.field} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <div style={{ textAlign: 'right' }}>
        <Button htmlType="submit" type="primary">
          {t('plotmodal.submit')}
        </Button>
      </div>
    </Form>
  );
};
