// @ts-ignore
const API_URL = window.API_URL;

const BASE_URL = `${API_URL}/cropfield`;

export const redirectToRVO = (selectedYear: number, kvk: string) => {
  window.location.href = getPVS_RVO_URL(selectedYear, kvk);
};

export const getPVS_RVO_URL = (selectedYear: number, kvk: string) => {
  return `${BASE_URL}/v1/RvoCropField/GetParcelsFromRvo?year=${selectedYear}&kvk=${kvk}&redirectUrl=${encodeURIComponent(
    window.location.href,
  )}`;
};
