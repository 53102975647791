import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { atoms } from 'atoms';
import { useAtomValue } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import { defaultSorter } from 'utilities/default-sorter';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';

const format = (decimals) => (value) =>
  value === undefined || value === null
    ? ''
    : displayWithDecimals(decimals)(roundToDecimals(decimals)(value));

export const SubfieldRealized = ({ yearlyOverview }) => {
  const { t } = useTranslation();
  const plots = useAtomValue(atoms.plots);

  const InitColumns = [
    {
      id: 'subfieldId',
      render: (subfieldId, record) =>
        plots.find((z) => z.id === record.subFieldId)?.properties?.name,
      sorter: (a, b) => {
        const aName = plots.find((z) => z.id === a.subFieldId)?.properties
          ?.name;
        const bName = plots.find((z) => z.id === b.subFieldId)?.properties
          ?.name;

        return defaultSorter((name: string) => name)(aName, bName);
      },
      sortOrder: 'ascend',
      sortDirections: ['ascend'],
    },
    { id: 'hectare', render: format(2) },
    { id: 'm3ManurePerHectare', render: format(2) },
    { id: 'kgWorkingNitrogenPerHectareFromManure', render: format(2) },
    { id: 'kgPhosphatePerHectareFromManure', render: format(2) },
    { id: 'kgNitrogenPerHectareFromFertilizer', render: format(2) },
    { id: 'kgNitrogenTotalPerHectare', render: format(2) },
    { id: 'totalPastureDays', render: format(0) },
    { id: 'numberOfHarvests', render: format(0) },
    { id: 'estimatedKgGrazedPerHectare', render: format(0) },
    { id: 'kgMowedPerHectare', render: format(0) },
    { id: 'kgFedPerHectare', render: format(0) },
    { id: 'totalKgHarvestedPerHectare', render: format(0) },
    { id: 'totalKgHarvested', render: format(0) },
    { id: 'kgMowedTotal', render: format(0) },
  ];

  const columns = InitColumns.map((c) => ({
    title: t(`yearlyoverview.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    render: c.render,
    sorter: c.sorter,
    sortOrder: c.sortOrder,
    sortDirections: c.sortDirections,
  })) as ColumnsType<any>;

  // yearlyOverview.annualReviewPerSubfieldsRealized
  return (
    <Table
      loading={!yearlyOverview}
      dataSource={yearlyOverview?.annualReviewPerSubfieldsRealized}
      columns={columns}
      scroll={{ x: true }}
    ></Table>
  );
};
