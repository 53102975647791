import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Activity,
  Config,
  Header,
  Planboard,
} from '@vaadataworks/vaa-planboard';
import { getSubfields } from 'adapters/cropfield-adapter';
import { addSubfieldToSilage } from 'adapters/monster-adapter';
import {
  deleteActivity,
  getActivities,
  postActivity,
} from 'adapters/planning-adapter';
import { message, Spin } from 'antd';
import { atoms } from 'atoms';
import { ActivityModal, CustomButton } from 'components';
import { PlanboardStyling } from 'components/planboard-styling';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import moment from 'moment';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import activityStyles from 'styles/styling.json';
import {
  mapActivityToDto,
  mapDtoArrayToActivityArray,
  mapDtoToActivity,
} from 'utilities/activity-mapper';
import { styleActivitiesByProperty } from 'utilities/activity-style';
import { defaultSorter } from 'utilities/default-sorter';
import { ActivityPopOver } from './popover';
import styles from './styles.module.scss';

export const PlanboardPage = () => {
  const { t } = useTranslation();
  const [activities, setActivities] = useState<Activity<any>[]>([]);
  const [subfields, setSubfields] = useAtom(atoms.plots);
  const [silages] = useAtom(atoms.silages);
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [activityModalVisible, setActivityModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const setModalComponent = useSetAtom(atoms.modal.component);
  const setShowModal = useSetAtom(atoms.modal.show);
  const setModalState = (comp) => {
    setShowModal(true);
    setModalComponent(comp);
  };
  const [modifyActivity, setModifyActivity] =
    useState<Activity<any> | null>(null);
  const [styledActivities, setStyledActivities] = useState(
    styleActivitiesByProperty(activities, 'activityType', activityStyles),
  );

  const createActivity = useCallback(
    async (activity: Activity<any>) => {
      const activityDTO = mapActivityToDto(activity);
      const response = await postActivity(activityDTO);
      const parsedActivity = mapDtoToActivity(response);
      const act = {
        ...parsedActivity,
        properties: {
          ...parsedActivity.properties,
          plotName: subfields.find((x) => x.id === activity.properties.plot)
            ?.properties?.name,
        },
      };
      setActivities((current) => [...current, act]);
    },
    [subfields],
  );

  const updateActivity = (activity: Activity<any>) => {
    postActivity(mapActivityToDto(activity));
    setActivities((prev) => {
      const list = [...prev];
      const index = list.findIndex((x) => x.id === activity.id);
      if (index === -1) {
        throw new Error(`No activity found with id ${activity.id}`);
      }
      list[index] = activity;
      return list;
    });
  };

  const onDragActivity = useCallback(
    (activity: Activity<any>) => {
      const plot = subfields.find(
        (plot) => plot.properties?.name === activity.properties.plotName,
      );

      if (plot) {
        activity.properties.plot = plot.id;
      }

      updateActivity(activity);
    },
    [subfields],
  );

  const onAddActivity = useCallback(
    (activity: Activity<any>) => {
      const plot = subfields.find(
        (plot) => plot.properties?.name === activity.properties.plotName,
      );

      if (plot) {
        activity.properties.plot = plot.id;
      }

      createActivity(activity);
    },
    [subfields, createActivity],
  );

  const [config, setConfig] = useState<Config>({
    dimensions: ['plotName'],
    startDate: moment(`${selectedYear}-02-15`, 'YYYY-MM-DD'),
    endDate: moment().set('year', selectedYear).endOf('year'),
    steps: 'year',
    contentOnActivityPopover: (activity) => (
      <ActivityPopOver activity={activity} />
    ),
    contentOnActivityPopoverTitle: (activity) =>
      t(`types.${activity.activityType}`),
    onNewActivity: (start, end, dimensionPath) => {
      setModifyActivity(null);
      setActivityModalVisible(true);
    },
    onActivityEdit: (activity: Activity<any>) => {
      setModifyActivity(activity);
      setActivityModalVisible(true);
    },
  });

  useEffect(() => {
    // TODO: do the following in setter or getter of the Atom
    const loadData = async () => {
      setLoading(true);
      const subFields = await getSubfields({ year: selectedYear });
      setSubfields(subFields);
      const activities = mapDtoArrayToActivityArray(
        await getActivities({ year: selectedYear }),
      );
      const newActivities = activities.map((activity) => {
        return {
          ...activity,
          properties: {
            ...activity.properties,
            plotName: subFields.find((x) => x.id === activity.properties.plot)
              ?.properties?.name,
          },
        };
      });
      setActivities(newActivities);
    };
    loadData().finally(() => {
      document
        .getElementsByClassName('styles-module_nowLine__2fIjR')[0]
        ?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      setLoading(false);
    });
    changeDate([
      moment(`${selectedYear}-02-15`, 'YYYY-MM-DD'),
      moment().set('year', selectedYear).endOf('year'),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  useEffect(() => {
    setStyledActivities(
      styleActivitiesByProperty(activities, 'activityType', activityStyles),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  const changeDate = (dateRange) => {
    if (!dateRange) {
      return;
    }
    setConfig((current) => ({
      ...current,
      startDate: dateRange[0],
      endDate: dateRange[1],
    }));
  };

  const changeStep = (step) => {
    setConfig((current) => ({
      ...current,
      steps: step,
    }));
  };

  const handleActivitySubmit = useCallback(
    (activity?: Activity<any>) => {
      setActivityModalVisible(false);
      if (!activity) {
        return;
      }
      if (!activity.id) {
        createActivity(activity);
      } else {
        updateActivity(activity);
      }
    },
    [createActivity],
  );

  const handleActivityDelete = (activityId: string | number) => {
    setActivityModalVisible(false);
    deleteActivity(activityId)
      .then(() => {
        setActivities((current) =>
          current.filter((activity) => activity.id !== activityId),
        );
        message.success('Activiteit succesvol verwijderd.');
      })
      .catch((e) => {
        message.error(JSON.stringify(e));
      });
  };

  const disabledDate = (date) => {
    return (
      date < moment().set('year', selectedYear).startOf('year') ||
      date > moment().set('year', selectedYear).endOf('year')
    );
  };

  return (
    <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
      <ActivityModal
        modifyActivity={modifyActivity}
        visible={activityModalVisible}
        onSubmit={handleActivitySubmit}
        handleActivityDelete={handleActivityDelete}
        plots={subfields}
      />
      <div style={{ height: 10 }}></div>
      <Header
        onChangeDate={changeDate}
        dateRangeValue={[config.startDate, config.endDate]}
        ranges={{
          'Het hele jaar': [
            moment().set('year', selectedYear).startOf('year'),
            moment().set('year', selectedYear).endOf('year'),
          ],
        }}
        disabledDate={disabledDate}
        defaultStep={config.steps}
        onChangeStep={changeStep}
        localisation={{
          steps: {
            days: 'Dagen',
            year: 'Jaar',
          },
        }}
        buttons={[
          <CustomButton
            color="secondary"
            shape="circle"
            onClick={() => setModalState(<PlanboardStyling />)}
            icon={<FontAwesomeIcon icon="palette" />}
          />,
        ]}
        onNewActivty={config.onNewActivity}
        title={t('planboard.title')}
      />
      <div className={styles.planboardWrapper}>
        <Planboard
          activities={styledActivities}
          defaultDimensions={[
            ...subfields
              .sort(defaultSorter((plot) => plot.properties?.name))
              .map((plot) => plot.properties?.name),
          ]}
          config={config}
          sorter={defaultSorter((x) => x.dimension)}
          onChangeActivity={onDragActivity}
          onAddActivity={onAddActivity}
        ></Planboard>
      </div>
    </Spin>
  );
};
