import { Activity } from '@vaadataworks/vaa-planboard';
import { getSubfields } from 'adapters/cropfield-adapter';
import { postActivity } from 'adapters/planning-adapter';
import { Col, Row, Tabs } from 'antd';
import { atoms } from 'atoms';
import { ActivityModal, CustomButton, FeatureSelect } from 'components';
import { Container } from 'components/grid';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { RvoCard } from 'pages/plots/RvoCard';
import { useCallback, useEffect, useState } from 'react';
import { mapActivityToDto } from 'utilities/activity-mapper';
import styles from './styles.module.scss';

const { TabPane } = Tabs;

export const Mobile = () => {
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [plots, setPlots] = useAtom(atoms.plots);
  const [activityModalVisible, setActivityModalVisible] = useState(false);

  useEffect(() => {
    getSubfields({ year: selectedYear }).then((res) => {
      setPlots(res);
    });
  }, [selectedYear, setPlots]);

  const checkSelected = (id: string) => selectedIds.includes(id);
  const addSelected = (id: string) =>
    setSelectedIds((current) => [...current, id]);
  const removeSelected = (id: string) =>
    setSelectedIds((current) => current.filter((selected) => selected !== id));

  const toggleSelected = (id: string) => {
    if (checkSelected(id)) removeSelected(id);
    else addSelected(id);
  };

  const createActivity = async (activity: Activity<any>) => {
    const activityDTO = mapActivityToDto(activity);
    const response = await postActivity(activityDTO);
    return response;
  };

  const handleActivitySubmit = useCallback((activity?: Activity<any>) => {
    setActivityModalVisible(false);
    if (!activity) {
      return;
    }
    createActivity(activity);
  }, []);

  return (
    <div className={styles.vaaContent}>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Lijst" key="1">
          <Container>
            {plots.map((plot) => (
              <RvoCard
                checkSelected={checkSelected}
                toggleSelected={toggleSelected}
                plot={plot}
                getPlotName={(plot) => plot.properties.name}
                getPlotId={(plot) => plot.properties.id}
              />
            ))}
          </Container>
        </TabPane>
        <TabPane tab="Kaart" key="2" className={styles.kaart}>
          <Container className={styles.kaartContainer}>
            <FeatureSelect
              features={plots}
              selected={selectedIds}
              onChangeSelected={setSelectedIds}
            />
          </Container>
        </TabPane>
      </Tabs>
      <div className={styles.activity}>
        <CustomButton
          shape="round"
          color="primary"
          onClick={() => setActivityModalVisible(true)}
        >
          Activiteit registreren
        </CustomButton>
      </div>
      <ActivityModal
        visible={activityModalVisible}
        onSubmit={handleActivitySubmit}
        plots={plots}
        defaultPlotIds={selectedIds}
      />
    </div>
  );
};
