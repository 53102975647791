import { deleteArtificialFertilizer } from 'adapters/manure-adapter';
import { Divider, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { atoms } from 'atoms';
import { ArtificialFertilizerForm } from 'components/artificial-modal';
import { EditableTable } from 'components/editable-table/EditableTable';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ArtificialFertilizer } from 'types/artificialFertilizer';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';

export interface ArtificialFertilizersProps {}
export const ArtificialFertilizers: FC<ArtificialFertilizersProps> = () => {
  const setArtificialFertilizers = useUpdateAtom(atoms.artificialFertilizers);
  const defaultArtificialFertilizers = useAtomValue(
    atoms.defaultArtificialFertilizers,
  );
  const ownArtificialFertilizers = useAtomValue(atoms.ownArtificialFertilizers);
  const { t } = useTranslation();
  const setModalComponent = useUpdateAtom(atoms.modal.component);
  const setShowModal = useUpdateAtom(atoms.modal.show);
  const setModalState = (comp) => {
    setShowModal(true);
    setModalComponent(comp);
  };

  const format = (value) =>
    value === undefined || value === null
      ? ''
      : displayWithDecimals(2)(roundToDecimals(2)(value));

  const editFertilizer = (record: any) => {
    setModalState(
      <ArtificialFertilizerForm
        onSubmit={() => setShowModal(false)}
        fertilizer={record}
      />,
    );
  };
  const deleteFertilizer = (record: ArtificialFertilizer) => {
    if (record.id) {
      deleteArtificialFertilizer({ id: record.id }).then((r) =>
        setArtificialFertilizers((current) =>
          current.filter((fert) => fert.id !== record.id),
        ),
      );
    }
  };
  const InitColumns = [
    { id: 'fertilizerType' },
    { id: 'amount', render: format },
    { id: 'nitrogen', render: format },
    { id: 'ammonium', render: format },
    { id: 'nitrate', render: format },
    { id: 'urea', render: format },
    { id: 'amide', render: format },
    { id: 'potassiumOxide', render: format },
    { id: 'phosphorusPentoxide', render: format },
    { id: 'magnesiumOxide', render: format },
    { id: 'sulfurTrioxide', render: format },
    { id: 'calciumOxide', render: format },
    { id: 'sodiumOxide', render: format },
    { id: 'selenium', render: format },
    { id: 'copper', render: format },
    { id: 'cobalt', render: format },
    { id: 'chlorine', render: format },
    { id: 'nitrificationInhibitor', render: format },
    { id: 'other' },
  ];

  const columns = InitColumns.map((c) => ({
    title: t(`artificialpage.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    render: c.render,
  })) as ColumnsType<ArtificialFertilizer>;
  return (
    <>
      <h2>Standaard</h2>
      <Table
        columns={columns.filter((c) => c.key !== 'amount')}
        bordered
        dataSource={defaultArtificialFertilizers}
      />
      <Divider />
      <h2>Eigen</h2>
      <EditableTable
        editFunc={editFertilizer}
        deleteFunc={deleteFertilizer}
        columns={columns}
        dataSource={ownArtificialFertilizers}
      />
    </>
  );
};
