import bbox from '@turf/bbox';
import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FeatureGroup,
  GeoJSON,
  Map,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
} from 'react-leaflet';
import { turfToLeafletLatLngBounds } from 'utilities/leaflet-utils';
import styles from './styles.module.scss';

const defaultPadding: leaflet.PointTuple = [5, 5];
const defaultStyle = { color: '#0000DD', fillOpacity: 0.2 };
const selectedStyle = { color: '#ff0000', fillOpacity: 0.2 };

export const FeatureSelect = ({
  features,
  selected = [],
  onChangeSelected = () => {},
  mapStyle = {},
  colorPoint = () => '#000000',
  paddingBottomRight,
  paddingTopLeft,
}: {
  features: any;
  selected?: string[];
  onChangeSelected?: (selected: string[]) => void;
  mapStyle?: any;
  colorPoint?: (feature: any) => string;
  paddingBottomRight?: leaflet.PointTuple;
  paddingTopLeft?: leaflet.PointTuple;
}) => {
  const mapRef = useRef<Map>(null);

  const featureCollection = {
    type: 'FeatureCollection',
    features: features.filter(
      (feature) => !selected.find((id) => feature.properties.id === id),
    ),
  };
  const selectedFeatureCollection = {
    type: 'FeatureCollection',
    features: features.filter(
      (feature) => !!selected.find((id) => feature.properties.id === id),
    ),
  };

  const zoomToBounds = () => {
    const leafletElement = mapRef.current.leafletElement;

    leafletElement.fitBounds(
      turfToLeafletLatLngBounds(
        bbox({
          type: 'FeatureCollection',
          features: features,
        }),
      ),
      paddingBottomRight || paddingTopLeft
        ? {
            paddingBottomRight,
            paddingTopLeft,
          }
        : { padding: defaultPadding },
    );

    leafletElement.invalidateSize();
  };

  useEffect(() => {
    zoomToBounds();
  }, [features]);

  const onSelectFeature = (e) => {
    onChangeSelected([...selected, e.layer.feature.properties.id]);
  };

  const onDeselectFeature = (e) => {
    onChangeSelected(
      selected.filter((id) => id !== e.layer.feature.properties.id),
    );
  };

  return (
    <Map
      ref={mapRef}
      style={mapStyle}
      className={styles.featureViewer}
      zoom={10}
      center={[51.84276079, 5.18380148]}
      attributionControl={false}
      zoomControl={false}
    >
      <ZoomControl position="bottomright" />
      {/* <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      /> */}
      <WMSTileLayer
        url="https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?"
        layers="Actueel_ortho25"
      />
      <FeatureGroup onClick={onSelectFeature}>
        {featureCollection.features.map((feature) => (
          <GeoJSON
            key={feature.properties.id}
            data={feature}
            style={defaultStyle}
          />
        ))}
      </FeatureGroup>
      <FeatureGroup onClick={onDeselectFeature}>
        {selectedFeatureCollection.features.map((feature) => (
          <GeoJSON
            key={feature.properties.id}
            data={feature}
            style={selectedStyle}
          />
        ))}
      </FeatureGroup>
    </Map>
  );
};
