import { faSpaceShuttle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyDataFromPreviousYear } from 'adapters/cropfield-adapter';
import { Popconfirm } from 'antd';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { useAtom } from 'jotai';

export const DataTransfer = () => {
  const [selectedYear] = useAtom(atoms.selectedYear);

  const transfer = () => {
    copyDataFromPreviousYear(selectedYear);
  };

  return (
    <>
      <Popconfirm
        title={`Weet u zeker dat u uw data (gebruikspercelen, mest soorten en bemestingsplan) van voorgaande jaar (${
          selectedYear - 1
        }) wilt over zetten naar huidig geselecteerde jaar (${selectedYear})?`}
        okText="Bevestig"
        cancelText="Annuleer"
        onConfirm={transfer}
      >
        <CustomButton
          color="primary"
          icon={<FontAwesomeIcon icon={faSpaceShuttle}></FontAwesomeIcon>}
        >
          Data overzetten van voorgaande jaar naar huidig geselecteerde jaar
        </CustomButton>
      </Popconfirm>
    </>
  );
};
