import { getSubfieldData } from 'adapters/manure-adapter';
import { ColumnsType } from 'antd/lib/table';
import { atoms } from 'atoms';
import { PlotModal } from 'components';
import { EditableTable } from 'components/editable-table/EditableTable';
import { useAtom } from 'jotai';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubFieldData } from 'types';
import { defaultSorter } from 'utilities/default-sorter';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { areaToHecatare } from 'utilities/geometry-to-area';
import { roundToDecimals } from 'utilities/roundToDecimals';

export interface PlotInfoProps {}
export const PlotInfo: FC<PlotInfoProps> = () => {
  const { t } = useTranslation();
  const [selectedYear] = useAtom(atoms.selectedYear);
  const [modal, setModalComponent] = useAtom(atoms.modal.component);
  const [showModal, setShowModal] = useAtom(atoms.modal.show);
  const [plots, setPlots] = useAtom(atoms.subfields);
  const [croptypes] = useAtom(atoms.croptypes);
  const [monsters] = useAtom(atoms.monsters);
  const [plotsList] = useAtom(atoms.plots);

  useEffect(() => {
    (async () => {
      const subfieldData = await getSubfieldData({ year: selectedYear });
      setPlots(subfieldData);
    })();
  }, [selectedYear, setPlots]);

  const setModalState = (comp) => {
    setShowModal(true);
    setModalComponent(comp);
  };
  const editSubfield = (record: any) => {
    setModalState(
      <PlotModal onSubmit={() => setShowModal(false)} subfield={record} />,
    );
  };
  const PlotInfoInitColumns = [
    {
      id: 'plot',
      render: (plot, record) =>
        plotsList.find((z) => z.id === record.subFieldId)?.properties?.name,
      sorter: (a, b) => {
        const aName = plotsList.find((z) => z.id === a.subFieldId)?.properties
          ?.name;
        const bName = plotsList.find((z) => z.id === b.subFieldId)?.properties
          ?.name;

        return defaultSorter((name: string) => name)(aName, bName);
      },
      sortOrder: 'ascend',
      sortDirections: ['ascend'],
    },
    {
      id: 'area',
      render: (plot, record) =>
        areaToHecatare(
          plotsList.find((z) => z.id === record.subFieldId)?.properties?.area,
        ),
    },
    {
      id: 'fertileArea',
      render: (plot, record) =>
        displayWithDecimals(2)(
          roundToDecimals(2)(record.hectareToBeFertalized),
        ),
    },
    {
      id: 'groundType',
      responsive: ['lg'],
      render: (plot, record) =>
        t(`fertilizationpage.groundTypes.${record.groundType}`),
    },
    {
      id: 'soilSampleId',
      responsive: ['lg'],
      render: (plot, record) =>
        monsters.find((f) => f.id === record.soilSampleId)?.name,
    },

    {
      id: 'P',
      responsive: ['lg'],
      render: (plot, record) =>
        t(`fertilizationpage.phosphateConditions.${record.phosphateCondition}`),
    },
    {
      id: 'N',
      responsive: ['lg'],
      render: (plot, record) =>
        displayWithDecimals(0)(
          roundToDecimals(0)(record.nitrogenSupplyingCapactity),
        ),
    },
    {
      id: 'pH',
      responsive: ['lg'],
      render: (plot, record) =>
        displayWithDecimals(1)(roundToDecimals(1)(record.ph)),
    },
    {
      id: 'crop',
      responsive: ['lg'],
      render: (plot, record) => {
        const subfield = plotsList.find((z) => z.id === record.subFieldId);
        return subfield
          ? croptypes.find(
              (type) => type.cropTypeId === subfield.properties?.cropTypeId,
            )?.description
          : null;
      },
    },
  ];
  // render: (t, r, index) => (
  // <Form.Item name={[`plots[${index}]`, 'crop']}></Form.Item>
  // ),
  const plotColumns = PlotInfoInitColumns.map((c) => ({
    title: t(`fertilizationpage.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    responsive: c.responsive,
    sorter: c.sorter,
    sortOrder: c.sortOrder,
    sortDirections: c.sortDirections,
    render: (text, record) => {
      if (c.render) return c.render(text, record);
      return text;
    },
  })) as ColumnsType<SubFieldData>;
  return (
    <>
      <h1>Perceelsinformatie</h1>
      <EditableTable
        numberKeys={['pH']}
        columns={plotColumns}
        dataSource={plots}
        editFunc={editSubfield}
      />
    </>
  );
};
