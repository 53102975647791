import dayjs from 'dayjs';
import { atom } from 'jotai';
import qs from 'qs';
import history from 'utilities/history';

let qsYear;
const qsYearString = qs
  .parse(window.location.search, { ignoreQueryPrefix: true })
  .year?.toString();
if (qsYearString && !isNaN(parseInt(qsYearString))) {
  qsYear = parseInt(qsYearString);
}

const _selectedYear = atom(qsYear || dayjs().year());

export const selectedYear = atom<number, number>(
  (get) => get(_selectedYear),
  (_get, set, newYear) => {
    set(_selectedYear, newYear);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('year', newYear + '');

    history.push({ search: '?' + searchParams.toString() });
  },
);
