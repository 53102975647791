import {
  createOrganicFertilizer,
  updateOrganicFertilizer,
} from 'adapters/manure-adapter';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { atoms } from 'atoms';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganicFertilizer } from 'types/organicFertilizer';
import { CustomInputNumber } from 'utilities/InputNumberNlFormat';
import styles from './styles.module.scss';

export interface OrganicFertilizerFormProps {
  onSubmit: () => any;
  fertilizer?: OrganicFertilizer;
}
export const OrganicFertilizerForm: FC<OrganicFertilizerFormProps> = ({
  onSubmit,
  fertilizer,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const setFertilizers = useUpdateAtom(atoms.organicFertilizers);
  const selectedYear = useAtomValue(atoms.selectedYear);
  useEffect(() => {
    if (fertilizer) {
      form.setFieldsValue(fertilizer);
    }
  }, [fertilizer, form]);

  const onfinish = (values) => {
    console.log(values);
    if (fertilizer) {
      updateFertilizer(values);
      resetForm();
      return;
    }
    addFertilizer(values);
    resetForm();
    return;
  };
  const resetForm = () => {
    form.resetFields();
    onSubmit();
  };

  const addFertilizer = async (values: any) => {
    const fertilizer = await createOrganicFertilizer(
      values as OrganicFertilizer,
    );
    setFertilizers((current) => [...current, fertilizer]);
    resetForm();
    onSubmit();
  };

  const updateFertilizer = async (values: any) => {
    const fertilizer = await updateOrganicFertilizer(
      values as OrganicFertilizer,
    );
    setFertilizers((current) => [
      ...current.filter((f) => f.id !== fertilizer.id),
      fertilizer,
    ]);
    resetForm();
    onSubmit();
  };
  return (
    <Form
      layout={'horizontal'}
      labelCol={{ span: 7 }}
      labelAlign="left"
      colon={false}
      form={form}
      onFinish={onfinish}
    >
      <h1>Organische mestsoort toevoegen</h1>
      <Form.Item hidden name="id">
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item hidden name="farmId">
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item initialValue={selectedYear} hidden name="year">
        <InputNumber className={styles.field} />
      </Form.Item>
      <Form.Item
        name="fertilizerType"
        label={t('organicpage.description')}
        required
      >
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item name="lifestockType" label={t('organicpage.lifestockType')}>
        <Select>
          <Select.Option value="rundvee">rundvee</Select.Option>
          <Select.Option value="schapen">schapen</Select.Option>
          <Select.Option value="geiten">geiten</Select.Option>
          <Select.Option value="paarden">paarden</Select.Option>
          <Select.Option value="ezels">ezels</Select.Option>
          <Select.Option value="ponys">ponys</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="manureCode" label={t('organicpage.manureCode')}>
        <InputNumber className={styles.field} />
      </Form.Item>
      <Form.Item
        name="kgNitrogenPerTon"
        label={t('organicpage.kgNitrogenPerTon')}
      >
        <CustomInputNumber precision={2} className={styles.field} />
      </Form.Item>
      <Form.Item
        name="kgPhosphatePerTon"
        label={t('organicpage.kgPhosphatePerTon')}
      >
        <CustomInputNumber precision={2} className={styles.field} />
      </Form.Item>
      <Form.Item
        name="workingCoefficient"
        label={t('organicpage.workingCoefficient')}
      >
        <CustomInputNumber
          precision={2}
          step={0.01}
          min={0}
          max={1}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="additive" label={t('organicpage.additive')}>
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item
        name="workingKgNitrogenPerTon"
        label={t('organicpage.workingKgNitrogenPerTon')}
      >
        <CustomInputNumber precision={2} className={styles.field} />
      </Form.Item>
      <Form.Item name="amount" label={t('organicpage.amount')}>
        <CustomInputNumber precision={2} className={styles.field} />
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button htmlType="submit" type="primary">
          {t('fertilizationplan.submit')}
        </Button>
      </div>
    </Form>
  );
};
