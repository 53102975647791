import { Feature } from 'geojson';
import { atom } from 'jotai';
import { SubFieldData, SubFieldManurePlan } from 'types';

export const plots = atom<Feature[]>([]);
export const subfields = atom<SubFieldData[]>([]);
export const manurePlans = atom<SubFieldManurePlan[]>([]);

export const cuts = atom((get) => {
  const onlyCuts = get(manurePlans).map((f) => f.harvestNumber);
  let unique = Array.from(new Set(onlyCuts));
  return unique;
});
