import {
  faEdit,
  faMap,
  faObjectGroup,
  faPlusSquare,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { faCut } from '@fortawesome/free-solid-svg-icons';
import { center, getCoord } from '@turf/turf';
import { getCropTypes, postSubfields } from 'adapters/cropfield-adapter';
import { Layout, message, notification } from 'antd';
import { atoms } from 'atoms';
import { Feature } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CropType } from 'types';
import { BaseMap } from './BaseMap/BaseMap';
import { DrawingTools } from './DrawingTools/DrawingTools';
import { mergePlots, splitPlot } from './helper';
import { EditControl } from './MapControls/EditControl';
import { SwitcherControl } from './MapControls/SwitcherControl';
import styles from './PlotEditor.module.scss';
import { PlotSidebar } from './PlotSidebar/PlotSidebar';
import { SplitModal } from './SplitModal/SplitModal';

export enum EditMode {
  None = 0,
  Drawing = 1,
  Editting = 2,
  Splitting = 3,
}

export const PlotViewer = () => {
  const { t } = useTranslation();
  const [plots, setPlots] = useAtom(atoms.plots);
  const selectedYear = useAtomValue(atoms.selectedYear);

  const [cropTypes, setCropTypes] = useState<CropType[]>([]);

  const [selectedPlots, setSelectedPlots] = useState<Array<number>>([]);
  const [mode, setMode] = useState<EditMode>(EditMode.None);
  const [mapRenderKey, setMapRenderKey] = useState<string>('');
  const [splitModalVisible, setSplitModalVisible] = useState<boolean>(false);
  const [drawnPlot, setDrawnPlot] = useState<Feature>();
  const [photoTiles, setPhotoTiles] = useState(false);
  const [mapPosition, setMapPosition] = useState<Array<number>>();

  useEffect(() => {
    getCropTypes({ year: selectedYear }).then((res) =>
      setCropTypes(res as unknown as CropType[]),
    );
  }, [selectedYear]);

  const addPlot = async (plot: Feature) => {
    const cropType = cropTypes.find(
      (cropType) => cropType.cropTypeCode === '265',
    );
    const newPlot = {
      ...plot,
      id: undefined,
      properties: {
        ...plot.properties,
        hectare: 478.618136626387,
        cropTypeId: cropType?.cropTypeId,
      },
    };
    const plotsToSave = [...plots, newPlot];
    try {
      const response = await postSubfields({ year: selectedYear }, plotsToSave);
      setPlots(response);
    } catch (e: any) {
      notification['error']({
        message: 'Er ging iets mis',
        description: e.response?.data?.Message,
      });
      console.error(e);
    }
  };

  const setCenterToPlot = (id: number) => {
    const plot = plots.find((x) => x.id === id);
    if (!plot) {
      console.log(`No plot found with id ${id}`);
      return;
    }

    const position = center(plot as Feature<any>);
    setMapPosition(getCoord(position).reverse());
  };

  const selectPlot = (id: number) => {
    if (!selectedPlots.some((x) => x === id)) {
      setCenterToPlot(id);
    }
    togglePlotSelect(id);
  };

  const togglePlotSelect = (id: number) => {
    if (mode !== EditMode.None) {
      return;
    }

    if (!plots.some((x) => x.id === id)) {
      console.log(`No plot found with id ${id}`);
      return;
    }

    setSelectedPlots((current) => {
      const selection = [...current];
      if (selection.some((x) => x === id)) {
        return selection.filter((x) => x !== id);
      }
      selection.push(id);
      return selection;
    });
  };

  const updatePlot = async (
    id: number,
    updateFn: (plot: Feature) => Feature,
  ) => {
    const currentPlots = [...plots];
    const index = currentPlots.findIndex((x) => x.id === id);
    if (index === -1) {
      throw new Error(`No plot found with id ${id}`);
    }
    currentPlots[index] = updateFn(currentPlots[index]);
    const plotsToSave = currentPlots;
    try {
      postSubfields({ year: selectedYear }, plotsToSave);
      setPlots(plotsToSave);
    } catch (e: any) {
      notification['error']({
        message: 'Er ging iets mis',
        description: e.response?.data?.Message,
      });
      console.error(e);
    }
  };

  const disableSelection = () => {
    setSelectedPlots([]);
  };

  const getSelectedPlots = () => {
    return plots.filter((x) => selectedPlots.some((y) => y === x.id));
  };

  const mergeSelection = async () => {
    if (selectedPlots.length < 2) {
      message.info('At least two plots should be selected.');
      return;
    }

    try {
      const plot = mergePlots(getSelectedPlots());
      const currentPlots = [...plots];
      const cropType = cropTypes.find(
        (cropType) => cropType.cropTypeCode === '265',
      );

      const newPlot = {
        ...plot,
        id: undefined,
        properties: {
          ...plot.properties,
          id: undefined,
          cropTypeId: cropType?.cropTypeId,
        },
      };

      currentPlots.push(newPlot);
      const plotsToSave = currentPlots.filter(
        (x) => !selectedPlots.some((y) => y === x.id),
      );
      const response = await postSubfields({ year: selectedYear }, plotsToSave);
      setPlots(response);
      disableSelection();
    } catch (e) {
      message.error('Could not merge selected plots.');
      console.log(e);
    }
  };

  const updatePlotName = (id: number, name: string) => {
    updatePlot(id, (plot) => {
      const newPlot = {
        ...plot,
        properties: { ...plot.properties, name: name },
      };
      return newPlot;
    });
  };

  const editPlot = (id: number) => {
    setSelectedPlots([id]);
    setMode(EditMode.Editting);
  };

  const deletePlot = async (id: number) => {
    const plotsToSave = [...plots.filter((x) => x.id !== id)];
    try {
      const response = await postSubfields({ year: selectedYear }, plotsToSave);
      setPlots(response);
      setSelectedPlots((current) => {
        return current.filter((x) => x !== id);
      });
    } catch (e: any) {
      notification['error']({
        message: 'Er ging iets mis',
        description: e.response?.data?.Message,
      });
      console.error(e);
    }
  };

  const handleDrawCreate = (plot: Feature) => {
    addPlot(plot);
    setMode(EditMode.None);
  };

  const enableSplit = () => {
    if (selectedPlots.length !== 1) {
      message.info('One plot should be selected.');
      return;
    }
    setMode(EditMode.Splitting);
  };

  const handleSplitModal = async (
    cancelled: boolean,
    repeatSplit: boolean,
    splitMeters: number | null,
  ) => {
    setSplitModalVisible(false);
    if (cancelled) return;
    try {
      const selectedPlot = getSelectedPlots()[0];
      if (!drawnPlot) {
        throw new Error('No drawnPlot defined.');
      }
      const cropType = cropTypes.find(
        (cropType) => cropType.cropTypeCode === '265',
      );
      const newPlots = splitPlot(
        selectedPlot,
        drawnPlot,
        repeatSplit ? splitMeters : null,
      );
      const idLessPlots = newPlots.map((plot) => {
        return {
          ...plot,
          id: undefined,
          properties: {
            ...plot.properties,
            id: undefined,
            cropTypeId: cropType?.cropTypeId,
          },
        };
      }) as Feature[];

      const plotsToSave: any = [...plots, ...idLessPlots].filter(
        (x) => x.id !== selectedPlot.id,
      );

      const response = await postSubfields({ year: selectedYear }, plotsToSave);
      setPlots(response);
      disableSelection();
    } catch (e) {
      message.error('Could not split plot. Please try again.');
      console.log(e);
    }
    setDrawnPlot(undefined);
  };

  const handleDrawSplit = (plot: Feature) => {
    setDrawnPlot(plot);
    setSplitModalVisible(true);
    setMode(EditMode.None);
  };

  const handleDrawEdit = (editedPlot: Feature) => {
    updatePlot(editedPlot.id as number, (originalPlot: Feature) => {
      const newPlot = { ...originalPlot, geometry: editedPlot.geometry };
      return newPlot;
    });
    setMode(EditMode.None);
    setMapRenderKey(new Date().toTimeString());
  };

  const handleDrawCancel = () => {
    setMode(EditMode.None);
    setMapRenderKey(new Date().toTimeString());
  };

  const deleteSelection = () => {
    setPlots((current) => {
      return current.filter((x) => !selectedPlots.some((y) => y === x.id));
    });
    disableSelection();
  };

  const renderModeTools = () => {
    if (mode === EditMode.None)
      return (
        <>
          <EditControl
            position="bottomleft"
            onClick={() => setMode(EditMode.Drawing)}
            icon={faPlusSquare}
            text={t('ploteditor.createPlot')}
          />
          <EditControl
            position="bottomleft"
            onClick={() => setMode(EditMode.Editting)}
            icon={faEdit}
            text={t('ploteditor.editPlot')}
            disabled={selectedPlots.length !== 1}
          />
          <EditControl
            position="bottomleft"
            onClick={enableSplit}
            icon={faCut}
            text={t('ploteditor.splitPlot')}
            disabled={selectedPlots.length !== 1}
          />
          <EditControl
            position="bottomleft"
            onClick={mergeSelection}
            icon={faObjectGroup}
            text={t('ploteditor.mergePlot')}
            disabled={selectedPlots.length < 2}
          />
          <EditControl
            position="bottomleft"
            onClick={deleteSelection}
            icon={faTrashAlt}
            text={t('ploteditor.deletePlot')}
            disabled={selectedPlots.length < 1}
            danger
          />
        </>
      );
    else
      return (
        <DrawingTools
          position="bottomleft"
          onCreate={handleDrawCreate}
          onSplit={handleDrawSplit}
          onEdit={handleDrawEdit}
          onCancel={handleDrawCancel}
          selectedPlots={selectedPlots}
          mode={mode}
        />
      );
  };
  return (
    <>
      <Layout className={styles.mainLayout}>
        <PlotSidebar
          onNameChange={updatePlotName}
          onSelect={selectPlot}
          onEdit={editPlot}
          onDelete={deletePlot}
          plots={plots}
          selectedPlots={selectedPlots}
        />
        <Layout.Content className={styles.contentLayout}>
          <SplitModal onSubmit={handleSplitModal} visible={splitModalVisible} />
          <BaseMap
            position={mapPosition}
            onPlotClick={togglePlotSelect}
            plots={plots}
            renderKey={mapRenderKey}
            selectedPlots={selectedPlots}
            photoTiles={photoTiles}
          >
            {renderModeTools()}
            <SwitcherControl
              activeIcon={faMap}
              inActiveIcon={faMap}
              position="topright"
              isActive={photoTiles}
              onChange={() => setPhotoTiles((current) => !current)}
            />
          </BaseMap>
        </Layout.Content>
      </Layout>
    </>
  );
};
