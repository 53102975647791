import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteAdvisorMandate,
  deleteDataMandate,
  getActiveAdvisorMandates,
  getActiveDataMandates,
  getAdvisorList,
  getOpenAdvisorMandates,
} from 'adapters/user-adapter';
import { message, Switch, Tooltip, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { CustomButton } from 'components';
import { Container } from 'components/grid';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvisorMandateDto, DataMandateDto } from 'types';
import { Advisor } from 'types/advisor';
import { AddDataClientModal } from './add-dataclient-modal';

// lijst van adviseurs(kvk, naam) moeten geselecteerd kunnen worden
// begindatum einddatum kunnen selecteren
// switch voor beheerrechten per adviseur
// of alleen in kunnen lezen
// in een tabel te zien, niet kunnen aanpassen wel verwijderen

export interface DataClientMandatesProps {}
export const DataClientMandates: FC<DataClientMandatesProps> = () => {
  const [showAddMandateModal, setShowAddMandateModal] = useState(false);
  const [activeDataClientMandates, setActiveDataClientMandates] = useState<
    DataMandateDto[]
  >([]);

  useEffect(() => {
    // Run at the start and each time the modal is closed.
    if (!showAddMandateModal) {
      updateDataClientMandates();
    }
  }, [showAddMandateModal]);

  const onDeleteDataClientMandate = async (dataMandateId) => {
    try {
      await deleteDataMandate(dataMandateId);
      updateDataClientMandates();
    } catch (e: any) {
      message.error(JSON.stringify(e));
    }
  };

  const updateDataClientMandates = async () => {
    (async () => {
      try {
        setActiveDataClientMandates(await getActiveDataMandates());
      } catch (e: any) {
        message.error(JSON.stringify(e));
      }
    })();
  };

  const { t } = useTranslation();
  const CompanyInfoInitColumns = [
    {
      id: 'dataClientName',
      render: (_, record) => record.dataClient.name,
    },
    {
      id: 'coc',
      render: (_, record) => record.dataClient.kvk,
    },
    {
      id: 'startDate',
      render: (_, record) => moment(record.activated.date).format('DD-MM-YYYY'),
    },
    {
      id: 'status',
      render: (_, record) => t(`advisorMandates.${record.status}`),
    },
  ];
  const companyColumns = CompanyInfoInitColumns.map((c) => ({
    title: t(`advisorMandates.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    render: (text, record) => {
      if (c.render) return c.render(text, record);
      return text;
    },
  })) as ColumnsType<DataMandateDto>;

  const actionColumns = [
    ...companyColumns.map((col) => {
      return { ...col };
    }),
    {
      title: 'Acties',
      key: 'Acties',
      render: (_: any, record: any) => {
        return (
          <Tooltip title="Verwijderen">
            <Typography.Link
              onClick={() => onDeleteDataClientMandate(record.dataMandateId)}
            >
              <FontAwesomeIcon icon="trash" />
            </Typography.Link>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Container>
      <AddDataClientModal
        isModalVisible={showAddMandateModal}
        closeModal={() => {
          setShowAddMandateModal(false);
        }}
      ></AddDataClientModal>
      <h1>Datakluis machtigingen</h1>
      <CustomButton
        color="primary"
        onClick={() => setShowAddMandateModal(true)}
      >
        Voeg een datakluis machtiging toe
      </CustomButton>
      <Table
        bordered
        dataSource={activeDataClientMandates}
        columns={actionColumns}
      />
    </Container>
  );
};
