import { SubFieldData, SubFieldManurePlan } from 'types';
import { ArtificialFertilizer } from 'types/artificialFertilizer';
import { FarmManurePlan } from 'types/farmManurePlan';
import { OrganicFertilizer } from 'types/organicFertilizer';
import { api } from 'utilities/api.axios';

type SubFieldParams = {
  year: number;
};

type FertilizerParams = {
  id: string;
};

type FarmManurePlanParams = {
  year: number;
};

export const getSubfieldData = (params: SubFieldParams) =>
  api
    .get<SubFieldData[]>(`manure/v1/SubFieldData`, { params })
    .then((r) => r.data);

export const postSubfieldData = (subfield: SubFieldData) =>
  api
    .post<SubFieldData>(`manure/v1/SubFieldData`, subfield)
    .then((r) => r.data);

export const getSubfieldManurePlan = (params: SubFieldParams) =>
  api
    .get<SubFieldManurePlan[]>(`manure/v1/SubFieldManurePlan`, { params })
    .then((r) => r.data);

export const postSubFieldManurePlan = (subfield: SubFieldManurePlan) =>
  api
    .post<SubFieldManurePlan>(`manure/v1/SubFieldManurePlan`, subfield)
    .then((r) => r.data);

export const deleteSubFieldManurePlan = (subFieldManurePlanId: string) =>
  api
    .delete<SubFieldManurePlan>(
      `manure/v1/SubFieldManurePlan?subFieldManurePlanId=${subFieldManurePlanId}`,
    )
    .then((r) => r.data);

export const getArtificialFertilizer = (params: SubFieldParams) =>
  api
    .get<ArtificialFertilizer[]>(`manure/v1/Fertilizer/artificial`, { params })
    .then((r) => r.data);

export const createArtificialFertilizer = (
  artificialFertilizer: ArtificialFertilizer,
) =>
  api
    .post<ArtificialFertilizer>(
      `manure/v1/Fertilizer/artificial`,
      artificialFertilizer,
    )
    .then((r) => r.data);

export const updateArtificialFertilizer = (
  artificialFertilizer: ArtificialFertilizer,
) =>
  api
    .patch<ArtificialFertilizer>(
      `manure/v1/Fertilizer/artificial`,
      artificialFertilizer,
    )
    .then((r) => r.data);

export const deleteArtificialFertilizer = (params: FertilizerParams) =>
  api
    .delete(`manure/v1/Fertilizer/artificial`, {
      params,
    })
    .then((r) => r.data);

export const getOrganicFertilizer = (params: SubFieldParams) =>
  api
    .get<OrganicFertilizer[]>(`manure/v1/Fertilizer/organic`, { params })
    .then((r) => r.data);

export const createOrganicFertilizer = (
  artificialFertilizer: OrganicFertilizer,
) =>
  api
    .post<OrganicFertilizer>(
      `manure/v1/Fertilizer/organic`,
      artificialFertilizer,
    )
    .then((r) => r.data);

export const updateOrganicFertilizer = (
  artificialFertilizer: OrganicFertilizer,
) =>
  api
    .patch<OrganicFertilizer>(
      `manure/v1/Fertilizer/organic`,
      artificialFertilizer,
    )
    .then((r) => r.data);

export const deleteOrganicFertilizer = (params: FertilizerParams) =>
  api
    .delete(`manure/v1/Fertilizer/organic`, {
      params,
    })
    .then((r) => r.data);

export const getFarmManurePlan = (params: FarmManurePlanParams) =>
  api.get(`manure/v1/FarmManurePlan`, { params }).then((r) => r.data);
export const postFarmManurePlan = (farmManurePlan: FarmManurePlan) =>
  api.post(`manure/v1/FarmManurePlan`, farmManurePlan).then((r) => r.data);
