import { InputNumber, InputNumberProps } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import React, { FC, useState } from 'react';
import { displayWithDecimals } from './displayWithDecimals';
import { roundToDecimals } from './roundToDecimals';
/**
 * Parse een nl-NL string nummer naar een string nummer zonder locale
 * @param {string} str input string
 * @returns nummer
 */
export const parseDisplayValue = (precision, typing) => (str) => {
  if (typing.isTyping) {
    return str;
  }
  if (str.endsWith('.') && str.length < 5) {
    //voor getallen nog kleiner dan 4 cijfers
    return str;
  } else if (str.endsWith('.')) {
    //voor grotere getallen waar al .-en tussen geplaatst zijn
    return str.replace(/\./g, '') + '.';
  }
  return str.replace(/\./g, '').replace(',', '.');
};

const normalizeLocaleNumberString = (precision) => (str) => {
  return str;
};

/**
 * Formateer een getal om weer te geven op het scherm
 * @param {*} str
 */
export const formatForDisplay = (precision, typing) => (str) => {
  if (typing.isTyping) {
    return str;
  }

  // Convert to normalized string number without locale
  const normalizedStringNumber = normalizeLocaleNumberString(precision)(str);

  // Parse to actual number
  const number = parseFloat(normalizedStringNumber);

  if (!isNaN(number)) {
    // actually format for display
    return displayWithDecimals(precision)(roundToDecimals(precision)(number));
  } else {
    return undefined;
  }
};

/**
 * Verwerk het resultaat van focus wisselingen
 * @param {*} event Focus event
 */
const handleFocus = (event) => {
  event.target.select();
};

/**
 * Creeer een Input number met NL formatting
 * @param {*} parms zelfde Input Parameters als Antd InputNumber, zonder onFocus, formatter en parser
 */
const InputNumberNlFormat = ({
  prefixCls,
  min,
  onClick,
  placeholder,
  max,
  step,
  precision,
  disabled,
  required,
  autoFocus,
  readOnly,
  name,
  id,
  value,
  defaultValue,
  onChange,
  style,
  pattern,
  className,
}) => {
  // Workarround using object references to get isTyping in the parser and formatter.
  // Might not be needed anymore if antdesign adds isTyping to the parser and formatter.
  const [typing] = useState({ isTyping: false });

  const onBlur = (e) => {
    typing.isTyping = false;
  };

  const onKeyPress = (e) => {
    typing.isTyping = true;
  };

  return (
    <InputNumber
      formatter={formatForDisplay(precision, typing)}
      parser={parseDisplayValue(precision, typing)}
      // Set focus handler
      onFocus={handleFocus}
      // Copy parameter
      prefixCls={prefixCls}
      onClick={onClick}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      autoFocus={autoFocus}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      step={step}
      precision={precision}
      readOnly={readOnly}
      name={name}
      id={id}
      value={value}
      defaultValue={defaultValue}
      style={style}
      pattern={pattern}
      className={className}
      decimalSeparator=","
    />
  );
};

export const CustomInputNumber = ({ precision = 0, ...props }) => {
  // Workarround using object references to get isTyping in the parser and formatter.
  // Might not be needed anymore if antdesign adds isTyping to the parser and formatter.
  const [typing] = useState({ isTyping: false });

  const onBlur = (e) => {
    typing.isTyping = false;
  };

  const onKeyPress = (e) => {
    typing.isTyping = true;
  };

  return (
    <InputNumber
      // Set the formats
      formatter={formatForDisplay(precision, typing)}
      parser={parseDisplayValue(precision, typing)}
      decimalSeparator=","
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      // onFocus={handleFocus}
      precision={precision}
      {...props}
    />
  );
};

export default InputNumberNlFormat;
