import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Activity } from '@vaadataworks/vaa-planboard';
import { atoms } from 'atoms';
import { useAtom } from 'jotai';
import { FC } from 'react';

export interface ActivityPopOverProps {
  activity: Activity<any>;
}
export const ActivityPopOver: FC<ActivityPopOverProps> = ({ activity }) => {
  const [schema] = useAtom(atoms.schema);
  const [artificialFertilizers] = useAtom(atoms.artificialFertilizers);
  const [organicFertilizers] = useAtom(atoms.organicFertilizers);
  const [silages] = useAtom(atoms.silages);
  return (
    <>
      <p>
        {activity.startDate.isSame(activity.endDate)
          ? activity.startDate.format('DD-MM-YYYY')
          : `${activity.startDate.format(
              'DD-MM-YYYY',
            )} - ${activity.endDate.format('DD-MM-YYYY')}`}
      </p>
      <p>
        {activity.properties &&
          Object.entries(activity.properties).map(([key, value]) => {
            const schemaObj = schema[activity.activityType]?.properties?.[key];
            if (typeof value === 'undefined' || value === null) return null;
            if (typeof value === 'object') return <p>{schemaObj?.title}: </p>;
            if (typeof value === 'boolean')
              return popoverRow(
                schemaObj?.title,
                value ? (
                  <FontAwesomeIcon icon="check" />
                ) : (
                  <FontAwesomeIcon icon="times" />
                ),
              );
            if (key === 'animalType') {
              const index = schemaObj.enum.findIndex((x) => x === value);
              if (index !== -1 && schemaObj.enumNames[index]) {
                return popoverRow(schemaObj?.title, schemaObj.enumNames[index]);
              }
            }
            if (!key.includes('plot')) {
              if (key === 'harvestNumber') {
                return popoverRow('Snede', value);
              }
              if (key === 'silageSampleId') {
                return popoverRow(
                  'Kuilmonster',
                  silages.find((f) => f.id === value)?.name,
                );
              }
              if (key === 'fertilizerTypeId') {
                const organic = organicFertilizers.find(
                  (fert) => fert.id === value,
                );
                const artificial = artificialFertilizers.find(
                  (fert) => fert.id === value,
                );
                if (organic)
                  return popoverRow(schemaObj?.title, organic.fertilizerType);
                if (artificial)
                  return popoverRow(
                    schemaObj?.title,
                    artificial.fertilizerType,
                  );
                return <></>;
              }
              return popoverRow(schemaObj?.title, value);
            }
            return <></>;
          })}
      </p>
    </>
  );
};

const popoverRow = (title, description) => (
  <>
    <p>
      {title}: {description}
    </p>
  </>
);
