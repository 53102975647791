import {
  getSubfieldManurePlan,
  postSubFieldManurePlan,
} from 'adapters/manure-adapter';
import { Button, Form, Input, InputNumber, notification, Select } from 'antd';
import { atoms } from 'atoms';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubFieldManurePlan } from 'types';
import { defaultSorter } from 'utilities/default-sorter';
import { CustomInputNumber } from 'utilities/InputNumberNlFormat';
import styles from './styles.module.scss';

export interface FertilizationPlanProps {
  onSubmit: () => any;
  fertilization?: SubFieldManurePlan;
}
export const FertilizationPlan: FC<FertilizationPlanProps> = ({
  onSubmit,
  fertilization,
}) => {
  const { t } = useTranslation();
  const plots = useAtomValue(atoms.plots);
  const selectedYear = useAtomValue(atoms.selectedYear);
  const organics = useAtomValue(atoms.organicFertilizers);
  const artificials = useAtomValue(atoms.artificialFertilizers);
  const setFertilizations = useUpdateAtom(atoms.manurePlans);
  const [form] = Form.useForm();
  const onfinish = (values) => {
    if (fertilization) {
      editFertilization(values);
    } else {
      addFertilization(values);
    }
  };
  const resetForm = () => {
    form.resetFields();
  };
  useEffect(() => {
    if (!fertilization) {
      return;
    }
    form.setFieldsValue({
      ...fertilization,
      plots: [fertilization.subFieldId],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFertilizations = async () => {
    try {
      const tmpFertilizations = await getSubfieldManurePlan({
        year: selectedYear,
      });
      setFertilizations(tmpFertilizations);
    } catch (err) {
      console.error(err);
      notification['error']({
        message: 'Er ging iets mis',
      });
    }
  };

  const addFertilization = async (values: any) => {
    try {
      const newPlans = values.plots.map(async (plot) => {
        return postSubFieldManurePlan({
          artificialFertilizerId: values.artificialFertilizerId,
          organicFertilizerId: values.organicFertilizerId,
          amountOfManure: values.amountOfManure,
          amountOfFertilizer: values.amountOfFertilizer,
          harvestNumber: values.harvestNumber,
          usage: values.usage,
          other: values.other,
          subFieldId: plot,
          usageCapacityPhosphate: 0,
        } as SubFieldManurePlan);
      });
      const plans: SubFieldManurePlan[] = await Promise.all(newPlans);
      setFertilizations((current) => [...current, ...plans]);
    } catch (err) {
      console.error(err);
      notification['error']({
        message: 'Er ging iets mis',
      });
    }

    resetForm();
    onSubmit();
    getFertilizations();
  };

  const editFertilization = async (values: any) => {
    try {
      (values.plots as string[]).map(async (plot, index) => {
        const response = await postSubFieldManurePlan({
          explanation: values.explanation,
          artificialFertilizerId: values.artificialFertilizerId,
          organicFertilizerId: values.organicFertilizerId,
          subFieldManurePlanId:
            index === 0 ? fertilization?.subFieldManurePlanId : undefined,
          amountOfManure: values.amountOfManure,
          amountOfFertilizer: values.amountOfFertilizer,
          harvestNumber: values.harvestNumber,
          usage: values.usage,
          other: values.other,
          subFieldId: plot,
          usageCapacityPhosphate: 0,
        });
        setFertilizations((current) => {
          const plans = current.filter(
            (fert) =>
              fert.subFieldManurePlanId !== response?.subFieldManurePlanId,
          );
          return [...plans, response];
        });
      });
    } catch (err) {
      console.error(err);
      notification['error']({
        message: 'Er ging iets mis',
      });
    }

    resetForm();
    onSubmit();
    getFertilizations();
  };

  return (
    <Form layout={'vertical'} colon={false} form={form} onFinish={onfinish}>
      {!fertilization ? (
        <h1>{t('fertilizationplan.title')}</h1>
      ) : (
        <h1>{t('fertilizationplan.edit')}</h1>
      )}
      <Form.Item name="plots" required label={t('fertilizationplan.plot')}>
        <Select mode="multiple" className={styles.field}>
          {plots
            .sort(defaultSorter((x) => x.properties?.name))
            .map((option, i) => (
              <Select.Option
                key={`${i} ${option.id}`}
                value={option.id as string}
              >
                {option.properties?.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item hidden name="key">
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item
        name="harvestNumber"
        required
        label={t('fertilizationplan.cut')}
      >
        <InputNumber min={1} className={styles.field} />
      </Form.Item>
      <Form.Item name="usage" label={t('fertilizationplan.usage')}>
        <Select className={styles.field}>
          <Select.Option key="1" value="grazing">
            Weiden
          </Select.Option>
          <Select.Option key="2" value="mow">
            Maaien
          </Select.Option>
          <Select.Option key="3" value="stableFeeding">
            Stalvoeren
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Organische mestsoort" name={['organicFertilizerId']}>
        <Select style={{ width: '100%' }}>
          {organics.map((fertilizer) => (
            <Select.Option key={fertilizer.id} value={fertilizer.id}>
              {fertilizer.fertilizerType}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="amountOfManure" label={t('fertilizationplan.organicM3')}>
        <CustomInputNumber min={0} className={styles.field} />
      </Form.Item>
      <Form.Item label="Kunstmestsoort" name={['artificialFertilizerId']}>
        <Select style={{ width: '100%' }}>
          {artificials.map((fertilizer) => (
            <Select.Option key={fertilizer.id} value={fertilizer.id}>
              {fertilizer.fertilizerType}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="amountOfFertilizer"
        label={t('fertilizationplan.artificialKg')}
      >
        <CustomInputNumber min={0} className={styles.field} />
      </Form.Item>
      <Form.Item name="other" hidden label={t('fertilizationplan.other')}>
        <Input className={styles.field} />
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button htmlType="submit" type="primary">
          {t('fertilizationplan.submit')}
        </Button>
      </div>
    </Form>
  );
};
