import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from 'antd';
import React from 'react';
import Control from 'react-leaflet-control';

export type SwitcherControlProps = {
  position: 'topleft' | 'topright' | 'bottomright' | 'bottomleft';
  onChange: (isActive: boolean) => void;
  isActive: boolean;
  activeIcon: IconProp;
  inActiveIcon: IconProp;
};

export const SwitcherControl = ({
  position,
  onChange,
  isActive,
  activeIcon,
  inActiveIcon
}: SwitcherControlProps) => {
  const handleChecked = () => {
    onChange(!isActive);
  };
  return (
    <Control position={position}>
      <Switch
        checkedChildren={<FontAwesomeIcon icon={activeIcon} />}
        unCheckedChildren={<FontAwesomeIcon icon={inActiveIcon} />}
        defaultChecked
        checked={isActive}
        onChange={handleChecked}
      />
    </Control>
  );
};
