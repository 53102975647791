import area from '@turf/area';
import { displayWithDecimals } from './displayWithDecimals';
import { roundToDecimals } from './roundToDecimals';

export const geometryToArea = (geometry) => area(geometry as any);

export const areaToHecatare = (areaValue) =>
  !isNaN(areaValue)
    ? displayWithDecimals(2)(roundToDecimals(2)(areaValue * 0.0001))
    : 0;
