import { Spin } from 'antd';
import { atoms } from 'atoms';
import * as H from 'history';
import { useAtomValue } from 'jotai/utils';
import { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export const Login: FC<any> = () => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const location = useLocation<{ from?: H.Location }>();
  const redirectPath = location.state.from?.pathname || '/';
  const redirectUri =
    window.location.href.replace(window.location.pathname, '') + redirectPath;

  if (keycloak.authenticated) {
    return (
      <Redirect
        to={{
          pathname: redirectPath,
        }}
      />
    );
  } else {
    keycloak.login({ redirectUri });

    return <Spin />;
  }
};
