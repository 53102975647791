import { Empty, Layout, List } from 'antd';
import { Feature } from 'geojson';
import React from 'react';
import styles from './PlotSidebar.module.scss';
import { SidebarItem } from './SidebarItem';
const { Sider } = Layout;

type PlotSidebarProps = {
  plots: Array<Feature>;
  selectedPlots: Array<number>;
  onNameChange: (id: number, name: string) => void;
  onSelect?: (id: number) => void;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
};

export const PlotSidebar = ({
  plots,
  selectedPlots,
  onNameChange,
  onSelect,
  onEdit,
  onDelete,
}: PlotSidebarProps) => {
  const sideBarItems = plots.length ? (
    plots.map((x) => (
      <SidebarItem
        key={x.id as number}
        plot={x}
        onNameChange={onNameChange}
        onSelect={onSelect}
        onEdit={onEdit}
        onDelete={onDelete}
        selected={selectedPlots.some((id) => id === x.id)}
      />
    ))
  ) : (
    <Empty />
  );

  return (
    <Sider
      breakpoint="xxl"
      theme="light"
      className={styles.sidebar}
      defaultCollapsed={true}
      zeroWidthTriggerStyle={{
        zIndex: 999,
        // top: '10px'
      }}
      collapsedWidth="0"
      width="300"
    >
      <div className={styles.sidebarContent}>
        <List itemLayout="vertical">{sideBarItems}</List>
      </div>
    </Sider>
  );
};
