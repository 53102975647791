import {
  createAdvisorMandate,
  getActiveAdvisorMandates,
  getAdvisorList,
} from 'adapters/user-adapter';
import { Empty, Form, Input, message, notification, Select, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CustomButton } from 'components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { FC } from 'react';
import { AdvisorMandateDto } from 'types';
import { Advisor } from 'types/advisor';

export const AddMandateModal: FC<{
  isModalVisible: boolean;
  closeModal: () => void;
}> = ({ isModalVisible, closeModal }) => {
  const [form] = Form.useForm();
  const [advisorList, setAdvisorList] = useState<Advisor[]>([]);

  const years = useMemo(() => {
    const currentYear = moment().year();
    const startYear = 2019;
    const diff = currentYear - startYear;
    return Array(diff + 1)
      .fill(startYear)
      .map((value, index) => value + index);
  }, []);

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  useEffect(() => {
    if (isModalVisible) {
      (async () => {
        try {
          const activeAdvisorMandates = await getActiveAdvisorMandates();
          const advisorList = await getAdvisorList();
          const mandatelessAdvisors = advisorList.filter(
            (advisor) =>
              !activeAdvisorMandates.find(
                (mandate) => mandate.advisor.kvk === advisor.kvk,
              ),
          );
          setAdvisorList(mandatelessAdvisors);
        } catch (e: any) {
          message.error(JSON.stringify(e));
          console.error(e);
        }
      })();
    }
  }, [isModalVisible]);

  const resetForm = () => {
    form.resetFields();
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await createAdvisorMandate(values);
      message.success('Adviseur machtiging succesvol toegevoegd.');
      handleClose();
    } catch (e: any) {
      notification['error']({
        message: 'Er ging iets mis',
        description: e.response?.data?.Message,
      });
      console.error(e);
    }
  };

  return (
    <Modal
      footer={
        <Space>
          <CustomButton onClick={handleClose}>Annuleren</CustomButton>
          <CustomButton color="primary" onClick={handleSave}>
            Toevoegen
          </CustomButton>
        </Space>
      }
      onCancel={handleClose}
      visible={isModalVisible}
      title="Voeg een adviseur machtiging toe"
    >
      <Form form={form} layout="vertical">
        <Form.Item name="otherPartyKvk" label="KVK" required>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>
        <Form.Item name="yearStart" label="Start jaar">
          <Select allowClear>
            {years.map((year) => (
              <Select.Option value={year}>{year}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="yearEnd" label="Eind jaar">
          <Select allowClear>
            {years.map((year) => (
              <Select.Option value={year}>{year}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="mandateType"
          label="Machtiging voor alleen lezen of ook voor aanpassen?"
          required
        >
          <Select>
            <Select.Option value="read">Alleen lezen</Select.Option>
            <Select.Option value="write">Lezen en aanpassen</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
