import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Col, Drawer, Menu, Row, Space } from 'antd';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { SelectYear } from './SelectYear';
import styles from './styles.module.scss';

type MobileHeaderProps = {
  icon?: IconProp;
  title?: string;
  menuItems: ReactNode;
};

export const MobileHeader: FC<MobileHeaderProps> = ({ menuItems }) => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const [impersonatedRelation, setImpersonatedRelation] = useAtom(
    atoms.impersonatedRelation,
  );
  const setUser = useUpdateAtom(atoms.user);

  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const signOut = () => {
    keycloak.logout();
    setImpersonatedRelation(null);
    setUser(null);
  };

  const stopImpersonation = () => {
    setImpersonatedRelation(null);
    history.push('/');
  };

  return (
    <div>
      <div style={{ height: 45 }} />
      <div className={styles.header}>
        <Row
          justify="space-between"
          align="middle"
          gutter={20}
          style={{ marginTop: '6px', marginBottom: '6px' }}
          className={styles.row}
        >
          <Col span={12}>
            <Row align="middle" gutter={20}>
              <Col>
                <Button type="ghost" onClick={() => setVisible(true)}>
                  <FontAwesomeIcon icon="bars" />
                </Button>
              </Col>
              <Col
                className={styles.titleMobile}
                onClick={() => {
                  const searchParams = new URLSearchParams(
                    window.location.search,
                  );
                  history.push({
                    pathname: '/',
                    search: '?' + searchParams.toString(),
                  });
                }}
              >
                <Avatar size={24} src="logo.svg" /> DGGK
              </Col>
              <Col></Col>
            </Row>
          </Col>
          <Col span={12} className={styles.textRight}>
            {!impersonatedRelation ? (
              <Space direction="horizontal" size="small">
                <SelectYear />
                <CustomButton
                  color="secondary"
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    const searchParams = new URLSearchParams(
                      window.location.search,
                    );
                    history.push({
                      pathname: '/settings',
                      search: '?' + searchParams.toString(),
                    });
                  }}
                  icon={<FontAwesomeIcon icon="cog" />}
                />
                <CustomButton
                  color="primary"
                  type="primary"
                  shape="circle"
                  onClick={signOut}
                  icon={<FontAwesomeIcon icon="sign-out-alt" />}
                />
              </Space>
            ) : (
              <Space direction="horizontal" size="middle">
                Jaar:
                <SelectYear />
                <CustomButton
                  color="secondary"
                  type="primary"
                  shape="circle"
                  icon={<FontAwesomeIcon icon="sign-out-alt" />}
                  onClick={stopImpersonation}
                  title="Sluit bedrijf"
                ></CustomButton>
              </Space>
            )}
          </Col>
        </Row>

        <Drawer
          title="Menu"
          placement="left"
          closable={true}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Menu mode="vertical">{menuItems}</Menu>
        </Drawer>
      </div>
    </div>
  );
};
