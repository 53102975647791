import { Tag } from 'antd';
import { FeatureViewer } from 'components';
import { Feature } from 'geojson';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';
import styles from './styles.module.scss';
import area from '@turf/area';

export interface RvoCardProps {
  checkSelected: (id: string) => boolean;
  toggleSelected: (id: string) => void;
  plot: Feature;
  getPlotName?: (plot: any) => string;
  getPlotId?: (plot: any) => string;
}
export const RvoCard: FC<RvoCardProps> = ({
  checkSelected,
  toggleSelected,
  plot,
  getPlotName = (plot) => plot.properties.name,
  getPlotId = (plot) => plot?.properties?.id,
}) => {
  const { t } = useTranslation();

  const plotAreaInHectare = displayWithDecimals(2)(
    roundToDecimals(2)(area(plot.geometry as any) * 0.0001),
  );

  return (
    plot && (
      <div
        style={{ cursor: 'pointer', userSelect: 'none' }}
        onClick={() => toggleSelected(getPlotId(plot))}
      >
        <div className={styles.overviewPlot}>
          <div
            style={{
              width: '100px',
              display: 'inline-block',
              float: 'left',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: '5px',
                top: '5px',
                zIndex: 800,
                height: 22,
                WebkitBoxShadow: 'rgba(0, 0, 0, 0.5) 0px 4px 15px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}
            >
              {checkSelected(getPlotId(plot)) && (
                <Tag
                  style={{ marginRight: 0, transform: 'translateY(-2px)' }}
                  color="blue"
                >
                  {t('ploteditor.selected')}
                </Tag>
              )}
            </div>
            <FeatureViewer
              mapStyle={{
                height: '80px',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
              }}
              feature={plot}
            />
          </div>
          <div
            style={{
              backgroundColor: 'white',
              width: 'calc(100% - 100px)',
              display: 'inline-block',
              height: '100%',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
              padding: '12px',
              float: 'right',
              fontSize: '12px',
              color: 'black',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <b>{getPlotName(plot)}</b>
            </div>
            <div>{plotAreaInHectare} ha</div>
            <div>{plot.properties?.cropTypeDescription}</div>
          </div>
        </div>
      </div>
    )
  );
};
