import { Activity } from '@vaadataworks/vaa-planboard';
import { addSubfieldToSilage, getSilages } from 'adapters/monster-adapter';
import { getActivitySchema } from 'adapters/planning-adapter';
import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import { atoms } from 'atoms';
import { FeatureSelectModal } from 'components/feature-select-modal';
import { FertilizerActivityModal } from 'components/fertilizer-activity-modal';
import { Feature } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import moment from 'moment';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Silage } from 'types/monster';
import { defaultSorter } from 'utilities/default-sorter';
import { SchemaForm } from './SchemaForm';
import styles from './styles.module.scss';

export type ActivityModalProps = {
  modifyActivity?: Activity<any> | null;
  visible: boolean;
  onSubmit: (newActivity?: Activity<any>) => any;
  handleActivityDelete?: (activityId: string | number) => void;
  plots: Feature[];
  defaultPlotIds?: string[];
};

const dateFormat = 'DD-MM-YYYY';

export const ActivityModal = ({
  modifyActivity,
  visible,
  onSubmit,
  handleActivityDelete = () => {},
  plots,
  defaultPlotIds,
}: ActivityModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [type, setType] = useState<string>();
  const [fields, setFields] = useState<string[]>();
  const [harvestNumber, setHarvestNumber] = useState<number>();

  const [silages, setSilages] = useAtom(atoms.silages);
  const [cuts] = useAtom(atoms.cuts).sort();

  const [schemaArray, setSchemaArray] = useState<
    { title: string; value: string }[]
  >([]);
  const selectedYear = useAtomValue(atoms.selectedYear);
  const forceUpdate = useReducer(() => ({}), {})[1] as () => void;

  useEffect(() => {
    getActivitySchema({ year: selectedYear }).then((r) => {
      let schemaKeys: { title: string; value: string }[] = [];
      Object.entries(r).forEach(([key, value]) => {
        if ((value as any).title)
          schemaKeys.push({ title: (value as any).title, value: key });
        else schemaKeys.push({ title: key, value: key });
      });
      setSchemaArray(schemaKeys);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    //TODO: rewrite editing activities to use json schemas
    if (!modifyActivity || !visible) {
      return;
    }
    const fieldsValue = {
      id: modifyActivity.id,
      field: [modifyActivity.resourceId],
      type: modifyActivity.activityType,
      start: modifyActivity.startDate,
      end: modifyActivity.endDate,
    };
    if (modifyActivity.properties) {
      Object.entries(modifyActivity.properties).forEach(([key, value]) => {
        fieldsValue[key] = value;
      });
    }
    setType(modifyActivity.activityType);

    form.setFieldsValue(fieldsValue);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyActivity, visible]);

  useEffect(() => {
    if (defaultPlotIds) {
      form.setFieldsValue({ field: defaultPlotIds });
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlotIds, form]);

  const resetForm = useCallback(() => {
    form.resetFields();
    setType('');
  }, [form]);

  const handleDelete = async () => {
    if (!modifyActivity?.id) return;
    resetForm();
    handleActivityDelete(modifyActivity.id);
  };

  const handleOk = useCallback(
    async (props) => {
      try {
        const values = await form.validateFields();
        values.field.forEach((plotId, index) => {
          const activity: Activity<any> = {
            id: index === 0 ? values.id : undefined,
            activityType: values.type,
            startDate: values.start,
            endDate: values.end,
            properties: {
              ...props,
              plot: plotId,
              harvestNumber: values.harvestNumber,
              plotName: plots.find((plot) => plot.id === plotId)?.properties
                ?.name,
            },
            resourceId: plotId,
          };
          if (props.silageSampleId)
            addSubfieldToSilage({
              silage: silages.find(
                (f) => f.id === props.silageSampleId,
              ) as Silage,
              subfieldId: plotId,
            }).then((silages) => setSilages(silages));

          onSubmit(activity);
        });
        resetForm();
      } catch (e) {
        console.log(e);
      }
    },
    [form, onSubmit, plots, resetForm],
  );

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const handleCancel = useCallback(() => {
    resetForm();
    onSubmit();
  }, [resetForm, onSubmit]);

  const handleSubform = useCallback(
    (form) => {
      const values = form.formData;
      handleOk(values);
    },
    [handleOk],
  );

  const onFormValueChange = (changedValues: any, values: any) => {
    if (changedValues.start) {
      form.setFieldsValue({ end: changedValues.start });
    }
    if (changedValues.type) {
      setType(changedValues.type);
    }
    if (changedValues.field) {
      setFields(changedValues.field);
    }
    if (changedValues.harvestNumber) {
      setHarvestNumber(changedValues.harvestNumber);
    }
    forceUpdate();
  };

  return (
    <Modal
      title="Activiteit"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        !type ? (
          <Button onClick={handleCancel}>{t('activitymodal.cancel')}</Button>
        ) : (
          false
        )
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="activityForm"
        onValuesChange={onFormValueChange}
        initialValues={{
          start: moment().set('year', selectedYear),
          end: moment().set('year', selectedYear),
        }}
      >
        <Form.Item
          fieldKey="id"
          label={t('activitymodal.zone')}
          name="field"
          rules={[{ required: true }]}
          className={styles.gebruikspercelen}
        >
          <Select mode={'multiple'}>
            {plots
              .sort(defaultSorter((x) => x.properties?.name))
              .map((plot) => (
                <Select.Option value={plot.id as string}>
                  {plot.properties?.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <FeatureSelectModal
          features={plots}
          selected={form.getFieldValue('field')}
          onChangeSelected={(selected) => {
            form.setFieldsValue({ field: selected });
            forceUpdate();
          }}
        />

        <Form.Item
          label={t('activitymodal.beginDate')}
          name="start"
          rules={[{ required: true }]}
        >
          <DatePicker
            format={dateFormat}
            disabledDate={(currentDate) =>
              currentDate <
                moment().set('year', selectedYear).startOf('year') ||
              currentDate > moment().set('year', selectedYear).endOf('year')
            }
          />
        </Form.Item>
        <Form.Item
          label={t('activitymodal.endDate')}
          name="end"
          rules={[{ required: true }]}
        >
          <DatePicker
            format={dateFormat}
            disabledDate={(currentDate) =>
              currentDate < form.getFieldValue('start') ||
              currentDate > moment().set('year', selectedYear).endOf('year')
            }
          />
        </Form.Item>
        <Form.Item label="Snede" name="harvestNumber">
          <Select allowClear>
            {cuts.map((cut) => (
              <Select.Option key={cut} value={cut}>
                {cut}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('activitymodal.type')}
          name="type"
          rules={[{ required: true }]}
        >
          <Select virtual={false}>
            {schemaArray.map((x) => (
              <Select.Option value={x.value}>{x.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
      </Form>

      {type === 'manure' ||
      type === 'fertalize' ||
      type === 'mow' ||
      type === 'mowClean' ||
      type === 'stableFeeding' ? (
        <FertilizerActivityModal
          type={type}
          fields={fields}
          harvestNumber={harvestNumber}
          submit={handleOk}
          cancel={handleCancel}
          handleDelete={handleDelete}
          modifyActivity={modifyActivity}
        />
      ) : (
        <SchemaForm
          cancel={handleCancel}
          handleDelete={handleDelete}
          activity={modifyActivity}
          submit={handleSubform}
          type={type}
        />
      )}
    </Modal>
  );
};
