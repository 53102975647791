import { getLabData, postLabData } from 'adapters/user-adapter';
import { Button, Form, Input, notification, Select } from 'antd';
import { atoms } from 'atoms';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const LabBerichten = () => {
  const [user] = useAtom(atoms.user);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const save = (values) => {
    postLabData(values)
      .then(() =>
        notification['success']({
          message: 'Uw lab gegevens zijn opgeslagen',
        }),
      )
      .catch(() =>
        notification['error']({
          message: 'Lab gegevens konden niet worden opgeslagen',
        }),
      );
  };

  useEffect(() => {
    getLabData().then((data) => {
      form.setFieldsValue(data[0]);
    });
  }, []);

  return (
    user && (
      <Form onFinish={save} form={form}>
        <div style={{ marginBottom: 24 }}>
          <div style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.45)' }}>Lab</div>
          <Form.Item name="labCode">
            <Select
              disabled={form.getFieldValue(['isLinked'])}
              style={{ width: '200px' }}
            >
              <Select.Option value="DUMEA_01">Dumea</Select.Option>
              <Select.Option value="EUROFINS-AGRO">Eurofins</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div style={{ marginBottom: 24 }}>
          <div style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.45)' }}>
            Klantcode
          </div>
          <Form.Item name="customerCode">
            <Input
              disabled={form.getFieldValue(['isLinked'])}
              style={{ width: '200px' }}
            />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 24 }}>
          <Button htmlType="submit" shape="round" type="primary">
            Opslaan
          </Button>
        </div>
        <div style={{ marginBottom: 24 }}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) => prev.labCode !== cur.labCode}
          >
            {({ getFieldValue }) => {
              const labCode = getFieldValue('labCode');
              return (
                labCode && (
                  <>
                    {labCode === 'DUMEA_01' ? (
                      <>
                        <p>
                          Dumea Agro kan kopieën van uw analyseresultaten
                          (actueel en historisch) doorgeven aan de Digitale
                          Grasland GebruiksKalender, zodat u ze hier kunt
                          koppelen aan percelen of kuilen. Daarvoor hebben zij
                          wel uw toestemming nodig. Dat kunt u aangeven door
                          onderstaand formulier te downloaden, in te vullen en
                          toe te sturen aan:
                        </p>
                        <b> DUMEA AGRO</b>
                        <div> Bornsestraat 24</div>
                        <div> 7597 NE Saasveld</div>
                        <div style={{ marginBottom: 10 }}>
                          Of via mail;{' '}
                          <a href="mailto:info@dumea-am.nl">info@dumea-am.nl</a>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          Eurofins Agro kan kopieverslagen en eventueel
                          historische gegevens doorgeven aan de Digitale
                          Grasland GebruiksKalender, zodat u ze hier kunt
                          koppelen aan percelen of kuilen. Daarvoor hebben zij
                          wel uw toestemming nodig. Dat kunt u aangeven door
                          onderstaand formulier te downloaden, in te vullen en
                          toe te sturen aan:
                        </p>
                        <b>Eurofins Agro</b>
                        <div> Postbus 170</div>
                        <div> 6700 AD Wageningen</div>
                        <div style={{ marginBottom: 10 }}>
                          Of via mail;{' '}
                          <a href="mailto:klantenservice.agro@eurofins.com">
                            klantenservice.agro@eurofins.com
                          </a>
                        </div>
                      </>
                    )}
                    <Button
                      href={
                        labCode === 'DUMEA_01'
                          ? 'formBoerData.pdf'
                          : 'formEurofins.pdf'
                      }
                      target="_blank"
                      shape="round"
                      type="primary"
                    >
                      Download machtigingen formulier
                    </Button>
                  </>
                )
              );
            }}
          </Form.Item>
        </div>
      </Form>
    )
  );
};
