import bbox from '@turf/bbox';
import { Feature } from 'geojson';
import 'leaflet/dist/leaflet.css';
import leaflet from 'leaflet';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FeatureGroup,
  GeoJSON,
  Map,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
} from 'react-leaflet';
import { turfToLeafletLatLngBounds } from 'utilities/leaflet-utils';
import styles from './BaseMap.module.scss';

const defaultPadding: leaflet.PointTuple = [5, 5];

export type BaseMapProps = {
  plots: Array<Feature>;
  selectedPlots: Array<number>;
  renderKey: string;
  position: Array<number> | undefined;
  onPlotClick: (id: number) => void;
  photoTiles?: boolean;
};

export const BaseMap: FunctionComponent<BaseMapProps> = ({
  onPlotClick,
  plots,
  renderKey,
  selectedPlots,
  position,
  children,
  photoTiles,
}) => {
  const map = useRef<Map>();

  const zoomToBounds = useCallback((plots) => {
    if (plots && plots.length && map.current?.leafletElement) {
      const geojson = {
        type: 'FeatureCollection',
        features: plots,
      };
      const leafletElement = map.current.leafletElement;

      setTimeout(() => {
        leafletElement.fitBounds(turfToLeafletLatLngBounds(bbox(geojson)), {
          padding: defaultPadding,
        });
        leafletElement.invalidateSize();
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (position === undefined) {
      zoomToBounds(plots);
    }
  }, []);

  useEffect(() => {
    if (position) {
      map.current.leafletElement.panTo(position);
    }
  }, [zoomToBounds, position]);

  const plotLayers = plots.map((plot: Feature) => {
    return (
      <GeoJSON
        onClick={() => onPlotClick(plot.id as number)}
        key={plot.id as number}
        data={plot}
        style={() =>
          selectedPlots.find((x) => x === plot.id)
            ? { color: 'blue' }
            : { color: 'grey' }
        }
      />
    );
  });

  return (
    <Map
      ref={map}
      center={[51.7190057, 5.347299]}
      zoom={18}
      className={styles.map}
      zoomControl={false}
      maxZoom={19}
      whenReady={() => {
        map.current.leafletElement.invalidateSize();
      }}
    >
      {photoTiles ? (
        <WMSTileLayer
          url="https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?"
          layers="Actueel_ortho25"
        />
      ) : (
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      )}

      {children}
      <FeatureGroup key={renderKey}>{plotLayers}</FeatureGroup>
      <ZoomControl position="bottomright" />
    </Map>
  );
};
