import axios, { AxiosError, AxiosRequestConfig } from 'axios';

// @ts-ignore
const API_URL = window.API_URL;

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  timeout: 10000,
};

export const api = axios.create(axiosConfig);

// Add a request interceptor
// axios.interceptors.request.use(
//   (config) => {
//     // Do something before request is sent
//     return config;
//   },
//   (error) => {
//     // Do something with request error
//     console.error('Axios interceptor catch request error', error);
//     return Promise.reject(error);
//   },
// );

// api.interceptors.response.use(
//   (response) => response,
//   (error: AxiosError) => {
//     console.log(error.response);
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // TODO: afhandelen als statuscode 401
//     // Do something with response error
//     console.error(
//       'Axios interceptor catch response error',
//       JSON.stringify(error),
//     );
//     return Promise.reject(error);
//   },
// );
