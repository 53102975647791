import { ActivityDto } from 'types/activitydto';
import { api } from 'utilities/api.axios';

type PlanningParams = {
  year: number;
};
export const getActivities = (params: PlanningParams) =>
  api
    .get<ActivityDto[]>(`planning/v1/Activity`, { params })
    .then((r) => r.data);

export const postActivity = (activity: ActivityDto) =>
  api.post<ActivityDto>(`planning/v1/Activity`, activity).then((r) => r.data);

export const deleteActivity = (id: string | number) =>
  api.delete<ActivityDto>(`planning/v1/Activity/${id}`).then((r) => r.data);

export const getActivitySchema = (params: PlanningParams) =>
  api.get<any>(`planning/v1/ActivitySchema/${params.year}`).then((r) => r.data);

export const getYearlyOverview = (params: PlanningParams) =>
  api.get<any>(`planning/v1/AnnualReview`, { params }).then((r) => r.data);
