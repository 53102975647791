import { FC } from 'react';

import styles from './grid.module.scss';

export const Container: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
};
