import { Select } from 'antd';
import { atoms } from 'atoms';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';

const { Option } = Select;

export const SelectYear = () => {
  const [selectedYear, setSelectedYear] = useAtom(atoms.selectedYear);

  const currentYear = dayjs().year();
  const nextYear = currentYear + 1;
  const startYear = 2019;
  const diff = nextYear - startYear;
  const years = Array(diff + 1)
    .fill(startYear)
    .map((value, index) => value + index);

  return (
    <Select value={selectedYear} onChange={setSelectedYear}>
      {years.map((year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      ))}
    </Select>
  );
};
