import { getCropTypes, getSubfields } from 'adapters/cropfield-adapter';
import {
  getArtificialFertilizer,
  getOrganicFertilizer,
  getSubfieldData,
  getSubfieldManurePlan,
} from 'adapters/manure-adapter';
import { getMonsters, getSilages } from 'adapters/monster-adapter';
import { getActivities, getActivitySchema } from 'adapters/planning-adapter';
import {
  // getActiveAdvisorMandates,
  // getActiveDataMandates,
  // getOpenAdvisorMandates,
  // getOpenDataMandates,
  getUser,
} from 'adapters/user-adapter';
import { notification, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { atoms } from 'atoms';
import { SelectRoleModal } from 'components';
import { useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { getDefaultRootColors } from 'requests/defaultRootVariables';
import { mapDtoArrayToActivityArray } from 'utilities/activity-mapper';
import { setTheme } from 'utilities/setTheme';

export const InitWrapper: FC = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const selectedYear = useAtomValue(atoms.selectedYear);
  const setPlots = useUpdateAtom(atoms.plots);
  const setSubfields = useUpdateAtom(atoms.subfields);
  const setManurePlans = useUpdateAtom(atoms.manurePlans);
  const setSchema = useUpdateAtom(atoms.schema);
  const setActivities = useUpdateAtom(atoms.activities);
  const setBreakpoint = useUpdateAtom(atoms.breakpoint);
  const setArtificialFertilizers = useUpdateAtom(atoms.artificialFertilizers);
  const setOrganicFertilizers = useUpdateAtom(atoms.organicFertilizers);
  const setCropTypes = useUpdateAtom(atoms.croptypes);
  const setMonsters = useUpdateAtom(atoms.monsters);
  const setSilages = useUpdateAtom(atoms.silages);
  const impersonatedRelationId = useAtomValue(atoms.impersonatedRelationId);
  const [user, setUser] = useAtom(atoms.user);
  const [showUserRoleModal, setShowUserRoleModal] = useState(false);

  useEffect(() => {
    setTheme(getDefaultRootColors());
    return () => console.log('initWrapper destroyed');
  }, []);

  useEffect(() => {
    (async () => {
      if (keycloak.authenticated && !user) {
        let temp;
        try {
          temp = await getUser();
          console.log('User: ', temp);
          setUser(temp);
          setShowUserRoleModal(false);
        } catch (err: any) {
          if (err.code === 'ECONNABORTED') {
            // retry request
            try {
              temp = await getUser();
              setUser(temp);
            } catch (error: any) {
              console.error(error);
              notification['error']({
                message: 'Er ging iets mis',
                description: error.toString(),
              });
            }
          } else if (!err.message.includes('404')) {
            console.error(err);
            notification['error']({
              message: 'Er ging iets mis',
              description: err.toString(),
            });
          }
          setShowUserRoleModal(true);
        }
      } else if (keycloak.authenticated && user && showUserRoleModal) {
        setShowUserRoleModal(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.authenticated]);

  const { xl, md } = useBreakpoint();
  useEffect(() => {
    const offset = xl ? 100 : md ? 135 : 60;
    setBreakpoint({ xl: xl, md: md, offset: offset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xl, md]);

  useEffect(() => {
    (async () => {
      console.log(user, selectedYear);
      if (user && (user.role !== 'advisor' || impersonatedRelationId)) {
        setLoading(true);
        const [
          plotsPromiseSettledResult,
          subfieldsPromiseSettledResult,
          manurePlansPromiseSettledResult,
          activitiesPromiseSettledResult,
          schemaPromiseSettledResult,
          artificialFertilizersResult,
          organicFertilizersResult,
          cropTypeResult,
          monsterResult,
          silageResult,
          // activeDataMandatesPromiseSettledResult,
          // activeAdvisorMandatesPromiseSettledResult,
          // openDataMandatesPromiseSettledResult,
          // openAdvisorMandatesPromiseSettledResult,
        ] = await Promise.allSettled(
          [
            getSubfields({ year: selectedYear }),
            getSubfieldData({ year: selectedYear }),
            getSubfieldManurePlan({ year: selectedYear }),
            getActivities({ year: selectedYear }),
            getActivitySchema({ year: selectedYear }),
            getArtificialFertilizer({ year: selectedYear }),
            getOrganicFertilizer({ year: selectedYear }),
            getCropTypes({ year: selectedYear }),
            getMonsters({ year: selectedYear }),
            getSilages({ year: selectedYear }),
            // getActiveDataMandates(),
            // getActiveAdvisorMandates(),
            // getOpenDataMandates(),
            // getOpenAdvisorMandates(),
          ].map((promise) =>
            promise.catch((err) => {
              console.error(err);
              notification['error']({
                message: 'Er ging iets mis',
                description: err.toString(),
              });
              throw err;
            }),
          ),
        );

        if (
          activitiesPromiseSettledResult.status === 'fulfilled' &&
          plotsPromiseSettledResult.status === 'fulfilled'
        ) {
          const mappedActivities = mapDtoArrayToActivityArray(
            activitiesPromiseSettledResult.value,
          );
          const newActivities = mappedActivities.map((activity) => {
            return {
              ...activity,
              properties: {
                ...activity.properties,
                plotName: plotsPromiseSettledResult.value.find(
                  (x) => x.id === activity.properties.plot,
                )?.properties?.name,
              },
            };
          });
          setActivities(newActivities);
        }

        if (plotsPromiseSettledResult.status === 'fulfilled') {
          setPlots(plotsPromiseSettledResult.value);
        }

        if (cropTypeResult.status === 'fulfilled') {
          setCropTypes(cropTypeResult.value);
        }

        if (subfieldsPromiseSettledResult.status === 'fulfilled') {
          setSubfields(subfieldsPromiseSettledResult.value);
        }

        if (manurePlansPromiseSettledResult.status === 'fulfilled') {
          setManurePlans(manurePlansPromiseSettledResult.value);
        }

        if (schemaPromiseSettledResult.status === 'fulfilled') {
          setSchema(schemaPromiseSettledResult.value);
        }
        if (artificialFertilizersResult.status === 'fulfilled') {
          setArtificialFertilizers(artificialFertilizersResult.value);
        }
        if (organicFertilizersResult.status === 'fulfilled') {
          setOrganicFertilizers(organicFertilizersResult.value);
        }
        if (monsterResult.status === 'fulfilled') {
          setMonsters(monsterResult.value);
        }
        if (silageResult.status === 'fulfilled') {
          setSilages(silageResult.value);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => console.log('Requests canceled?');
  }, [selectedYear, user]);

  return (
    <Spin
      spinning={isLoading}
      style={{ height: '100%' }}
      className="top-spinner"
      wrapperClassName="top-spinner-wrapper"
    >
      {keycloak.authenticated && !user && (
        <SelectRoleModal isModalVisible={showUserRoleModal} />
      )}
      {children}
    </Spin>
  );
};
