import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Popconfirm, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { CustomButton } from 'components/buttons';
import React, { useCallback, useEffect, useState } from 'react';
import { EditableCell } from './EditableCell';
interface EditableTableProps extends React.HTMLAttributes<HTMLElement> {
  dataSource: any;
  columns: any[];
  numberKeys?: string[];
  editFunc?: (record: any) => any;
  deleteFunc?: (record: any) => any;
}
export const EditableTable: React.FC<EditableTableProps> = ({
  title,
  columns,
  dataSource,
  numberKeys,
  editFunc,
  deleteFunc,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const editCallback = useCallback(
    (record) => {
      if (editFunc) {
        editFunc(record);
      }
    },
    [editFunc],
  );
  const deleteCallback = useCallback(
    (record) => {
      if (deleteFunc) {
        deleteFunc(record);
      }
    },
    [deleteFunc],
  );

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const newColumns =
    !editFunc && !deleteFunc
      ? columns
      : [
          ...columns,
          {
            title: 'Acties',
            dataIndex: 'Acties',
            render: (_: any, record: any) => {
              return (
                <Space>
                  {editFunc && (
                    <Tooltip title="Aanpassen">
                      <Typography.Link onClick={() => editCallback(record)}>
                        <FontAwesomeIcon icon="edit" />
                      </Typography.Link>
                    </Tooltip>
                  )}
                  {deleteFunc && (
                    <Popconfirm
                      title="Weet u zeker dat u wilt verwijderen?"
                      okText="Bevestig"
                      cancelText="Annuleer"
                      onConfirm={() => deleteCallback(record)}
                    >
                      <Tooltip title="Verwijderen">
                        <CustomButton
                          type="text"
                          icon={<FontAwesomeIcon icon="trash" />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  )}
                </Space>
              );
            },
          },
        ];

  const mergedColumns = newColumns.map((col) => {
    if (!(col as any).editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: numberKeys?.includes((col as any).key) ? 'number' : 'text',
        dataIndex: (col as any).dataIndex,
        title: (col as any).title,
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns as ColumnsType<any>}
        rowClassName="editable-row"
        scroll={{ x: true }}
      />
    </Form>
  );
};
