import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import Control from 'react-leaflet-control';

export type EditControlProps = {
  position: 'topleft' | 'topright' | 'bottomright' | 'bottomleft';
  onClick: () => any;
  icon?: IconProp;
  text?: string;
  disabled?: boolean;
  type?: 'text' | 'link' | 'ghost' | 'default' | 'primary' | 'dashed';
  danger?: boolean;
};

export const EditControl: FunctionComponent<EditControlProps> = ({
  position,
  onClick,
  icon,
  text,
  disabled,
  type,
  danger
}) => {
  return (
    <Control position={position}>
      <Button onClick={onClick} disabled={disabled} danger={danger} type={type}>
        {icon ? (
          <Tooltip title={text} placement="right">
            <FontAwesomeIcon icon={icon} />
          </Tooltip>
        ) : (
          text
        )}
      </Button>
    </Control>
  );
};
