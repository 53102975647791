import { feature } from '@turf/turf';
import { Feature, FeatureCollection } from 'geojson';
import { CropType } from 'types';
import { api } from 'utilities/api.axios';
import { geometryToArea } from 'utilities/geometry-to-area';

type CropFieldParams = {
  year: number;
};

export const getRvoCropfields = (params: CropFieldParams) =>
  api
    .get<FeatureCollection>(`cropfield/v1/RvoCropField`, { params })
    .then((r) => r.data.features);

export const getSubfields = (params: CropFieldParams) =>
  api
    .get<FeatureCollection>(`cropfield/v1/SubField`, { params })
    .then((r) => r.data.features);

export const getCropTypes = (params: CropFieldParams) =>
  api.get<CropType[]>(`cropfield/v1/CropTypes`, { params }).then((r) => r.data);

export const postSubfields = (params: CropFieldParams, features: Feature[]) => {
  // For each feature calculate area before save. The back-end requires for now that we calculate the area in the front-end. Might change in the future.
  const newFeatures = features.map((feature) => ({
    ...feature,
    properties: {
      ...feature.properties,
      area: geometryToArea(feature.geometry),
    },
  }));

  const collection: FeatureCollection = {
    type: 'FeatureCollection',
    features: newFeatures,
  };
  return api
    .post<FeatureCollection>(`cropfield/v1/SubField`, collection, { params })
    .then((r) => r.data.features);
};

export const copyDataFromPreviousYear = (selectedYear: number) => {
  return api.post(
    'cropfield/v1/SubField/copydatafrompreviousyear',
    {},
    {
      params: { selectedYear },
    },
  );
};
