import { atoms } from 'atoms';
import { Header } from 'components';
import { PlotViewer } from 'components/PlotEditor/PlotViewer';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import {
  AdvisorLandingPage,
  FertilizationPage,
  Login,
  Mobile,
  PlanboardPage,
  Plots,
  Settings,
  YearlyOverview,
} from 'pages';
import { ActivityTotals } from 'pages/activity-totals-page';
import { RuwVoer } from 'pages/ruwvoer-page';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { api } from 'utilities/api.axios';
import { isMobile } from 'utilities/is-mobile';
import { ProtectedRoute } from 'utilities/protected-route';

export const Body = () => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const [user] = useAtom(atoms.user);
  const impersonatedRelationId = useAtomValue(atoms.impersonatedRelationId);
  const getIsAuthorized = useCallback(() => {
    return !!keycloak.authenticated;
  }, [keycloak]);

  useEffect(() => {
    if (impersonatedRelationId) {
      api.defaults.headers.common['x-company-kvk'] = impersonatedRelationId;
    } else {
      delete api.defaults.headers.common['x-company-kvk'];
    }
  }, [impersonatedRelationId]);

  return user?.role !== 'advisor' || impersonatedRelationId ? (
    <>
      <Header />
      <Switch>
        <Route exact path="/">
          {isMobile() ? (
            <Redirect to={{ pathname: '/mobile' }} />
          ) : (
            <Redirect to="/planboard" />
          )}
        </Route>
        <ProtectedRoute
          component={PlanboardPage}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/planboard"
        />
        <ProtectedRoute
          component={Mobile}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/mobile"
        />
        <ProtectedRoute
          component={FertilizationPage}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/fertilization"
        />
        <ProtectedRoute
          component={PlotViewer}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/map"
        />
        <ProtectedRoute
          component={Plots}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/plots"
        />
        <ProtectedRoute
          component={Plots}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/plots/:page"
        />
        <ProtectedRoute
          component={Settings}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/settings"
        />
        <ProtectedRoute
          component={RuwVoer}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/ruwvoer"
        />
        <ProtectedRoute
          component={YearlyOverview}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/overview"
        />
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/">
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  ) : (
    <>
      <Header />
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <ProtectedRoute
          component={AdvisorLandingPage}
          redirectPath="/login"
          isAuthorized={getIsAuthorized}
          exact
          path="/advisor"
        />
        <Route path="/">
          <Redirect to="/advisor" />
        </Route>
      </Switch>
    </>
  );
};
