import { Tabs } from 'antd';
import { CustomCard } from 'components';
import { Container } from 'components/grid';
import { DataClientMandates, AdvisorMandates } from 'pages';
import { Account } from './account';
import { DataTransfer } from './data-transfer';
import { LabBerichten } from './lab-berichten';
import { Support, SupportTabPane } from './support';

const { TabPane } = Tabs;

export const Settings = () => {
  return (
    <Container>
      <CustomCard>
        <Tabs tabPosition="left" destroyInactiveTabPane={true}>
          <TabPane tab="Adviseur machtigingen" key="1">
            <AdvisorMandates />
          </TabPane>
          <TabPane tab="Datakluis machtigingen" key="2">
            <DataClientMandates />
          </TabPane>
          <TabPane tab="Data overzetten " key="3">
            <DataTransfer />
          </TabPane>
          <TabPane tab="Mijn account" key="4">
            <Account />
          </TabPane>
          <TabPane tab="Mijn lab berichten" key="5">
            <LabBerichten />
          </TabPane>
          <TabPane tab={<SupportTabPane />} key="6">
            <Support />
          </TabPane>
        </Tabs>
      </CustomCard>
    </Container>
  );
};
