import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { atoms } from 'atoms';
import dayjs from 'dayjs';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { Monster } from 'types/monster';
import { defaultSorter } from 'utilities/default-sorter';
import { roundToDecimals } from 'utilities/roundToDecimals';

const TableHeader: FC<{ title: string; subtitle?: string }> = ({
  title,
  subtitle,
}) => (
  <>
    <div style={{ fontSize: 14, fontWeight: 'bold' }}>{title}</div>
    <div style={{ fontSize: 12 }}>{subtitle}</div>
  </>
);

export interface MonsterCardProps {}
const getColumns = (fields: Feature<Geometry, GeoJsonProperties>[]) => {
  return [
    {
      title: <TableHeader title="Naam" />,
      dataIndex: 'name',
      key: 'name',
      sortOrder: 'ascend',
      sorter: defaultSorter((x) => x.name),
      showSorterTooltip: false,
      width: '10%',
      onFilter: (value: any, record) => record.name.startsWith(value),
    },
    {
      title: <TableHeader title="Datum" />,
      dataIndex: 'sampledDate',
      key: 'sampledDate',
      sorter: (a, b) =>
        dayjs(a.sampledDate).unix() - dayjs(b.sampledDate).unix(),
      render: (value: string) => dayjs(value).format('DD-MM-YYYY'),
      showSorterTooltip: false,
      width: '10%',
      onFilter: (value: any, record) => record.sampledDate.startsWith(value),
    },
    // {
    //   title: <TableHeader title="Percelen" />,
    //   dataIndex: 'subFieldIds',
    //   key: 'subFieldIds',
    //   showSorterTooltip: false,
    //   // onFilter: (value: string, record) => record.plots.includes(value),
    //   render: (subFieldIds: SoilSampleSubField[], record) =>
    //     subFieldIds.reduce(
    //       (acc, plot) =>
    //         `${acc} ${
    //           fields.find((field) => field.id === plot.subFieldId)?.properties
    //             ?.name
    //         },`,
    //       '',
    //     ),
    //   width: '10%',
    // },
    {
      title: <TableHeader title="NLV" subtitle="kg N/ha" />,
      dataIndex: ['nlv', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'nlv',
      sorter: (a, b) => (a.nlv?.value || 0) - (b.nlv?.value || 0),
      showSorterTooltip: false,
      width: '5%',
    },
    {
      title: <TableHeader title="SLV" subtitle="kg S/ha" />,
      dataIndex: ['slv', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'slv',
      sorter: (a, b) => (a.slv?.value || 0) - (b.slv?.value || 0),
      width: '5%',
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="P-plant" subtitle="kg P/ha" />,
      dataIndex: ['pPlant', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'pPlant',
      sorter: (a, b) => (a.pPlant?.value || 0) - (b.pPlant?.value || 0),
      width: '5%',
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="K-plant" subtitle="kg K/ha" />,
      dataIndex: ['kPlant', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'kPlant',
      width: '5%',
      sorter: (a, b) => (a.kPlant?.value || 0) - (b.kPlant?.value || 0),
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="Ca-plantbeschikbaar" subtitle="kg Ca/ha" />,
      dataIndex: ['caPlant', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'caPlant',
      sorter: (a, b) => (a.caPlant?.value || 0) - (b.caPlant?.value || 0),
      width: '8%',
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="pH" />,
      dataIndex: ['ph', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'ph',
      sorter: (a, b) => (a.ph?.value || 0) - (b.ph?.value || 0),
      width: '5%',
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="OS" subtitle="%" />,
      dataIndex: ['os', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'os',
      width: '5%',
      sorter: (a, b) => (a.os?.value || 0) - (b.os?.value || 0),
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="CEC" subtitle="%" />,
      dataIndex: ['cec', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '5%',
      key: 'cec',
      sorter: (a, b) => (a.cec?.value || 0) - (b.cec?.value || 0),
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="CEC bez" subtitle="%" />,
      dataIndex: ['cecBez', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'cecBez',
      sorter: (a, b) => (a.cecBez?.value || 0) - (b.cecBez?.value || 0),
      width: '5%',
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="Ca bez" subtitle="%" />,
      dataIndex: ['caBez', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      key: 'caBez',
      sorter: (a, b) => (a.caBez?.value || 0) - (b.caBez?.value || 0),
      width: '5%',
      showSorterTooltip: false,
    },
    {
      title: <TableHeader title="Mg bez" subtitle="%" />,
      dataIndex: ['mgBez', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '5%',
      key: 'mgBez',
      sorter: (a, b) => (a.mgBez?.value || 0) - (b.mgBez?.value || 0),
    },
  ] as ColumnsType<Monster>;
};
export const MonsterCard: FC<MonsterCardProps> = () => {
  const [monsters] = useAtom(atoms.monsters);
  const [plots] = useAtom(atoms.plots);
  return (
    <Table
      columns={getColumns(plots)}
      bordered
      pagination={false}
      dataSource={monsters}
      scroll={{ y: 'calc(100vh - 400px)' }}
    />
  );
};
