import { SubFieldData } from 'types';
import { Monster, Silage } from 'types/monster';
import { api } from 'utilities/api.axios';

type monsterParams = {
  year: number;
};
type updateMonsterParams = {
  monster: Monster;
  subfield: SubFieldData;
};
type updateSilageParams = {
  silage: Silage;
  subfieldId: string;
};

export const getMonsters = (params: monsterParams) =>
  api
    .get<Monster[]>(`sample/v1/SoilSample`, { params })
    .then((r) => r.data);

export const addSubfieldToMonster = (params: updateMonsterParams) =>
  api
    .put<Monster[]>(`sample/v1/SoilSample`, {
      ...params.monster,
      subFieldIds: params.monster.subFieldIds.reduce(
        (acc, cur) => {
          if (!acc.includes(cur.subFieldId)) acc.push(cur.subFieldId);

          return acc;
        },
        [params.subfield.subFieldId] as string[],
      ),
    })
    .then((r) => r.data);
export const addSubfieldToSilage = (params: updateSilageParams) =>
  api
    .put<Silage[]>(`sample/v1/SilageSample`, {
      ...params.silage,
      subFieldId: params.subfieldId,
    })
    .then((r) => r.data);

export const getSilages = (params: monsterParams) =>
  api
    .get<Silage[]>(`sample/v1/SilageSample`, { params })
    .then((r) => r.data);
