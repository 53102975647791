import {
  createArtificialFertilizer,
  updateArtificialFertilizer,
} from 'adapters/manure-adapter';
import { Button, Form, Input } from 'antd';
import { atoms } from 'atoms';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArtificialFertilizer } from 'types/artificialFertilizer';
import { CustomInputNumber } from 'utilities/InputNumberNlFormat';
import styles from './styles.module.scss';

export interface ArtificialFertilizerFormProps {
  onSubmit: () => any;
  fertilizer?: ArtificialFertilizer;
}
export const ArtificialFertilizerForm: FC<ArtificialFertilizerFormProps> = ({
  onSubmit,
  fertilizer,
}) => {
  const { t } = useTranslation();
  const setFertilizers = useUpdateAtom(atoms.artificialFertilizers);
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [form] = Form.useForm();
  useEffect(() => {
    if (fertilizer) {
      form.setFieldsValue(fertilizer);
    }
  }, [fertilizer, form]);

  const onfinish = (values) => {
    if (fertilizer) {
      updateFertilizer(values);
      resetForm();
      return;
    }
    addFertilizer(values);
    resetForm();
    return;
  };
  const resetForm = () => {
    form.resetFields();
    onSubmit();
  };

  const addFertilizer = async (values: any) => {
    const fertilizer = await createArtificialFertilizer(
      values as ArtificialFertilizer,
    );
    setFertilizers((current) => [...current, fertilizer]);
    resetForm();
    onSubmit();
  };

  const updateFertilizer = async (values: any) => {
    const fertilizer = await updateArtificialFertilizer(
      values as ArtificialFertilizer,
    );
    setFertilizers((current) => [
      ...current.filter((f) => f.id !== fertilizer.id),
      fertilizer,
    ]);
    resetForm();
    onSubmit();
  };
  return (
    <Form
      layout={'horizontal'}
      labelCol={{ span: 8 }}
      labelAlign="left"
      colon={false}
      form={form}
      onFinish={onfinish}
    >
      <h1>Kunstmestsoort toevoegen</h1>
      <Form.Item hidden name="id">
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item hidden name="farmId">
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item initialValue={selectedYear} hidden name="year">
        <CustomInputNumber className={styles.field} />
      </Form.Item>
      <Form.Item
        name="fertilizerType"
        label={t('artificialpage.fertilizerType')}
        required
      >
        <Input className={styles.field} />
      </Form.Item>
      <Form.Item name="amount" label={t('artificialpage.amount')}>
        <CustomInputNumber precision={2} min={0} className={styles.field} />
      </Form.Item>
      <Form.Item name="nitrogen" label={t('artificialpage.nitrogen')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="ammonium" label={t('artificialpage.ammonium')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="nitrate" label={t('artificialpage.nitrate')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="urea" label={t('artificialpage.urea')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="amide" label={t('artificialpage.amide')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item
        name="potassiumOxide"
        label={t('artificialpage.potassiumOxide')}
      >
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item
        name="phosphorusPentoxide"
        label={t('artificialpage.phosphorusPentoxide')}
      >
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item
        name="magnesiumOxide"
        label={t('artificialpage.magnesiumOxide')}
      >
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item
        name="sulfurTrioxide"
        label={t('artificialpage.sulfurTrioxide')}
      >
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="calciumOxide" label={t('artificialpage.calciumOxide')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="sodiumOxide" label={t('artificialpage.sodiumOxide')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="selenium" label={t('artificialpage.selenium')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="copper" label={t('artificialpage.copper')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="cobalt" label={t('artificialpage.cobalt')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="chlorine" label={t('artificialpage.chlorine')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item
        name="nitrificationInhibitor"
        label={t('artificialpage.nitrificationInhibitor')}
      >
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <Form.Item name="other" label={t('artificialpage.other')}>
        <CustomInputNumber
          precision={2}
          max={100}
          min={0.01}
          className={styles.field}
        />
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button htmlType="submit" type="primary">
          {t('fertilizationplan.submit')}
        </Button>
      </div>
    </Form>
  );
};
