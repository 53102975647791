import { CustomCard } from 'components';
import { Container } from 'components/grid';
import { FC } from 'react';
import { RuwvoerTable } from './RuwvoerTable';

export interface RuwVoerProps {}
export const RuwVoer: FC<RuwVoerProps> = () => {
  return (
    <Container>
      <CustomCard>
        <RuwvoerTable />
      </CustomCard>
    </Container>
  );
};
