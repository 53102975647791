import { atom } from 'jotai';

type BreakpointOffset = {
  xl: boolean | undefined;
  md: boolean | undefined;
  offset: number;
};
export const breakpoint = atom<BreakpointOffset>({
  xl: false,
  md: false,
  offset: 0,
});
