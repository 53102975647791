import { getCropTypes } from 'adapters/cropfield-adapter';
import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { atoms } from 'atoms';
import { Feature } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { useState } from 'react';
import { useEffect } from 'react';
import { FC } from 'react';
import { CropType } from 'types';

export interface PlotEditorProps {
  plot: Feature;
  onSubmit: (plot: Feature) => void;
}
export const PlotEditor: FC<PlotEditorProps> = ({ plot, onSubmit }) => {
  const [cropTypes] = useAtom(atoms.croptypes);
  const [form] = Form.useForm();
  const onfinish = (values) => {
    onSubmit({ ...plot, ...values });
    resetForm();
  };
  const resetForm = () => {
    form.resetFields();
  };

  return (
    <Form
      layout={'horizontal'}
      labelCol={{ span: 5 }}
      labelAlign="left"
      colon={false}
      form={form}
      onFinish={onfinish}
      onAbort={resetForm}
      initialValues={plot}
    >
      <h1>Perceel aanpassen</h1>
      <Form.Item name={['properties', 'name']} label="naam">
        <Input />
      </Form.Item>
      <Form.Item hidden name={['properties', 'hectare']} label="hectare">
        <Input />
      </Form.Item>
      <Form.Item name={['properties', 'cropTypeId']} label="gewas">
        <Select>
          {cropTypes
            .filter(
              (cropType) =>
                cropType.selectable ||
                cropType.cropTypeId === plot.properties?.cropTypeId,
            )
            .map((cropType) => (
              <Select.Option value={cropType.cropTypeId}>
                {cropType.description}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item hidden name={['properties', 'id']} label="id">
        <Input />
      </Form.Item>
      <Form.Item name={['properties', 'type']} hidden label="type">
        <Input />
      </Form.Item>
      <Form.Item name={['properties', 'year']} hidden label="jaar">
        <Input />
      </Form.Item>
      <Button htmlType="submit" type="primary">
        Opslaan
      </Button>
    </Form>
  );
};
