import { deleteOrganicFertilizer } from 'adapters/manure-adapter';
import { Divider } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { atoms } from 'atoms';
import { EditableTable } from 'components/editable-table/EditableTable';
import { OrganicFertilizerForm } from 'components/organic-modal';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganicFertilizer } from 'types/organicFertilizer';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';

export interface OrganicFertilizersProps {}
export const OrganicFertilizers: FC<OrganicFertilizersProps> = () => {
  const setOrganicFertilizers = useUpdateAtom(atoms.organicFertilizers);
  const defaultOrganicFertilizers = useAtomValue(
    atoms.defaultOrganicFertilizers,
  );
  const ownOrganicFertilizers = useAtomValue(atoms.ownOrganicFertilizers);
  const { t } = useTranslation();
  const setModalComponent = useUpdateAtom(atoms.modal.component);
  const setShowModal = useUpdateAtom(atoms.modal.show);
  const setModalState = (comp) => {
    setShowModal(true);
    setModalComponent(comp);
  };

  const format = (value) =>
    value === undefined || value === null
      ? ''
      : displayWithDecimals(2)(roundToDecimals(2)(value));

  const editFertilizer = (record: any) => {
    setModalState(
      <OrganicFertilizerForm
        onSubmit={() => setShowModal(false)}
        fertilizer={record}
      />,
    );
  };
  const deleteFertilizer = (record: OrganicFertilizer) => {
    if (record.id) {
      deleteOrganicFertilizer({ id: record.id }).then((r) =>
        setOrganicFertilizers((current) =>
          current.filter((fert) => fert.id !== record.id),
        ),
      );
    }
  };
  const InitColumns = [
    { id: 'manureCode' },
    { id: 'fertilizerType' },
    { id: 'lifestockType' },
    { id: 'amount', render: format },
    { id: 'kgNitrogenPerTon', render: format },
    { id: 'kgPhosphatePerTon', render: format },
    { id: 'workingCoefficient', render: format },
    { id: 'workingKgNitrogenPerTon', render: format },
    { id: 'additive' },
  ];

  const columns = InitColumns.map((c) => ({
    title: t(`organicpage.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    render: c.render,
  })) as ColumnsType<OrganicFertilizer>;
  return (
    <>
      <h2>Standaard</h2>
      <Table
        columns={columns
          .filter((c) => c.key !== 'amount')
          .filter((c) => c.key !== 'additive')}
        bordered
        dataSource={defaultOrganicFertilizers}
      />
      <Divider />
      <h2>Eigen</h2>
      <EditableTable
        editFunc={editFertilizer}
        deleteFunc={deleteFertilizer}
        columns={columns}
        dataSource={ownOrganicFertilizers}
      />
    </>
  );
};
