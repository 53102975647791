import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import length from '@turf/length';
import { area, Coord, distance, getCoords } from '@turf/turf';
import { Button, Statistic } from 'antd';
import { Feature } from 'geojson';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLeaflet } from 'react-leaflet';
import Control from 'react-leaflet-control';
import { createPlot } from '../helper';
import { EditMode } from '../PlotViewer';

type DrawingToolsProps = {
  position: 'topleft' | 'topright' | 'bottomright' | 'bottomleft';
  mode: EditMode;
  selectedPlots: Array<number>;
  onCreate?: (plot: Feature) => void;
  onSplit?: (plot: Feature) => void;
  onEdit?: (plot: Feature) => void;
  onCancel: () => void;
};

export const DrawingTools = ({
  position,
  mode,
  selectedPlots,
  onCreate,
  onSplit,
  onEdit,
  onCancel,
}: DrawingToolsProps) => {
  const { t } = useTranslation();
  const { map } = useLeaflet();
  const [editLayer, setEditLayer] = useState<any>(null);
  const [lineLength, setLineLength] = useState(0);
  const [currentArea, setCurrentArea] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  const handleDrawStart = ({ workingLayer }) => {
    setEditLayer(workingLayer);
  };

  useEffect(() => {
    const updateStatistics = (layer) => {
      try {
        const layerGeoJson = layer.toGeoJSON();
        if (!layerGeoJson) {
          return;
        }
        setTotalLength(length(layerGeoJson, { units: 'metres' }));
        setCurrentArea(area(layerGeoJson));
      } catch (e) {
        console.log(e);
      }
    };

    if (!editLayer) {
      return;
    }

    try {
      let lastSnap: Coord | null = null;
      updateStatistics(editLayer);

      editLayer.on('pm:vertexadded', ({ marker }) => {
        lastSnap = getCoords(marker.toGeoJSON());
        updateStatistics(editLayer);
      });

      editLayer.on('pm:snapdrag', ({ marker }) => {
        updateStatistics(editLayer);
        if (lastSnap) {
          setLineLength(
            distance(lastSnap, getCoords(marker.toGeoJSON()), {
              units: 'metres',
            }),
          );
        }
      });

      editLayer.on('pm:edit', () => {
        updateStatistics(editLayer);
      });
    } catch (e) {
      console.log(e);
    }
  }, [editLayer]);

  const handleDrawEnd = () => {
    setLineLength(0);
    setTotalLength(0);
    setCurrentArea(0);
  };

  const handleCreate = ({ layer }) => {
    if (!layer) {
      throw new Error('pm:create event did not have required layer parameter.');
    }

    // The create function also adds a new layer based on the geoJSON.
    // We do not want to have a duplicate layer - so we delete the GeoMan version
    layer.remove();

    const layerGeoJson = layer.toGeoJSON();

    const geoJson = createPlot(
      layer._leaflet_id,
      `${t('ploteditor.newplotName')} ${layer._leaflet_id}`,
      layerGeoJson,
    );

    if (mode === EditMode.Drawing && typeof onCreate === 'function') {
      return onCreate(geoJson);
    } else if (mode === EditMode.Splitting && typeof onSplit === 'function') {
      return onSplit(geoJson);
    }
  };

  const handleEdit = () => {
    const layers = map.pm.getGeomanLayers();
    const firstSelected = selectedPlots[0];
    const selectedLayer = layers.find((x) => firstSelected === x.feature?.id);

    if (!selectedLayer) {
      console.log(`No layer found with id: ${firstSelected}`);
      return;
    }

    // The create function also adds a new layer based on the geoJSON.
    // We do not want to have a duplicate layer - so we delete the GeoMan version
    selectedLayer.remove();

    const layerGeoJson = selectedLayer.toGeoJSON();

    // If there is an existing id or name we do not want to change it.
    // This will happen in case of edit mode.
    const geoJson = createPlot(
      layerGeoJson.id,
      layerGeoJson.properties?.name,
      layerGeoJson,
    );

    if (mode === EditMode.Editting && typeof onEdit === 'function') {
      return onEdit(geoJson);
    }
  };

  const handleCancel = () => {
    try {
      if (editLayer) {
        editLayer.pm.disable();
        editLayer.remove();
        setEditLayer(null);
      }
    } catch (e) {
      console.log(e);
    }
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  useEffect(() => {
    map.on('pm:create', handleCreate);
    map.on('pm:drawstart', handleDrawStart);
    map.on('pm:drawend', handleDrawEnd);
    return () => {
      map.off('pm:create', handleCreate);
      map.off('pm:drawstart', handleDrawStart);
      map.off('pm:drawend', handleDrawEnd);
    };
  });

  useEffect(() => {
    if (mode === EditMode.Drawing) {
      map.pm.enableDraw('Polygon', {
        allowSelfIntersection: false,
        finishOn: 'dblclick',
      });
    }

    if (mode === EditMode.Splitting) {
      map.pm.enableDraw('Line', {
        allowSelfIntersection: false,
        finishOn: 'dblclick',
      });
    }

    if (mode === EditMode.Editting) {
      const layers = map.pm.getGeomanLayers();
      const firstSelected = selectedPlots[0];
      const selectedLayer = layers.find((x) => firstSelected === x.feature?.id);

      if (!selectedLayer) {
        console.log(`No layer found with id: ${firstSelected}`);
        return;
      }

      selectedLayer.pm.enable({
        allowSelfIntersection: false,
      });

      setEditLayer(selectedLayer);
    }

    return () => {
      map.pm.disableDraw();
    };
  }, [mode, selectedPlots, map]);

  return (
    <Control position={position}>
      <Statistic title={t('ploteditor.lineLength')} value={lineLength} />
      <Statistic title={t('ploteditor.totalLength')} value={totalLength} />
      <Statistic title={t('ploteditor.area')} value={currentArea / 10000} />
      <Button onClick={handleCancel} danger>
        {t('ploteditor.buttons.cancel')}
      </Button>
      {mode === EditMode.Editting && (
        <Button onClick={handleEdit} type="primary">
          {t('ploteditor.buttons.save')}
        </Button>
      )}
    </Control>
  );
};
