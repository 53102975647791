import { FC } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import styles from './custombutton.module.scss';

interface CustomButtonProps extends ButtonProps {
  color?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'danger';
}

export const CustomButton: FC<CustomButtonProps> = ({
  color = '',
  children,
  ...rest
}) => {
  return (
    <Button className={[styles.button, styles[color]].join(' ')} {...rest}>
      {children}
    </Button>
  );
};
