import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { atoms } from 'atoms';
import dayjs from 'dayjs';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { FC } from 'react';
import { Silage } from 'types/monster';
import { defaultSorter } from 'utilities/default-sorter';
import { roundToDecimals } from 'utilities/roundToDecimals';

const TableHeader: FC<{ title: string; subtitle?: string }> = ({
  title,
  subtitle,
}) => (
  <>
    <div style={{ fontSize: 14, fontWeight: 'bold' }}>{title}</div>
    <div style={{ fontSize: 12 }}>{subtitle}</div>
  </>
);

export interface RuwvoerTableProps {}
const getColumns = (plots: Feature<Geometry, GeoJsonProperties>[]) => {
  return [
    {
      title: <TableHeader title="Naam" />,
      width: '200px',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      showSorterTooltip: false,
      sortOrder: 'ascend',
      sorter: defaultSorter((x) => x.name),
      onFilter: (value: any, record: any) => record.name.startsWith(value),
    },
    // {
    //   title: <TableHeader title="Perceel" />,
    //   dataIndex: 'subFieldId',
    //   key: 'subFieldId',
    //   sorter: (a, b) =>
    //     plots
    //       .find((f) => f.id === a.subFieldId)
    //       ?.properties?.name.localeCompare(
    //         plots.find((f) => f.id === b.subFieldId)?.properties?.name,
    //       ),
    //   fixed: 'left',
    //   width: '250px',
    //   render: (value: string) =>
    //     plots.find((f) => f.id === value)?.properties?.name,
    //   showSorterTooltip: false,
    //   onFilter: (value: any, record: any) => record.date.startsWith(value),
    // },
    {
      title: <TableHeader title="Monster datum" />,
      dataIndex: 'sampledDate',
      key: 'sampledDate',
      sorter: (a, b) =>
        dayjs(a.sampledDate).unix() - dayjs(b.sampledDate).unix(),
      fixed: 'left',
      width: '150px',
      showSorterTooltip: false,
      render: (value: string) => dayjs(value).format('DD-MM-YYYY'),
      onFilter: (value: any, record: any) =>
        record.sampledDate.startsWith(value),
    },
    {
      title: <TableHeader title="DS" />,
      dataIndex: ['ds', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'ds',
      sorter: (a, b) => (a.ds?.value || 0) - (b.ds?.value || 0),
      showSorterTooltip: false,
      onFilter: (value: any, record: any) => record.ds.startsWith(value),
    },
    {
      title: <TableHeader title="pH" />,
      dataIndex: ['ph', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'ph',
      sorter: (a, b) => (a.ph?.value || 0) - (b.ph?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: string, record) => record.plots.includes(value),
    },
    {
      title: <TableHeader title="Boterzuur" />,
      dataIndex: ['boterzuur', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'boterzuur',
      sorter: (a, b) => (a.boterzuur?.value || 0) - (b.boterzuur?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.nlv === value,
    },
    {
      title: <TableHeader title="Azijnzuur" />,
      dataIndex: ['azijnzuur', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'azijnzuur',
      sorter: (a, b) => (a.azijnzuur?.value || 0) - (b.azijnzuur?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.slv === value,
    },
    {
      title: <TableHeader title="melkzuur" />,
      dataIndex: ['melkzuur', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'melkzuur',
      sorter: (a, b) => (a.melkzuur?.value || 0) - (b.melkzuur?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.pplant === value,
    },
    {
      title: <TableHeader title="vem" subtitle="kg K/ha" />,
      dataIndex: ['vem', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'vem',
      sorter: (a, b) => (a.vem?.value || 0) - (b.vem?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.kplant === value,
    },
    {
      title: <TableHeader title="suiker" />,
      dataIndex: ['suiker', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'suiker',
      sorter: (a, b) => (a.suiker?.value || 0) - (b.suiker?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.caplant === value,
    },
    {
      title: <TableHeader title="Ruw vet" />,
      dataIndex: ['ruwVet', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'ruwVet',
      sorter: (a, b) => (a.ruwVet?.value || 0) - (b.ruwVet?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.ph === value,
    },
    {
      title: <TableHeader title="DVE+" />,
      dataIndex: ['dve', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'dve',
      sorter: (a, b) => (a.dve?.value || 0) - (b.dve?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.os === value,
    },
    {
      title: <TableHeader title="reTotaal" />,
      dataIndex: ['reTotaal', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'reTotaal',
      sorter: (a, b) => (a.reTotaal?.value || 0) - (b.reTotaal?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.cec === value,
    },
    {
      title: <TableHeader title="Ruw eiwit" />,
      dataIndex: ['re', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 're',
      sorter: (a, b) => (a.re?.value || 0) - (b.re?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.cecbez === value,
    },
    {
      title: <TableHeader title="oplosbaarRE" />,
      dataIndex: ['oplosbaarRE', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '120px',
      key: 'oplosbaarRE',
      sorter: (a, b) =>
        (a.oplosbaarRE?.value || 0) - (b.oplosbaarRE?.value || 0),
      showSorterTooltip: false,
      // onFilter: (value: number, record) => record.cabez === value,
    },
    {
      title: <TableHeader title="lys" />,
      dataIndex: ['lys', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'lys',
      sorter: (a, b) => (a.lys?.value || 0) - (b.lys?.value || 0),
      // onFilter: (value: number, record) => record.mgbez === value,
    },
    {
      title: <TableHeader title="Meth" />,
      dataIndex: ['meth', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'meth',
      sorter: (a, b) => (a.meth?.value || 0) - (b.meth?.value || 0),
    },
    {
      title: <TableHeader title="OEB+" />,
      dataIndex: ['oeb', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'oeb',
      sorter: (a, b) => (a.oeb?.value || 0) - (b.oeb?.value || 0),
    },
    {
      title: <TableHeader title="NH3" />,
      dataIndex: ['nH3', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'nH3',
      sorter: (a, b) => (a.nH3?.value || 0) - (b.nH3?.value || 0),
    },
    {
      title: <TableHeader title="Nitraat" />,
      dataIndex: ['nitraat', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'nitraat',
      sorter: (a, b) => (a.nitraat?.value || 0) - (b.nitraat?.value || 0),
    },
    {
      title: <TableHeader title="Ruw as" />,
      dataIndex: ['ras', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'ras',
      sorter: (a, b) => (a.ras?.value || 0) - (b.ras?.value || 0),
    },
    {
      title: <TableHeader title="Ruwe celstof" />,
      dataIndex: ['rc', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'rc',
      sorter: (a, b) => (a.rc?.value || 0) - (b.rc?.value || 0),
    },
    {
      title: <TableHeader title="Vcos" />,
      dataIndex: ['vcos', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'vcos',
      sorter: (a, b) => (a.vcos?.value || 0) - (b.vcos?.value || 0),
    },
    {
      title: <TableHeader title="NDF" />,
      dataIndex: ['ndf', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'ndf',
      sorter: (a, b) => (a.ndf?.value || 0) - (b.ndf?.value || 0),
    },
    {
      title: <TableHeader title="ADL" />,
      dataIndex: ['adl', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'adl',
      sorter: (a, b) => (a.adl?.value || 0) - (b.adl?.value || 0),
    },
    {
      title: <TableHeader title="FOSP+" />,
      dataIndex: ['fos', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'fos',
      sorter: (a, b) => (a.fos?.value || 0) - (b.fos?.value || 0),
    },
    {
      title: <TableHeader title="K" />,
      dataIndex: ['k', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'k',
      sorter: (a, b) => (a.k?.value || 0) - (b.k?.value || 0),
    },
    {
      title: <TableHeader title="P" />,
      dataIndex: ['p', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'p',
      sorter: (a, b) => (a.p?.value || 0) - (b.p?.value || 0),
    },
    {
      title: <TableHeader title="S" />,
      dataIndex: ['s', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 's',
      sorter: (a, b) => (a.s?.value || 0) - (b.s?.value || 0),
    },
    {
      title: <TableHeader title="KAV" />,
      dataIndex: ['kav', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'kav',
      sorter: (a, b) => (a.kav?.value || 0) - (b.kav?.value || 0),
    },
    {
      title: <TableHeader title="Na" />,
      dataIndex: ['na', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'na',
      sorter: (a, b) => (a.na?.value || 0) - (b.na?.value || 0),
    },
    {
      title: <TableHeader title="Mg" />,
      dataIndex: ['mg', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'mg',
      sorter: (a, b) => (a.mg?.value || 0) - (b.mg?.value || 0),
    },
    {
      title: <TableHeader title="Ca" />,
      dataIndex: ['ca', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'ca',
      sorter: (a, b) => (a.ca?.value || 0) - (b.ca?.value || 0),
    },
    {
      title: <TableHeader title="Mn" />,
      dataIndex: ['mn', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'mn',
      sorter: (a, b) => (a.mn?.value || 0) - (b.mn?.value || 0),
    },
    {
      title: <TableHeader title="Zn" />,
      dataIndex: ['zn', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'zn',
      sorter: (a, b) => (a.zn?.value || 0) - (b.zn?.value || 0),
    },
    {
      title: <TableHeader title="Fe" />,
      dataIndex: ['fe', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'fe',
      sorter: (a, b) => (a.fe?.value || 0) - (b.fe?.value || 0),
    },
    {
      title: <TableHeader title="Cu" />,
      dataIndex: ['cu', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'cu',
      sorter: (a, b) => (a.cu?.value || 0) - (b.cu?.value || 0),
    },
    {
      title: <TableHeader title="Mo" />,
      dataIndex: ['mo', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'mo',
      sorter: (a, b) => (a.mo?.value || 0) - (b.mo?.value || 0),
    },
    {
      title: <TableHeader title="Co" />,
      dataIndex: ['co', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'co',
      sorter: (a, b) => (a.co?.value || 0) - (b.co?.value || 0),
    },
    {
      title: <TableHeader title="Se" />,
      dataIndex: ['se', 'value'],
      render: (value: number) =>
        value ? roundToDecimals(2)(value) : undefined,
      width: '100px',
      key: 'se',
      sorter: (a, b) => (a.se?.value || 0) - (b.se?.value || 0),
    },
  ] as ColumnsType<Silage>;
};
export const RuwvoerTable: FC<RuwvoerTableProps> = () => {
  const [silages] = useAtom(atoms.silages);
  const [plots] = useAtom(atoms.plots);
  return (
    <Table
      columns={getColumns(plots)}
      bordered
      pagination={false}
      scroll={{ x: 3000, y: 'calc(100vh - 250px)' }}
      dataSource={silages}
    />
  );
};
