import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CustomButton } from 'components/buttons';
import { FeatureSelect } from 'components/feature-select';
import { useState } from 'react';
import styles from './styles.module.scss';

export const FeatureSelectModal = ({
  features,
  selected = [],
  onChangeSelected = () => {},
}: {
  features: any[];
  selected: string[];
  onChangeSelected: (selected: string[]) => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <CustomButton
        color="secondary"
        icon={<FontAwesomeIcon icon={faMapMarkedAlt} />}
        onClick={openModal}
        className={styles.button}
        title="Selecteer gebruikspercelen op de kaart"
      ></CustomButton>

      <Modal
        title="Selecteer gebruikspercelen op de kaart"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} type="primary">
            Okee
          </Button>,
        ]}
        className={styles.Modal}
        width="100%"
      >
        <FeatureSelect
          features={features}
          selected={selected}
          onChangeSelected={onChangeSelected}
        />
      </Modal>
    </>
  );
};
