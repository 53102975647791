import Table, { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';

const displayValues = [
  'm3Manure',
  'kgP205FromManure',
  'kgP205FromFertilizer',
  'kgP205Total',
  'kgNitrogenFromManure',
  'kgWorkingNitrogenFromManure',
  'kgNitrogenFromFertilizer',
  'totalKgNitrogen',
];

const getCropCategoriesFromYearlyOverview = (yearlyOverview) => {
  return [
    ...yearlyOverview.annualReviewOfCompany.areaPerCropCategory.map(
      (category) => category.cropCategory,
    ),
    'Total',
    'UsageCapacity',
  ];
};

const makeRows = (yearlyOverview) => {
  const cropCategories = getCropCategoriesFromYearlyOverview(yearlyOverview);
  const rows = displayValues.map((dv) => ({
    name: dv,
    ...cropCategories
      .filter(
        (category) => category !== 'Total' && category !== 'UsageCapacity',
      )
      .reduce((acc, category) => {
        if (
          yearlyOverview?.annualReviewOfCompany?.plannedManurePerCropCategory[
            category
          ]
        ) {
          return {
            ...acc,
            ...{
              [category]:
                yearlyOverview.annualReviewOfCompany
                  .plannedManurePerCropCategory[category][dv + 'PerHectare'],
            },
          };
        } else {
          return acc;
        }
      }, {}),
    ...cropCategories
      .filter(
        (category) => category === 'Total' || category === 'UsageCapacity',
      )
      .reduce((acc, category) => {
        if (
          yearlyOverview?.annualReviewOfCompany?.plannedManurePerCropCategory[
            category
          ]
        ) {
          return {
            ...acc,
            ...{
              [category]:
                yearlyOverview.annualReviewOfCompany
                  .plannedManurePerCropCategory[category][dv],
            },
          };
        } else {
          return acc;
        }
      }, {}),
  }));
  return Object.keys(rows).length > 0 ? rows : undefined;
};

const format = (value) =>
  value === undefined || value === null
    ? ''
    : displayWithDecimals(2)(roundToDecimals(2)(value));

export const CompanyPlanned = ({ yearlyOverview }) => {
  const { t } = useTranslation();

  if (yearlyOverview) {
    const cropCategories = getCropCategoriesFromYearlyOverview(yearlyOverview);

    const InitColumns = [
      { id: 'name', render: (value) => t(`yearlyoverview.${value}`) },
    ];

    const columns = InitColumns.map((c) => ({
      title: t(`yearlyoverview.${c.id}`),
      dataIndex: c.id,
      key: c.id,
      render: c.render,
    })) as ColumnsType<any>;

    columns.push({
      title: t(`yearlyoverview.averagePerHa`),
      children: cropCategories
        .filter(
          (category) => category !== 'Total' && category !== 'UsageCapacity',
        )
        .map((category) => ({
          id: category,
          key: category,
          dataIndex: category,
          title: t(`yearlyoverview.${category}`),
          render: format,
        })),
    });

    columns.push(
      ...cropCategories
        .filter(
          (category) => category === 'Total' || category === 'UsageCapacity',
        )
        .map((category) => ({
          id: category,
          key: category,
          dataIndex: category,
          title: t(`yearlyoverview.${category}`),
          render: format,
        })),
    );

    // yearlyOverview.annualReviewPerSubfieldsPlanned
    return (
      <Table
        loading={!yearlyOverview}
        dataSource={makeRows(yearlyOverview)}
        columns={columns}
        scroll={{ x: true }}
      ></Table>
    );
  } else {
    return null;
  }
};
