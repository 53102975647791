import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faMousePointer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  List,
  Popconfirm,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { Feature } from 'geojson';
import React from 'react';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';
import { useTranslation } from 'react-i18next';
const { Paragraph } = Typography;

type SidebarItemProps = {
  key: number;
  plot: Feature;
  onNameChange: (id: number, name: string) => void;
  onSelect?: (id: number) => void;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
  selected?: boolean;
};

export const SidebarItem = ({
  plot,
  onNameChange,
  onSelect,
  onEdit,
  onDelete,
  selected,
}: SidebarItemProps) => {
  const actions: Array<any> = [];
  const { t } = useTranslation();
  if (onSelect) {
    actions.push(
      <Tooltip placement="topLeft" title={t('ploteditor.selectBtn')}>
        <Button
          size="small"
          icon={<FontAwesomeIcon icon={faMousePointer} />}
          onClick={() => onSelect(plot.id as number)}
        />
      </Tooltip>,
    );
  }

  if (onEdit) {
    actions.push(
      <Tooltip title={t('ploteditor.editBtn')}>
        <Button
          size="small"
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={() => onEdit(plot.id as number)}
        />
      </Tooltip>,
    );
  }

  if (onDelete) {
    actions.push(
      <Tooltip title={t('ploteditor.deleteBtn')}>
        <Popconfirm
          title={`Weet u zeker dat u ${plot.properties?.name} wilt verwijderen? 
          Dit kan niet als er activiteiten verbonden zitten aan dit perceel`}
          okText="Bevestig"
          cancelText="Annuleer"
          onConfirm={() => onDelete(plot.id as number)}
        >
          <Button
            size="small"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            danger
          />
        </Popconfirm>
      </Tooltip>,
    );
  }
  return (
    <List.Item actions={actions}>
      <Row>
        <Col span={16}>
          <Paragraph
            editable={{
              icon: <FontAwesomeIcon icon={faEdit} />,
              tooltip: t('ploteditor.editPlotName'),
              onChange: (x) => onNameChange(plot.id as number, x),
            }}
          >
            {plot.properties?.name}
          </Paragraph>
          <Paragraph type="secondary">
            {displayWithDecimals(2)(
              roundToDecimals(2)(plot.properties?.hectare),
            )}{' '}
            ha
          </Paragraph>
        </Col>
        <Col span={8}>
          {selected ? <Tag color="blue">{t('ploteditor.selected')}</Tag> : null}
        </Col>
      </Row>
    </List.Item>
  );
};
