import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getArtificialFertilizer,
  getOrganicFertilizer,
  getSubfieldData,
  getSubfieldManurePlan,
} from 'adapters/manure-adapter';
import { notification, Tabs } from 'antd';
import { atoms } from 'atoms';
import { CustomButton, FertilizationPlan } from 'components';
import { ArtificialFertilizerForm } from 'components/artificial-modal';
import { CustomCard } from 'components/custom-card';
import { Container } from 'components/grid';
import { OrganicFertilizerForm } from 'components/organic-modal';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from 'types/company';
import { ArtificialFertilizers } from './ArtificialFertilizers';
import { CompanyInfo } from './CompanyInfo';
import { CutPlanning } from './CutPlanning';
import { OrganicFertilizers } from './OrganicFertilizers';
import { PlotInfo } from './PlotInfo';

const { TabPane } = Tabs;

export interface FertilizationPageProps {}

export const FertilizationPage: FC<FertilizationPageProps> = () => {
  const { t } = useTranslation();

  const plots = useAtomValue(atoms.plots);
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  return (
    <Container>
      <CustomCard>
        <Tabs
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          tabBarExtraContent={<ActiveTabButton activeTabKey={activeTabKey} />}
          destroyInactiveTabPane
        >
          <TabPane tab={t('navbar.menu.fertilization')} key="1">
            <CutPlanning plots={plots} />
          </TabPane>
          <TabPane tab="Perceelsinformatie" key="2">
            <PlotInfo />
          </TabPane>
          <TabPane tab="Bedrijfsinformatie" key="3">
            <CompanyInfo />
          </TabPane>
          <TabPane tab="Organische mestsoorten" key="4">
            <OrganicFertilizers />
          </TabPane>
          <TabPane tab="Kunstmest- en overige mestsoorten" key="5">
            <ArtificialFertilizers />
          </TabPane>
        </Tabs>
      </CustomCard>
    </Container>
  );
};

const ActiveTabButton = ({ activeTabKey }) => {
  const { t } = useTranslation();
  const setModalComponent = useUpdateAtom(atoms.modal.component);
  const setShowModal = useUpdateAtom(atoms.modal.show);

  const setModalState = (comp) => {
    setShowModal(true);
    setModalComponent(comp);
  };

  switch (activeTabKey) {
    case '1':
      return (
        <CustomButton
          color="secondary"
          type="primary"
          shape="round"
          onClick={() =>
            setModalState(
              <FertilizationPlan onSubmit={() => setShowModal(false)} />,
            )
          }
          icon={<FontAwesomeIcon icon="plus" />}
        >
          {t('navbar.buttons.newFertilization')}
        </CustomButton>
      );
    case '4':
      return (
        <CustomButton
          color="secondary"
          type="primary"
          shape="round"
          onClick={() =>
            setModalState(
              <OrganicFertilizerForm onSubmit={() => setShowModal(false)} />,
            )
          }
          icon={<FontAwesomeIcon icon="plus" />}
        >
          {t('navbar.buttons.newOrganic')}
        </CustomButton>
      );
    case '5':
      return (
        <CustomButton
          color="secondary"
          type="primary"
          shape="round"
          onClick={() =>
            setModalState(
              <ArtificialFertilizerForm onSubmit={() => setShowModal(false)} />,
            )
          }
          icon={<FontAwesomeIcon icon="plus" />}
        >
          {t('navbar.buttons.newArtificial')}
        </CustomButton>
      );

    default:
      return null;
  }
};
