import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteAdvisorMandate,
  getActiveAdvisorMandates,
  getAdvisorList,
  getOpenAdvisorMandates,
} from 'adapters/user-adapter';
import { message, Popconfirm, Switch, Tooltip, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { atoms } from 'atoms';
import { CustomButton } from 'components';
import { Container } from 'components/grid';
import { useUpdateAtom } from 'jotai/utils';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AdvisorMandateDto } from 'types';
import { Advisor } from 'types/advisor';

export interface FarmerMandatesProps {}
export const FarmerMandates: FC<FarmerMandatesProps> = () => {
  const setImpersonatedRalation = useUpdateAtom(atoms.impersonatedRelation);
  const [activeAdvisorMandates, setActiveAdvisorMandates] = useState<
    AdvisorMandateDto[]
  >([]);
  const history = useHistory();

  useEffect(() => {
    updateAdvisorMandates();
  }, []);

  const onDeleteAdvisorMandate = async (advisorMandateId) => {
    try {
      await deleteAdvisorMandate(advisorMandateId);
      updateAdvisorMandates();
    } catch (e: any) {
      message.error(JSON.stringify(e));
    }
  };

  const updateAdvisorMandates = async () => {
    (async () => {
      try {
        setActiveAdvisorMandates(await getActiveAdvisorMandates());
      } catch (e: any) {
        message.error(JSON.stringify(e));
      }
    })();
  };

  const impersonateFarmer = (farmerKvk) => {
    setImpersonatedRalation({ id: farmerKvk });
    history.push('/');
  };

  const { t } = useTranslation();
  const CompanyInfoInitColumns = [
    {
      id: 'name',
      render: (_, record) => record.farm.name,
    },
    {
      id: 'coc',
      render: (_, record) => record.farm.kvk,
    },
    {
      id: 'startDate',
      render: (_, record) => moment(record.activated.date).format('DD-MM-YYYY'),
    },
    {
      id: 'levelOfRights',
      render: (_, record) => t(`advisorMandates.${record.levelOfRights}`),
    },
    {
      id: 'status',
      render: (_, record) => t(`advisorMandates.${record.status}`),
    },
  ];
  const companyColumns = CompanyInfoInitColumns.map((c) => ({
    title: t(`advisorMandates.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    render: (text, record) => {
      if (c.render) return c.render(text, record);
      return text;
    },
  })) as ColumnsType<AdvisorMandateDto>;

  const actionColumns = [
    ...companyColumns.map((col) => {
      return { ...col };
    }),
    {
      title: 'Open bedrijf',
      key: 'Impersoneer',
      render: (_: any, record: any) => {
        return (
          <Tooltip title="Open bedrijf">
            <CustomButton
              type="link"
              onClick={() => impersonateFarmer(record.farm.kvk)}
            >
              Open bedrijf
            </CustomButton>
          </Tooltip>
        );
      },
    },
    {
      title: 'Acties',
      key: 'Acties',
      render: (_: any, record: any) => {
        return (
          <Tooltip title="Verwijderen">
            <Popconfirm
              title="Weet u zeker dat u de machtiging voor de melkveehouder wilt verwijderen?"
              okText="Bevestig"
              cancelText="Annuleer"
              onConfirm={() => onDeleteAdvisorMandate(record.advisorMandateId)}
            >
              <CustomButton
                icon={<FontAwesomeIcon icon="trash" />}
                type="text"
              />
            </Popconfirm>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Container>
      <h1>Melkveehouder machtigingen</h1>
      <Table
        bordered
        dataSource={activeAdvisorMandates}
        columns={actionColumns}
      />
    </Container>
  );
};
