import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteAdvisorMandate,
  getActiveAdvisorMandates,
  getAdvisorList,
  getOpenAdvisorMandates,
} from 'adapters/user-adapter';
import { message, Switch, Tooltip, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { CustomButton } from 'components';
import { Container } from 'components/grid';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvisorMandateDto } from 'types';
import { Advisor } from 'types/advisor';
import { AddMandateModal } from './add-mandate-modal';

// kvk invoeren
// begindatum einddatum kunnen selecteren
// switch voor beheerrechten per adviseur
// of alleen in kunnen lezen
// in een tabel te zien, niet kunnen aanpassen wel verwijderen

export interface AdvisorMandatesProps {}
export const AdvisorMandates: FC<AdvisorMandatesProps> = () => {
  const [showAddMandateModal, setShowAddMandateModal] = useState(false);
  const [activeAdvisorMandates, setActiveAdvisorMandates] = useState<
    AdvisorMandateDto[]
  >([]);

  useEffect(() => {
    // Run at the start and each time the modal is closed.
    if (!showAddMandateModal) {
      updateAdvisorMandates();
    }
  }, [showAddMandateModal]);

  const onDeleteAdvisorMandate = async (advisorMandateId) => {
    try {
      await deleteAdvisorMandate(advisorMandateId);
      updateAdvisorMandates();
    } catch (e: any) {
      message.error(JSON.stringify(e));
    }
  };

  const updateAdvisorMandates = async () => {
    (async () => {
      try {
        setActiveAdvisorMandates(await getActiveAdvisorMandates());
      } catch (e: any) {
        message.error(e);
      }
    })();
  };

  const { t } = useTranslation();
  const CompanyInfoInitColumns = [
    {
      id: 'coc',
      render: (_, record) => record.advisor.kvk,
    },
    {
      id: 'email',
      render: (_, record) => record.email,
    },
    {
      id: 'startDate',
      render: (_, record) => moment(record.activated.date).format('DD-MM-YYYY'),
    },
    {
      id: 'levelOfRights',
      render: (_, record) => t(`advisorMandates.${record.levelOfRights}`),
    },
    {
      id: 'status',
      render: (_, record) => t(`advisorMandates.${record.status}`),
    },
  ];
  const companyColumns = CompanyInfoInitColumns.map((c) => ({
    title: t(`advisorMandates.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    render: (text, record) => {
      if (c.render) return c.render(text, record);
      return text;
    },
  })) as ColumnsType<AdvisorMandateDto>;

  const actionColumns = [
    ...companyColumns.map((col) => {
      return { ...col };
    }),
    {
      title: 'Acties',
      key: 'Acties',
      render: (_: any, record: any) => {
        return (
          <Tooltip title="Verwijderen">
            <Typography.Link
              onClick={() => onDeleteAdvisorMandate(record.advisorMandateId)}
            >
              <FontAwesomeIcon icon="trash" />
            </Typography.Link>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Container>
      <AddMandateModal
        isModalVisible={showAddMandateModal}
        closeModal={() => {
          setShowAddMandateModal(false);
        }}
      ></AddMandateModal>
      <h1>Adviseur machtigingen</h1>
      <CustomButton
        color="primary"
        onClick={() => setShowAddMandateModal(true)}
      >
        Voeg een adviseur machtiging toe
      </CustomButton>
      <Table
        bordered
        dataSource={activeAdvisorMandates}
        columns={actionColumns}
      />
    </Container>
  );
};
