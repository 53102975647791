import { Form, InputNumber, Modal, Switch } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SplitModalProps = {
  onSubmit: (
    cancelled: boolean,
    repeatSplit: boolean,
    splitMeters: number | null,
  ) => void;
  visible: boolean;
};
export const SplitModal = ({ onSubmit, visible }: SplitModalProps) => {
  const [mutliSplitEnabled, setMutliSplitEnabled] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const resetForm = () => {
    setMutliSplitEnabled(false);
    form.resetFields();
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      resetForm();
      const { enabled, meters } = values;
      onSubmit(false, enabled, meters);
    });
  };

  const handleCancel = () => {
    resetForm();
    onSubmit(true, false, null);
  };

  return (
    <Modal
      title={t('ploteditor.splitAmounts')}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="multiSplit">
        <Form.Item name="enabled" label={t('ploteditor.repeatSplit')}>
          <Switch checked={mutliSplitEnabled} onChange={setMutliSplitEnabled} />
        </Form.Item>
        <Form.Item name="meters" label={t('ploteditor.meters')}>
          <InputNumber disabled={!mutliSplitEnabled} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
