import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { displayWithDecimals } from 'utilities/displayWithDecimals';
import { roundToDecimals } from 'utilities/roundToDecimals';

const round = (value) => displayWithDecimals(2)(roundToDecimals(2)(value));

export const CropcategoryArea = ({ yearlyOverview }) => {
  const [areas, setAreas] = useState<any[]>();
  const { t } = useTranslation();

  useEffect(() => {
    if (yearlyOverview) {
      setAreas(
        yearlyOverview.annualReviewOfCompany.areaPerCropCategory.map(
          (category) => ({
            cropcategory: category.cropCategory,
            area: round(category.hectare),
            hectare: category.hectare,
          }),
        ),
      );
    }
  }, [yearlyOverview]);

  const InitColumns = [
    { id: 'cropcategory', render: (value) => t(`yearlyoverview.${value}`) },
    { id: 'area' },
  ];

  const columns = InitColumns.map((c) => ({
    title: t(`yearlyoverview.${c.id}`),
    dataIndex: c.id,
    key: c.id,
    render: c.render,
  })) as ColumnsType<any>;

  return (
    <Table
      loading={!areas}
      dataSource={areas}
      columns={columns}
      scroll={{ x: true }}
      size="middle"
      pagination={false}
      bordered
      summary={(pageData) => {
        let total = '';
        if (pageData && Array.isArray(pageData)) {
          total = round(
            pageData.reduce((acc, cur) => {
              return acc + cur.hectare;
            }, 0),
          );
        }

        return (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                {t(`yearlyoverview.totalArea`)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>{total}</Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    ></Table>
  );
};
