import { getYearlyOverview } from 'adapters/planning-adapter';
import { Tabs } from 'antd';
import { atoms } from 'atoms';
import { CustomCard } from 'components';
import { Container } from 'components/grid';
import { useAtomValue } from 'jotai/utils';
import { useState, useEffect } from 'react';
import { CompanyPlanned } from './company-planned';
import { CompanyRealized } from './company-realized';
import { SubfieldPlanned } from './subfield-planned';
import { SubfieldRealized } from './subfield-realized';
import styles from './styles.module.scss';
import { CropcategoryArea } from './cropcategory-area';

export const YearlyOverview = () => {
  const selectedYear = useAtomValue(atoms.selectedYear);
  const [yearlyOverview, setYearlyOverview] = useState<any>();

  useEffect(() => {
    (async () => {
      const tmp = await getYearlyOverview({ year: selectedYear });
      setYearlyOverview(tmp);
    })();
  }, [selectedYear]);

  return (
    <Container>
      <CustomCard>
        <Tabs tabPosition="top">
          <Tabs.TabPane tab="Bedrijfsniveau gepland" key={1}>
            <div className={styles.areas}>
              <CropcategoryArea yearlyOverview={yearlyOverview} />
            </div>
            <CompanyPlanned yearlyOverview={yearlyOverview} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Bedrijfsniveau gerealiseerd" key={2}>
            <div className={styles.areas}>
              <CropcategoryArea yearlyOverview={yearlyOverview} />
            </div>
            <CompanyRealized yearlyOverview={yearlyOverview} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Per perceel gepland" key={3}>
            <SubfieldPlanned yearlyOverview={yearlyOverview} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Per perceel gerealiseerd" key={4}>
            <SubfieldRealized yearlyOverview={yearlyOverview} />
          </Tabs.TabPane>
        </Tabs>
      </CustomCard>
    </Container>
  );
};
