import { RootColors } from 'utilities/types';

export const getDefaultRootColors = (): RootColors => {
  return {
    primary: '#4a8a32',
    secondary: '#545454',
    tertiary: '#89b824',
    danger: '#ff4d4f',
    success: '#44bf40',
  };
};
