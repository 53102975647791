import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { AsyncStorage } from 'jotai/utils/atomWithStorage';
import { Relation } from 'types/relation';

const relationSessionStorage: AsyncStorage<Relation | null> = {
  getItem: async (key) => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue === null) return null;
    return JSON.parse(storedValue);
  },
  setItem: async (key, newValue) => {
    sessionStorage.setItem(key, JSON.stringify(newValue));
  },
  removeItem: async (key) => {
    sessionStorage.removeItem(key);
  },
};

export const impersonatedRelation = atomWithStorage<Relation | null>(
  'impersonation',
  null,
  relationSessionStorage,
);

export const impersonatedRelationId = atom<string | null | undefined>((get) => {
  const impersonateSession = get(impersonatedRelation);
  return impersonateSession ? impersonateSession.id : null;
});
