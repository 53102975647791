import { atom } from 'jotai';
import { Fertilization } from 'types';
import { ArtificialFertilizer } from 'types/artificialFertilizer';
import { OrganicFertilizer } from 'types/organicFertilizer';

export const fertilizations = atom<Fertilization[]>([]);
export const artificialFertilizers = atom<ArtificialFertilizer[]>([]);
export const organicFertilizers = atom<OrganicFertilizer[]>([]);

export const defaultArtificialFertilizers = atom((get) => {
  const onlyDefaults = get(artificialFertilizers).filter(
    (f) => f.farmId === null,
  );
  return onlyDefaults;
});

export const ownArtificialFertilizers = atom((get) => {
  const onlyOwn = get(artificialFertilizers).filter((f) => f.farmId !== null);
  return onlyOwn;
});

export const defaultOrganicFertilizers = atom((get) => {
  const onlyDefaults = get(organicFertilizers).filter((f) => f.farmId === null);
  return onlyDefaults;
});

export const ownOrganicFertilizers = atom((get) => {
  const onlyOwn = get(organicFertilizers).filter((f) => f.farmId !== null);
  return onlyOwn;
});
