import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postUser } from 'adapters/user-adapter';
import { Button, Col, Input, message, notification, Row, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { atoms } from 'atoms';
import { CustomCard } from 'components';
import { CustomButton } from 'components/buttons';
import { useUpdateAtom } from 'jotai/utils';
import { ChangeEvent, useCallback } from 'react';
import { FC, useState } from 'react';
import styles from './styles.module.scss';

export const SelectRoleModal: FC<{ isModalVisible: boolean }> = ({
  isModalVisible,
}) => {
  const [name, setName] = useState<string>();
  const [organization, setOrganization] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<'None' | 'Farmer' | 'Advisor'>('None');
  const [loading, setLoading] = useState<boolean>(false);
  const setUser = useUpdateAtom(atoms.user);

  const changeNameEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [setName],
  );

  const changeOrganizationEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOrganization(event.target.value);
    },
    [setOrganization],
  );

  const changeEmailEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [setEmail],
  );

  const mouseClickRoleEvent = useCallback(
    (roleClicked: typeof role) => () => {
      setRole(roleClicked);
    },
    [setRole],
  );

  const saveUser = useCallback(async () => {
    if (name && organization && role) {
      setLoading(true);
      const user = { username: name, companyName: organization, role, email: email };
      try {
        setUser(await postUser(user));
        message.success('Gebruiker is succesvol aangemaakt.');
      } catch (err: any) {
        notification['error']({
          message: 'Er ging iets mis',
          description: err.toString(),
        });
      } finally {
        setLoading(false);
      }
    }
  }, [name, organization, role, setUser]);

  return (
    <Modal
      footer={
        <CustomButton
          color="primary"
          disabled={!name || !organization || !role}
          onClick={saveUser}
          loading={loading}
        >
          Opslaan
        </CustomButton>
      }
      onCancel={() => {}}
      closable={false}
      visible={isModalVisible}
      title="Welkom op de Digitale Graslandgebruikskalender"
    >
      <div>
        <p>Beantwoord de volgende vragen om te kunnen beginnnen.</p>
      </div>

      <div className={styles.question}>
        <h4>Wat is uw naam?</h4>
        <Input value={name} onChange={changeNameEvent} />
      </div>

      <div className={styles.question}>
        <h4>Wat is de naam van uw organisatie?</h4>
        <Input value={organization} onChange={changeOrganizationEvent} />
      </div>

      <div className={styles.question}>
        <h4>Wat is uw email?</h4>
        <Input value={email} onChange={changeEmailEvent} />
      </div>

      <h4>Bent u een melkveehouder of een adviseur?</h4>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Button
            type="link"
            className={`${styles.cardButton} ${
              role === 'Farmer' ? styles.selected : ''
            }`}
            onClick={mouseClickRoleEvent('Farmer')}
          >
            <CustomCard className={styles.card}>Melkveehouder</CustomCard>
          </Button>
        </Col>
        <Col md={12} xs={24}>
          <Button
            type="link"
            className={`${styles.cardButton} ${
              role === 'Advisor' ? styles.selected : ''
            }`}
            onClick={mouseClickRoleEvent('Advisor')}
          >
            <CustomCard className={styles.card}>Adviseur</CustomCard>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
