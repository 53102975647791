import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stylingJson from 'styles/styling.json';
import styles from './styles.module.scss';
export interface PlanboardStylingProps {}
export const PlanboardStyling: FC<PlanboardStylingProps> = () => {
  const { t } = useTranslation();
  const [styling, setStyling] = useState<any[]>([]);
  useEffect(() => {
    setStyling(Object.entries(stylingJson));
  }, []);
  return (
    <div>
      <h1 className={styles.textCenter}>{t('planboard.legend')}</h1>
      {styling.map(([key, values]) => (
        <Row className={styles.marginBottom}>
          <Col span={12}>{t(`types.${key.toString()}`)}</Col>
          <Col span={12}>
            <div
              className={styles.legend}
              style={{
                ...values,
              }}
            ></div>
          </Col>
        </Row>
      ))}
    </div>
  );
};
