import {
  area,
  BBox,
  bbox,
  bboxPolygon,
  booleanWithin,
  getCoords,
  lineIntersect,
  lineOffset,
  lineString,
  multiLineString,
  MultiPolygon,
  Polygon,
  polygonize,
  polygonToLine,
  Position,
  union,
} from '@turf/turf';
import { Feature } from 'geojson';
import { v4 as uuidv4 } from 'uuid';

export const createPlot = (
  id: number,
  name: string,
  geoJson: Feature<any>,
): Feature<any> => {
  geoJson.id = id;
  geoJson.properties = {
    name: name,
    hectare: area(geoJson as Feature<any>),
  };

  return geoJson;
};

export const mergePlots = (plots: Array<Feature>): Feature<any> => {
  const combinedId = plots.reduce((pv, cv) => pv + (cv.id as number), 0);
  const mergedGeoJson = union(...(plots as Array<Feature<any>>));

  return createPlot(combinedId, `${plots[0].properties?.name}`, mergedGeoJson);
};

const lineInPolygon = (polygon: Feature<any>, line: Feature<any>) => {
  const intersecting = lineIntersect(polygon, line);
  // If the polygon and the line intersects - the line is counted as in
  // If not - it could be the case the line is fully in the polygon.
  return intersecting && intersecting.features?.length > 0
    ? true
    : booleanWithin(line, polygon);
};

const createMultiSplitline = (
  plot: Feature<any>,
  line: Feature<any>,
  offSetInMeters: number,
) => {
  const createPositions = (
    boundingBox: BBox,
    line: Feature<any>,
    offSetInMeters: number,
  ) => {
    const positions: Array<Position[]> = [];
    const boundingBoxPolygon = bboxPolygon(boundingBox);

    let i = 0;
    while (true) {
      const offset = lineOffset(line, offSetInMeters * i, {
        units: 'meters',
      });

      if (!lineInPolygon(boundingBoxPolygon, offset)) {
        break;
      }
      // Extend the line to the bounding box;
      const extendedOffset = extendLineToBoundingBox(offset, boundingBox);
      const coordinates = getCoords(extendedOffset);
      if (!coordinates) {
        continue;
      }

      if (i % 2) {
        coordinates.reverse();
      }

      positions.push(coordinates);
      i++;
    }
    return positions;
  };

  const boundingBox = bbox(plot);

  const positivePositions = createPositions(boundingBox, line, offSetInMeters);
  const negativePositions = createPositions(boundingBox, line, -offSetInMeters);
  return multiLineString([...positivePositions, ...negativePositions]);
};

const splitPolygonByLines = (
  polygon: Feature<Polygon | MultiPolygon>,
  splitLine: Feature<any>,
) => {
  const splits: Array<any> = [];

  // Convert the polygon to line
  const polygonAsLine = polygonToLine(polygon);
  // Union the polygon lines with the splitline
  const unionedLines = union(polygonAsLine as Feature<any>, splitLine);
  // Convert the linestrings to multiple polygons
  const polygonized = polygonize(unionedLines as any);

  // TODO: check why this doesn't work
  // const filterFeaturesOutside = polygonized.features.filter((pg) => {
  //   return booleanPointInPolygon(pointOnFeature(pg), polygon);
  // });

  for (let i = 0; i < polygonized.features.length; i++) {
    const geoJson = polygonized.features[i];
    // The ids for every plot should be unique.
    // As we do not know exactly what id's are used at this moment
    // We try to generate a probably unique one for now. Could be improved if nescessary.

    splits.push(
      createPlot(uuidv4(), `${polygon.properties?.name}_${i}`, geoJson),
    );
  }

  return splits;
};

const getExtendedLine = (
  minX: number,
  minY: number,
  maxX: number,
  maxY: number,
  pointA: Position,
  pointB: Position,
) => {
  const [x1, y1] = pointA;
  const [x2, y2] = pointB;

  const m = (y2 - y1) / (x2 - x1);
  const c = y1 - m * x1;

  // Formula: y = mx + c
  const y = (x: number) => m * x + c;
  const x = (y: number) => (y - c) / m;

  const top = [x(maxY), maxY];
  const left = [minX, y(minX)];
  const bottom = [x(minY), minY];
  const right = [maxX, y(maxX)];

  const yMinX = y(minX);
  const yMaxX = y(maxX);
  const xMinY = x(minY);
  const xMaxY = x(maxY);

  const positions: Array<Position> = [];
  // This might be simplified;
  // Intersects top
  if ((yMinX < maxY && yMaxX > maxY) || (yMinX > maxY && yMaxX < maxY)) {
    positions.push(top);
  }
  // Intersects bottom
  if ((yMinX < minY && yMaxX > minY) || (yMinX > minY && yMaxX < minY)) {
    positions.push(bottom);
  }

  // Intersects right
  if ((xMinY < maxX && xMaxY > maxX) || (xMinY > maxX && xMaxY < maxX)) {
    positions.push(right);
  }
  // Intersects left
  if ((xMinY < minX && xMaxY > minX) || (xMinY > minX && xMaxY < minX)) {
    positions.push(left);
  }
  return positions;
};

const extendLineToBoundingBox = (line: Feature<any>, bbox: BBox) => {
  // TODO:
  // At this moment this function is only working with 2 point lines

  // Get the min and max of the bbox
  const [minX, minY, maxX, maxY] = bbox;

  // Get the coords of the line
  const coords = getCoords(line);
  const firstCoords = coords[0];
  const lastCoords = coords[coords.length - 1];

  // Create an extended line
  const extendedLine = getExtendedLine(
    minX,
    minY,
    maxX,
    maxY,
    firstCoords,
    lastCoords,
  );

  return lineString(extendedLine);
};

const getSplitLine = (
  plot: Feature<any>,
  line: Feature<any>,
  splitAmount: number | null,
) => {
  const splitLine = splitAmount
    ? createMultiSplitline(plot, line, splitAmount)
    : isMultiPointLine(line)
    ? line
    : extendLineToBoundingBox(line, bbox(plot));

  return splitLine;
};

export const isMultiPointLine = (line: Feature<any>) => {
  const coords = getCoords(line);
  if (coords.length === 2) {
    return false;
  }
  // There are cases when the last two coordinates are exactly the same
  // If that's the case treat is a a non multi line
  if (coords.length === 3) {
    const coordA = coords[coords.length - 1];
    const coordB = coords[coords.length - 2];
    return coordA[0] !== coordB[0] || coordA[1] !== coordB[1];
  }

  return true;
};

export const splitPlot = (
  plot: Feature<any>,
  line: Feature<any>,
  splitAmount: number | null,
): Array<Feature<any>> => {
  if (
    plot.geometry.type !== 'Polygon' &&
    plot.geometry.type !== 'MultiPolygon'
  ) {
    throw new Error(
      `Plot geometry should be of type Polygon, MultiPolygon or Feature. Current: [${plot.geometry.type}]`,
    );
  }

  if (
    line.geometry.type !== 'LineString' &&
    line.geometry.type !== 'MultiLineString'
  ) {
    throw new Error(
      `SplitLine geometry should be of type LineString or MultiLineString. Current: [${line.geometry.type}]`,
    );
  }
  const splitLine = getSplitLine(plot, line, splitAmount);

  return splitPolygonByLines(plot, splitLine);
};
