import { atom } from 'jotai';
import Keycloak, { KeycloakInstance } from 'keycloak-js';

const keycloak = Keycloak({
  clientId: (window as any).AUTH_CLIENT_ID,
  realm: (window as any).AUTH_REALM,
  url: (window as any).AUTH_URL,
});

export const keycloakInstance = atom<KeycloakInstance>(keycloak);

export const auth = {
  keycloakInstance,
};
